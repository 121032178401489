import { Injectable } from "@angular/core";
import { AuthService } from "../api/auth.service";
import { RoutesService } from "../api/routes.service";
import { LoginGuardService } from "../api/login-guard.service";
import { StudentG9ConnectionService } from "./student-g9-connection.service";
import { LangService } from "../core/lang.service";
import { StudentG9DashboardService } from "./student-g9-dashboard.service";
import { CALC_FRAME_ID } from "../ui-testrunner/widget-calculator/widget-calculator.component";

export interface IAttemptContextInit {
  uid: number, // this.auth.getUid()
  test_attempt_id: number,
  test_session_id: number,
  sessionTabHash: string,
  is_soft_lock_disabled: number, 
  is_soft_lock_enabled?: number,  
  currentStudentPosition: { // critical that the object pointer is not broken on this
    stageIndex: null | number,
    questionCaption: null | string,
  }
  isSebMode: boolean,
}
export interface IAttemptContext extends IAttemptContextInit {
  numTimesOffScreen:number,
  is_paused:boolean,
}

@Injectable({
  providedIn: "root"
})
export class StudentSoftLockService {
  
  private ctx: IAttemptContext

  public isWindowFocused: boolean = true;
  public seenStudentOffScreenWarning:boolean;

  constructor(
    private auth: AuthService, 
    private routes: RoutesService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
    private studentG9Connection: StudentG9ConnectionService,
  ) {}

  init(ctx: IAttemptContextInit){
    this.ctx = { 
      ... ctx, // note: object pointer is not preserved on the ctx object that was fed in, only on the objects nested inside
      numTimesOffScreen: 0,
      is_paused: false, 
    };
    if(!this.auth.getKioskPassword()){
      this.forceFullScreen();
    } 
    window.onbeforeunload = () => this.studentG9Connection.disconnect();
    window.onblur = this.windowBlurred;
    window.onfocus = this.windowFocused;
    this.initDetectFullScreen();
    console.log('SoftLockService Init', this.isSoftLockActive(), this.ctx)
  }

  destroy(){
    document.removeEventListener('fullscreenchange', this.detectFullScreen, true);
    window.onblur = () => {}
  }

  isSoftLockActive(){
    // if ((this.auth.getKioskPassword() || payload.assistiveTech || this.dash.getSchoolSoftLockEnable()) && !payload.isSebMode) {
    // todo: determine need to bring back some logic in the case of assistive tech and this.auth.getKioskPassword()
    const isActive = (
      // (this.ctx.is_soft_lock_enabled == 1) && 
      (this.ctx.is_soft_lock_disabled == 0) && 
      (!this.ctx.isSebMode)
    )
    return isActive
  }

  sessionEventLog(slug:string, detail?:any){
    console.log('sessionEventLog', slug)
    this.auth.apiCreate(this.routes.LOG, {
      slug,
      data: {
        test_session_id: this.ctx.test_session_id, // to do: bring back item set
        test_attempt_id: this.ctx.test_attempt_id,
        uid: this.ctx.uid,
        sessionTabHash: this.ctx.sessionTabHash,
        detail,
      }
    })
  }

  recheckStudentSoftLock = async () => {
    const test_attempt_id = this.ctx.test_attempt_id
    const softLockStatus = await this.auth.apiGet(this.routes.STUDENT_SOFT_LOCK, test_attempt_id);
    if (this.ctx.is_paused && !softLockStatus.is_paused){
      this.loginGuard.quickPopup('You have been unpaused.')
    }
    if (softLockStatus){
      this.ctx.is_soft_lock_disabled = softLockStatus.is_soft_lock_disabled
      // this.ctx.is_soft_lock_enabled = softLockStatus.is_soft_lock_enabled
      this.ctx.is_paused = softLockStatus.is_paused
    }
    // Object.assign(this.ctx, response);
  };

  async getStudentSoftLock(){
    await this.recheckStudentSoftLock();
    return { ... this.ctx }
  }

  triggerOnLoadPause = async () =>{
    await this.pauseStudent();
    this.setStudentSoftLock();
    this.onSoftLockRetry()
  }

  pauseStudent = async () => {
    console.log('softlock: try pause student')
    await this.recheckStudentSoftLock();
    const test_attempt_id = this.ctx.test_attempt_id
    this.sessionEventLog('STUDENT_SOFTLOCK_TRIGGERED', {test_attempt_id})
    if (this.ctx.is_paused || !this.isSoftLockActive()) {
      return;
    }
    // pause
    try{
      await this.auth
      .apiPatch(this.routes.STUDENT_SOFT_LOCK, test_attempt_id, {});
      console.log("ATTEMP_PAUSED");
    }catch(e){
      console.log("STUDENT_SOFT_LOCK_FAILED", e);
    }
  };

  async forceFullScreen() {
    if (this.isSoftLockActive()){
      let elem = document.documentElement;
      let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen'] || elem['msRequestFullscreen'];
      if (methodToBeInvoked) {
        try {
          await methodToBeInvoked.call(elem);
        }
        catch (err) {
          this.setStudentSoftLock();
        }
      }
    }
  }

  setStudentSoftLock = (event?) => {
    //pause student
    // this.studentSoftLock.pauseStudent(this.currentAttemptId)
    if (this.isSoftLockActive() && this.studentG9Connection) {
      this.studentG9Connection
        .updateStudentPosition({
          stageIndex: this.ctx.currentStudentPosition.stageIndex,
          questionCaption: this.ctx.currentStudentPosition.questionCaption,
          softLock: 1,
          numTimesOffScreen: this.ctx.numTimesOffScreen
        });
      if (!this.seenStudentOffScreenWarning) {
        this.seenStudentOffScreenWarning = true;
        this.loginGuard.confirmationReqActivate({
          caption: 'msg_student_navigate_popup',
          btnCancelConfig: {
            hide: true
          },
          confirm: () => {
            setTimeout( () => {
              this.activatePauseNotif()
            }, 0)
          } 
        })
      }
    }
  }

  
  activatePauseNotif(){
    this.loginGuard.confirmationReqActivate({
      caption : this.lang.tra('msg_cannot_proceed_test') + ' ' + this.lang.tra('msg_session_pause') ,
      btnCancelConfig: {
        hide: true
      },
      btnProceedConfig: {
        caption: 'btn_retry'
      },
      confirm: () => this.onSoftLockRetry()
    })
  }
  async onSoftLockRetry(){
    await this.recheckStudentSoftLock()
    if (!this.ctx.is_soft_lock_disabled){
      if(!this.ctx.is_paused) {
        //once the student is cleared out of both soft lock and the pausing state, we should put them back into fullscreen mode.
        this.forceFullScreen();
        return;
      }
      this.activatePauseNotif()              
    }
  }
  
  invigUnpauseTestAttemptForStudents = async(testAttemptId:number, params, route = this.routes.EDUCATOR_SOFT_LOCK) => {
    return this.auth.apiPatch(route, testAttemptId, {isPausing : false}, params )
  }

  invigDisableSoftLockForStudentAttempt = async(testAttemptId:number, params:any, route = this.routes.EDUCATOR_SOFT_LOCK) => {
    return this.auth.apiPatch(route, testAttemptId, {
        isPausing: false,
        isDisableSoftLock: true
      }, params )
  }
    

  logStudentPause(test_attempt_id) {

    this.auth.apiCreate(this.routes.LOG, {
      slug: "STUDENT_SOFTLOCK_TRIGGERED",
      data: {
        uid: this.auth.getUid(),
        test_attempt_id: test_attempt_id,
      },
    });
  }

  initDetectFullScreen() {
    document.addEventListener('fullscreenchange', this.detectFullScreen)
  }

  detectFullScreen = (event) => {
    if (!document.fullscreenElement) {
      this.sessionEventLog('STU_EXIT_FULLSCREEN');
      if (!this.isSoftLockActive() /*|| this.abed_submitted*/ ){ // todo: determine why this.abed_submitted is a criteria for this
        return 
      }
      this.loginGuard.confirmationReqActivate({
        caption: 'msg_student_leave_fullscreen_popup',
        btnCancelConfig: {
          caption: 'msg_student_return_to_fullscreen'
        },
        btnProceedConfig: {
          caption: 'msg_student_exit_fullscreen'
        },
        confirm: () => {
          setTimeout( () => {
            this.setStudentSoftLock()
            this.activatePauseNotif();
          }, 0)} ,
        close: () => {
          setTimeout( () => {
            this.forceFullScreen()
          }, 0)},
      })
      // this.setStudentSoftLock();
    }
  }

  
  windowFocused = (event?) => {
    this.isWindowFocused = true;
    this.sessionEventLog('WINDOW_FOCUS')
  }

  windowBlurred = (event?) => {
    this.isWindowFocused = false;
    let target = event?.target;
    if(target?.length && target[0].frameElement?.id === CALC_FRAME_ID) {
      target[0].onblur = this.windowBlurred;
      target[0].onfocus = this.windowFocused;
    }
    setTimeout(()=>{
      if(!this.isWindowFocused) {
        this.sessionEventLog('WINDOW_BLUR')
        this.ctx.numTimesOffScreen++;
        this.setStudentSoftLock(event);
      }
    }, 5);
  }



}

