// this information is largely coming from the data definition files

import { IMappedList, initMappedList } from "./util";
import { IDataDefinition, IStudent_School_Enrol_Type, IMultiSelect } from "./types";
import { T } from "@angular/cdk/keycodes";

 // todo:DB_DATA_MODEL

export enum G9_COURSES {
    APPLIED = 'lbl_applied',
    ACADEMIC = 'lbl_academic',
}

export interface IG9Courses extends IDataDefinition {
    course: G9_COURSES,
    inPerson?: boolean,
    remote?: boolean
}
interface IG9Semester extends IDataDefinition {
    windowDateStart?: string,
    windowDateEnd?: string,
}

export const DEMO_SEMESTER = 'A';

export const EQAO_DB_NULL = '#';

// based on https://vretta.app.box.com/file/729064039069
// the goal is to map these directly to the meta properties that we are storing for the students
export const STUDENT_YES_NO:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_yes'},
    { id: EQAO_DB_NULL, label: 'sdc_const_no'},
]);
export const STUDENT_YES_NO_RADIO:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_yes'},
    { id: EQAO_DB_NULL, label: 'sdc_const_no'},
]);

export const STUDENT_YES_NO_UNKNOWN_RADIO:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_yes'},
    { id: '2', label: 'sdc_const_no'},
    { id: EQAO_DB_NULL, label: 'sdc_const_unknown'},
]);

export const STUDENT_YES_NO_UNKNOWN_2_RADIO:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_yes'},
    { id: EQAO_DB_NULL, label: 'sdc_const_no_unknown'},
]);

export const STUDENT_OSSLT_SUCC:IMappedList<IDataDefinition> = initMappedList([    
    { id: EQAO_DB_NULL, label: 'sdc_const_no'},
    { id: '0', label: 'sdc_const_osslt_succ_0'},
    { id: '1', label: 'sdc_const_osslt_succ_1'},
    { id: '2', label: 'sdc_const_osslt_succ_2'},
]);

export const STUDENT_IN_PERSON_REMOTE:IMappedList<IDataDefinition> = initMappedList([
    {id: '1', tag:'sdc_student_rem_inp_1', label: 'tra_in_person'}, // In-Person
    {id: '2', tag:'sdc_student_rem_inp_2', label: 'tra_remote'} // Remote
]);
export const STUDENT_GENDERS:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_male', },
    { id: '2', label: 'sdc_const_female',},
    { id: '9', label: 'sdc_const_unspecified', },
]);

export const STUDENT_SEMESTET_INDICATOR:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_semestered', },
    { id: '2', label: 'sdc_const_full_year',},
    { id: '3', label: 'sdc_const_quadmestered', },
    { id: '4', label: 'sdc_const_octomestered', },
    { id: '5', label: 'sdc_const_other', },
]);

export const Math_Class_When:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_const_semestered' },
    { id: '2', label: 'sdc_const_quadmestered'},
    { id: '3', label: 'sdc_const_octomestered'},
    { id: '4', label: 'sdc_const_full_year'},
]);

export const Learning_Format_Primary:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_learning_format_primary_1' },
    { id: '2', label: 'sdc_learning_format_primary_3'},
    { id: '3', label: 'sdc_learning_format_primary_5'},
]);

export const Learning_Format_Junior:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_learning_format_junior_1' },
    { id: '2', label: 'sdc_learning_format_junior_3'},
    { id: '3', label: 'sdc_learning_format_junior_5'},
]);

export const Learning_Format_G9:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_learning_format_1' },
    { id: '2', label: 'sdc_learning_format_3'},
    { id: '3', label: 'sdc_learning_format_5'},
]);

export const Learning_Format_OSSLT:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_learning_format_osslt_1' },
    { id: '2', label: 'sdc_learning_format_osslt_3'},
    { id: '3', label: 'sdc_learning_format_osslt_5'},
]);

export const STUDENT_GRADES:IMappedList<IDataDefinition> = initMappedList([    
    { id: '3', label: 'sdc_grade_3' },
    { id: '6', label: 'sdc_grade_6'},
]);

export const STUDENT_EQAO_GRADECOURSE_TYPE:IMappedList<IDataDefinition> = initMappedList([   
    { id: 'EQAO_G3', label: 'lbl_primary'},
    { id: 'EQAO_G6', label: 'lbl_junior'},
    { id: 'EQAO_G9', label: 'txt_g9_math'},
    { id: 'EQAO_G10', label: 'lbl_osslt'}
]);

export const STUDENT_NBED_COURSE_TYPE:IMappedList<IDataDefinition> = initMappedList([    
    { id: 'NBED_TCLE', label: 'lbl_nbed_tcle'},
    { id: 'NBED_TCN', label: 'lbl_nbed_tcn'},
    { id: 'NBED_SCIENCES8', label: 'lbl_nbed_sciences8'}
]);

export const STUDENT_MBED_COURSE_TYPE:IMappedList<IDataDefinition> = initMappedList([    
    { id: 'MBED_SAMPLE', label: 'Sample Course'}, // TODO: create label for MBED Sample course
]);

export const STUDENT_ABED_COURSE_TYPE:IMappedList<IDataDefinition> = initMappedList([    
    { id: 'ABED_SAMPLE', label: 'Sample Course'}, // TODO: create label for ABED Sample course
    { id: 'ABED_GRADE_6', label: 'Grade 6'}, // TODO: create label for ABED G6
    { id: 'ABED_GRADE_9', label: 'Grade 9'}, // TODO: create label for ABED G9
    { id: 'ABED_GRADE_12', label: 'Grade 12'}, // TODO: create label for ABED G12
    { id: 'ABED_CEAC', label: 'CAEC'}, // TODO: create label for ABED G12
    { id: 'CLASSROOM_ASSESSMENT', label: 'CLASSROOM_ASSESSMENT'}, // TODO: create label for CLASSROOM_ASSESSMENT
]);

export const STUDENT_G9_COURSES:IMappedList<IG9Courses> = initMappedList([    
    { id: '1', course: G9_COURSES.APPLIED, inPerson: true, remote: false, label: 'sdc_student_g9_courses_1'},//Applied (Full-time in-person learning)
    { id: '2', course: G9_COURSES.ACADEMIC, inPerson: true, remote: false, label: 'sdc_student_g9_courses_2'},//Academic (Full-time in-person learning)
    { id: '3', course: G9_COURSES.APPLIED, inPerson: false, remote: true, label: 'sdc_student_g9_courses_3'},//Applied (Full-time remote learning)
    { id: '4', course: G9_COURSES.ACADEMIC, inPerson: false, remote: true, label: 'sdc_student_g9_courses_4'},//Academic (Full-time remote learning)
    { id: '5', course: G9_COURSES.APPLIED, inPerson: true, remote: true, label: 'sdc_student_g9_courses_5'},//Applied (Combination of in-person and remote learning)
    { id: '6', course: G9_COURSES.ACADEMIC, inPerson: true, remote: true, label: 'sdc_student_g9_courses_6'},//Academic (Combination of in-person and remote learning)
]);
export const STUDENT_G9_COURSES_SIMPLE:IMappedList<IG9Courses> = initMappedList([    
    { id: '1', course: G9_COURSES.APPLIED,  label: 'lbl_applied'},
    { id: '2', course: G9_COURSES.ACADEMIC, label: 'lbl_academic'}
]);

export const STUDENT_INDIG_TYPE:IMappedList<IDataDefinition> = initMappedList([    
    { id: '2', label: 'sdc_student_indig_type_2'},//First Nations
    { id: '3', label: 'sdc_student_indig_type_3'},//Métis
    { id: '4', label: 'sdc_student_indig_type_4'},//Inuit
    { id: EQAO_DB_NULL, label: 'sdc_student_indig_type_5'},//Other
]);

export const STUDENT_ACC_BRAILLE:IMappedList<IDataDefinition> = initMappedList([    
    { id: '3', label: 'sdc_student_acc_braille_3'},//Contracted
    { id: '4', label: 'sdc_student_acc_braille_4'},//Uncontracted
]);
export const STUDENT_TIME_IN_CANADA:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label: 'sdc_student_time_in_canada_1'}, // Less than one year
    { id: '2', label: 'sdc_student_time_in_canada_2'}, // One year or more but less than two years
    { id: '3', label: 'sdc_student_time_in_canada_3'},// Two years or more but less than three years
    { id: '4', label: 'sdc_student_time_in_canada_4'}, // Three years or more but less than five years
    { id: '5', label: 'sdc_student_time_in_canada_5'},// Five years or more
    { id: EQAO_DB_NULL, label: 'sdc_student_time_in_canada_6'} // Missing/Unknown/Not Applicable
]);
export const STUDENT_MATH_CLASS_WHEN:IMappedList<IG9Semester> = initMappedList([    
    {id:'1', label:'sdc_student_math_class_when_1'},//First semester
    {id:'2', label:'sdc_student_math_class_when_2'},//Second semester
    {id:'3', label:'sdc_student_math_class_when_3'},//Full year
    {id:'A', label:'sdc_student_math_class_when_A'},//First quadmester
    {id:'B', label:'sdc_student_math_class_when_B'},//Second quadmester
    {id:'C', label:'sdc_student_math_class_when_C'},//Third quadmester
    {id:'D', label:'sdc_student_math_class_when_D'},//Fourth quadmester
    {id:'E', label:'sdc_student_math_class_when_E'},//First octomester
    {id:'F', label:'sdc_student_math_class_when_F'},//Second octomester
    {id:'G', label:'sdc_student_math_class_when_G'},//Third octomester
    {id:'H', label:'sdc_student_math_class_when_H'},//Fourth octomester
    {id:'I', label:'sdc_student_math_class_when_I'},//Fifth octomester
    {id:'J', label:'sdc_student_math_class_when_J'},//Sixth octomester
    {id:'K', label:'sdc_student_math_class_when_Kr'},//Seventh octomester
    {id:'L', label:'sdc_student_math_class_when_L'},//Eighth octomester
    {id:'M', label:'sdc_student_math_class_when_M'},//First hexamester
    {id:'N', label:'sdc_student_math_class_when_N'},//Second hexamester
    {id:'O', label:'sdc_student_math_class_when_O'},//Third hexamester
    {id:'P', label:'sdc_student_math_class_when_P'},//Fourth hexamester
    {id:'Q', label:'sdc_student_math_class_when_Q'},//Fifth hexamester
    {id:'R', label:'sdc_student_math_class_when_R'},//Sixth hexamester
]);


export const STUDENT_PRIVATE_SCHOOL_ENROL_TYPES:IMappedList<IStudent_School_Enrol_Type> = initMappedList([    
    { id: '#', isSchoolEditable: true,  label: 'lbl_not_applicable'},
    { id: '3', isSchoolEditable: true,  label: 'sdc_school_enroll_type_3'},//Adult
    { id: '4', isSchoolEditable: true,  label: 'sdc_school_enroll_type_4'},//Mature
]);

export const STUDENT_SCHOOL_ENROL_TYPES:IMappedList<IStudent_School_Enrol_Type> = initMappedList([    
    { id: '#', isSchoolEditable: true,  label: 'lbl_not_applicable'},
    { id: '1', isSchoolEditable: false, label: 'sdc_school_enroll_type_1'},//Home-schooled
    { id: '2', isSchoolEditable: false, label: 'sdc_school_enroll_type_2'},//Section 23
    { id: '3', isSchoolEditable: true,  label: 'sdc_school_enroll_type_3'},//Adult
    { id: '4', isSchoolEditable: true,  label: 'sdc_school_enroll_type_4'},//Mature
    { id: '5', isSchoolEditable: false, label: 'sdc_school_enroll_type_5'},//E-learning
    { id: '6', isSchoolEditable: false, label: 'sdc_school_enroll_type_6'},//Independent Study
    { id: '7', isSchoolEditable: false, label: 'sdc_school_enroll_type_7'},//Independent Learning Centre
]);

export const STUDENT_SCHOOL_ENROL_TYPES_PJ:IMappedList<IStudent_School_Enrol_Type> = initMappedList([    
    { id: '#', isSchoolEditable: true,  label: 'lbl_not_applicable'},
    { id: '1', isSchoolEditable: false, label: 'sdc_school_enroll_type_1'},//Home-schooled
    { id: '2', isSchoolEditable: false, label: 'sdc_school_enroll_type_2'},//Section 23
]);

export const STUDENT_OSSLT_ELIG_STATUS :IMappedList<IDataDefinition> = initMappedList([    
    // {        label: 'Not Applicable'},
    { id: '1', label: 'sdc_osslt_eligstat_1'},
    { id: '2', label: 'sdc_osslt_eligstat_2'},
]);

export const STUDENT_OSSLT_DATE_OF_FTE :IMappedList<IDataDefinition> = initMappedList([    
    { id: '#', label: 'lbl_not_applicable_dfte'},
    //{ id: '17', label: 'lbl_2017_2018_bef'}, removed
    //{ id: '18', label: '2018-2019'}, changed
    { id: '18', label: 'lbl_2018_2019_bef'},
    { id: '19', label: '2019-2020'},
    { id: '20', label: '2020-2021'},
    { id: '21', label: '2021-2022'},
]);

export const STUDENT_OSSLT_NON_PART :IMappedList<IDataDefinition> = initMappedList([    
    { id: '#', label: 'sdc_const_no'},
    { id: '1', label: 'sdc_osslt_non_part_1'},
    { id: '2', label: 'sdc_osslt_non_part_2'},
    { id: '3', label: 'sdc_osslt_non_part_3'},
]);

export const STUDENT_OSSLT_LEV_STUD_LANG :IMappedList<IDataDefinition> = initMappedList([    
    { id: '#', label: 'lbl_not_applicable_fr_alt'},
    { id: '1', label: 'sdc_osslt_lev_stud_lang_1'},
    { id: '2', label: 'sdc_osslt_lev_stud_lang_2'},
    { id: '3', label: 'sdc_osslt_lev_stud_lang_3'},
    { id: '4', label: 'sdc_osslt_lev_stud_lang_4'},
    { id: '5', label: 'sdc_osslt_lev_stud_lang_5'},
]);

export const STUDENT_STATUS_IN_CANADA_ONTARIO :IMappedList<IDataDefinition> = initMappedList([    
    // {        label: 'Not Applicable'},
    { id: '1', label: 'sdc_status_can_1'},//Canadian Citizen
    { id: '2', label: 'sdc_status_can_2'},//Permanent Resident
    { id: '3', label: 'sdc_status_can_3'},//Refugee Status
    { id: '4', label: 'sdc_status_can_4'},//Student Visa
    { id: '5', label: 'sdc_status_can_5'},//Other Visa
    { id: '6', label: 'sdc_status_can_6'},//None of the above
    { id: EQAO_DB_NULL, label: 'sdc_status_can_unknown'}, //Unknown
]);

export const STUDENT_IEP:IMappedList<IMultiSelect> = initMappedList([    
    { id: '1', label: 'sdc_iep_1', tag:''},//This student has an IEP.
]);
export const STUDENT_ELL:IMappedList<IMultiSelect> = initMappedList([    
    { id: '1', label: 'sdc_ell_1', tag: ''},//This student is currently an English language learner.
]);

export const STUDENT_ALFPANA:IMappedList<IMultiSelect> = initMappedList([    
    { id: '2', label: 'sdc_ell_2'},//ALF.
    { id: '3', label: 'sdc_ell_3'},//PANA.
    { id: EQAO_DB_NULL, label: 'sdc_const_no'} //No
]);

export const STUDENT_BREAKS:IMappedList<IMultiSelect> = initMappedList([    
    { id: '1', label: 'sdc_breaks_1'},//Extended periodic supervised breaks
]);
export const STUDENT_NonParticipationStatus:IMappedList<IMultiSelect> = initMappedList([    
    //{ id: '0', label: 'sdc_nonparticipation_1'},
    { id: '1', tag:'exempted',  label: 'sdc_nonparticipation_1'},
    { id: '2', tag:'deferred',  label: 'sdc_nonparticipation_2'},
    { id: '3', tag:'osslc',     label: 'sdc_nonparticipation_3'},
]);

export const STUDENT_IPRC:IMappedList<IMultiSelect> = initMappedList([    
    { id: '1',  tag:'behaviour',     label:'sdc_iprc_beh'},//Behaviour
    { id: '2',  tag:'autism',        label:'sdc_iprc_aut'},//Autism 
    { id: '3',  tag:'deaf',          label:'sdc_iprc_deaf'},//Deaf or hard of hearing
    { id: '4', tag:'language',      label:'sdc_iprc_lang'},//Language impairment
    { id: '5', tag:'speech',        label:'sdc_iprc_speech'},//Speech impairment         
    { id: '6', tag:'learning',      label:'sdc_iprc_learn'},//Learning disability         
    { id: '7',  tag:'gifted',        label:'sdc_iprc_gifted'},//Giftedness                 
    { id: '8',  tag:'intellectual',  label:'sdc_iprc_intell'},//Mild intellectual disability 
    { id: '9',  tag:'developmental', label:'sdc_iprc_develop'},//Developmental disability  
    { id: '10',  tag:'physical',      label:'sdc_iprc_physical'},//Physical disability
    { id: '11',  tag:'blind',         label:'sdc_iprc_blind'},//Blind and low vision
    { id: '12',  tag:'multiple',      label:'sdc_iprc_multiple'},//Multiple exceptionalities
    { id: EQAO_DB_NULL, label: 'sdc_const_no'} //No
]);

export const STUDENT_MATH_AUDIO:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label:'sdc_pj_audio_full'},//audio_full
    { id: '2', label:'sdc_pj_audio_without_full'},//audio_without_full
    { id: EQAO_DB_NULL, label: 'sdc_const_not_applicable'} //No
])

export const STUDENT_MATH_AUDIO_ALTERNATE_FORM:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', label:'sdc_pj_audio_full_alternate_form'},//audio_full
    { id: '2', label:'sdc_pj_audio_without_full_alternate_form'},//audio_without_full
    { id: EQAO_DB_NULL, label: 'sdc_const_not_approved'} //No
])

// the ids used below do not correspond to anything because the values 
export const STUDENT_ASSIST_TECH_1:IMappedList<IDataDefinition> = initMappedList([    
    { id: '2', tag: 'chrome',    label: 'sdc_assist_tech_chrome'},//Read&Write Google Chrome Extension
    { id: '1', tag: 'other',     label: 'sdc_1_assist_tech_other'},
    //{ id: '3', tag: 'kurz_dl',   label: 'sdc_assist_tech_kurz_dl'},//Kurzweil: the downloadable version
    //{ id: '4', tag: 'kurz_ext',  label: 'sdc_assist_tech_kurz_ext'},//Kurzweil: the extension for Firefox and Chrome
    //{ id: '5', tag: 'nvda',      label: 'sdc_assist_nvda'},//NVDA
    //{ id: '6', tag: 'voiceover', label: 'sdc_assist_voiceover'},//VoiceOver
    //{ id: '7', tag: 'readaloud', label: 'sdc_assist_readaloud'},//Read Aloud
    //{ id: '8', tag: 'jaws',      label: 'sdc_jaws'},//Jaws
    //{ id: '9', tag: 'chromevox', label: 'sdc_chromevox'},//ChromeVox
    //{ id: '10', tag: 'read',  label: 'sdc_natural_reader'},//Natural Reader
    //{ id: '11', tag: 'other',  label: 'sdc_assist_tech_other'},//Other
]);

export const STUDENT_ASSIST_TECH_2:IMappedList<IDataDefinition> = initMappedList([    
    //{ id: '2', tag: 'chrome',    label: 'sdc_assist_tech_chrome'},//Read&Write Google Chrome Extension
    { id: '3', tag: 'kurz_dl',   label: 'sdc_assist_tech_kurz_dl'},//Kurzweil: the downloadable version
    { id: '4', tag: 'kurz_ext',  label: 'sdc_assist_tech_kurz_ext'},//Kurzweil: the extension for Firefox and Chrome
    { id: '5', tag: 'nvda',      label: 'sdc_assist_nvda'},//NVDA
    { id: '6', tag: 'voiceover', label: 'sdc_assist_voiceover'},//VoiceOver
    { id: '7', tag: 'readaloud', label: 'sdc_assist_readaloud'},//Read Aloud
    { id: '8', tag: 'jaws',      label: 'sdc_jaws'},//Jaws
    { id: '9', tag: 'chromevox', label: 'sdc_chromevox'},//ChromeVox
    { id: '10', tag: 'read',  label: 'sdc_natural_reader'},//Natural Reader
    { id: '11', tag: 'other',  label: 'sdc_assist_tech_other'},//Other
]);

export const STUDENT_ASSIST_TECH_3:IMappedList<IDataDefinition> = initMappedList([    
    { id: '12', tag: 'chrome_2', label: 'sdc_assist_tech_chrome_2'},//Read&Write for Google
]);

export const STUDENT_BRAILLE:IMappedList<IDataDefinition> = initMappedList([ 
    { id: '1', label: 'sdc_braille_contr', lang:'fr'},//Contracted FR
    { id: '2', label: 'sdc_braille_uncontr', lang: 'fr'},//Uncontracted FR
    { id: '3', label: 'sdc_braille_contr', lang:'en'},//Contracted EN
    { id: '4', label: 'sdc_braille_uncontr', lang:'en'},//Uncontracted EN
    { id: EQAO_DB_NULL, label: 'sdc_const_no'} //No
]);


/*
export const STUDENT_BRAILLE_FR:IMappedList<IDataDefinition> = initMappedList([ 
    { id: '1', label: 'sdc_braille_contr'},//Contracted
    { id: '2', label: 'sdc_braille_uncontr'},//Uncontracted
    { id: EQAO_DB_NULL, label: 'sdc_const_no'} //No
]);
*/

export const STUDENT_SINGLE_CHECK:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', tag: '', label: ''},
]);

export const STUDENT_PJ_AUDIO_SINGLE_CHECK:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', tag: '', label: 'sdc_gr36_audio_title_sub'},
]);

export const GENERAL_SINGLE_CHECK:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', tag: '', label: ''},
]);

export const STUDENT_COLOURED_PRINT:IMappedList<IDataDefinition> = initMappedList([    
    { id: '1', tag: 'blue', label: 'sdc_col_print_blue'},//Blue
    { id: '2', tag: 'green', label: 'sdc_col_print_green'},//Green
    { id: '3', tag: 'yellow', label: 'sdc_col_print_yellow'},//Yellow
]);

// Temporary Sample BC FSA constants
export const FSA_GRADE_LEVEL:IMappedList<IDataDefinition> = initMappedList([
    { id: '4', label: '4'},
    { id: '7', label: '7'},
]);
export const FSA_ANGLOPHONE_COURSE_CODE:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'NME4 & LTE4'},
    { id: '2', label: 'NME7 & LTE7'},
]);
export const FSA_FRANCOPHONE_COURSE_CODE:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'NMF4 & LTF4'},
    { id: '2', label: 'NMF7 & LTF7'},
]);
export const FSA_ACCOMMODATIONS:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'Accommodation 1', },
    { id: '2', label: 'Accommodation 2',},
    { id: '3', label: 'Accommodation 3', },
]);
export const FSA_ACCESSIBILITY:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'Braile', },
    { id: '2', label: 'Large Print Version',},
]);
export const FSA_REQUEST_STATUS:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'N/A', },
    { id: '2', label: 'Submitted by School Admin',},
    { id: '3', label: 'Approved by Ministry Admin Coordinator', },
    { id: '4', label: 'Assigned to PRCVI',},
    { id: '5', label: 'Delivered to School', },
])
// Temporary Sample BC GRAD constants
export const BC_GRAD_GRADE:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: '9'},
    { id: '2', label: '10'},
    { id: '3', label: '11'},
    { id: '4', label: '12'},
])
export const BC_GRAD_ANGLOPHONE:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'NME10'},
    { id: '2', label: 'LTE10'},
    { id: '3', label: 'LTE12'},
])
export const BC_GRAD_FRANCOPHONE:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'NMF10'},
    { id: '2', label: 'LTP10'},
    { id: '3', label: 'LTP12'},
    { id: '4', label: 'LTF12'},
])
export const BC_GRAD_REGISTERED_DATES:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: '2021/11'},
    { id: '2', label: '2022/02'},
    { id: '3', label: '2022/06'},
    { id: '4', label: '2022/04'},
])
export const BC_GRAD_STUDENT_ACCOUNT_OPTIONS:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'Active'},
    { id: '2', label: 'No account created yet'},
])
export const GRAD_REQUEST_STATUS:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'N/A', },
    { id: '2', label: 'Submitted by School Admin',},
    { id: '3', label: 'Approved by Ministry Admin Coordinator', },
    { id: '4', label: 'Assigned to Vretta\'s Design Team', },
    { id: '5', label: 'Assigned to PRCVI',},
    { id: '6', label: 'Delivered to School', },
])
export const GRAD_SESSION_CODES:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'Session 1', },
    { id: '2', label: 'Session 2',},
    { id: '3', label: 'Session 3', },
]);
export const GRAD_ACCESSIBILITY:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'Braile', },
    { id: '2', label: 'Large Print',},
]);
export const GRAD_PRIMARY_IEDC:IMappedList<IDataDefinition> = initMappedList([
    { id: '1', label: 'PRCVI', },
    { id: '2', label: 'TSVI',},
]);

export const EQAO_ASSESSMENTS = [ 
    "Junior",
    "Primary",
    "G9",
    "OSSLT"
]

export const NBED_ASSESSMENTS = [
    "NBED_TCLE",
    "NBED_TCN",
    "NBED_SCIENCES8"
]

export const ABED_ASSESSMENTS = [ // todo:DB_DATA_MODEL
    "ABED_SAMPLE",
    'ABED_GRADE_6',
    'ABED_GRADE_9',
    'ABED_GRADE_12', 
    'ABED_CAEC',
]

export const classLabelABEDKeys =  // todo:DB_DATA_MODEL
[
    "_abed_grade_6_eqao_g9_class_code_label", 
    "_abed_grade_9_eqao_g9_class_code_label",
    "_abed_grade_12_eqao_g9_class_code_label"
];

export const classCodeABEDKeys =
[
    "_abed_grade_6_eqao_g9_class_code", 
    "_abed_grade_9_eqao_g9_class_code",
    "_abed_grade_12_eqao_g9_class_code"   
]
