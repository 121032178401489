<div class="time-input">
  <input type="number" min="0" max="23" maxlength="2" placeholder="{{tra('lbl_hour')}}" class="input is-small" [formControl]="hour"  >
  <!-- <input type="number" min="1" max="12" maxlength="2" [placeholder]="getHourplaceholder()" class="input is-small" [formControl]="hour"  > -->
  <span class="time-seperator">:</span>
  <input type="number" min="0" max="59" maxlength="2" placeholder="Min."  class="input is-small"  [formControl]="minutes"  >
  <div class="select is-small">
    <select [formControl]="period" >
      <option value=""></option>    
      <option [value]="Periods.AM">{{tra('lbl_am')}}</option>    
      <option [value]="Periods.PM">{{tra('lbl_pm')}}</option>        
    </select>
  </div>
</div>
