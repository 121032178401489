import * as moment from 'moment-timezone';
import * as _ from 'lodash';

import { Component, OnInit, OnDestroy } from '@angular/core';
import { LangService } from '../../core/lang.service';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { MyApplAccountService } from '../my-appl-account.service';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';
import { ApiFailCode } from '../../api/constants/access-controls';
import { IQuestionConfig } from '../../ui-item-maker/item-set-editor/models';
import { IRoutingRule } from '../../ui-item-maker/item-set-editor/models/assessment-framework';
import { lzDecompressProps } from '../../core/util/lzstring';
import { StudentSoftLockService } from 'src/app/ui-student/student-soft-lock.service';
import { StudentG9ConnectionService } from 'src/app/ui-student/student-g9-connection.service';
import { StudentG9DashboardService } from 'src/app/ui-student/student-g9-dashboard.service';
import { CALC_FRAME_ID } from 'src/app/ui-testrunner/widget-calculator/widget-calculator.component';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

export enum WarningModes {
  INVALID_SEB = 'INVALID_SEB',
  ACCEPT_ATTEST = 'ACCEPT_ATTEST',
  SELECT_LANG = 'SELECT_LANG',
  NOT_BOOKED_APPL = 'NOT_BOOKED_APPL',
  NO_QUESTIONS_FOUND = 'NO_QUESTIONS_FOUND',
  UNKNOWN = 'UNKNOWN',
  LOGIN = 'LOGIN',
  INVALID_SEB_BYOD = 'INVALID_SEB_BYOD'
}

export interface ITestAttemptRes {
  lang: string;
  question_index: number;
  section_index: number;
  module_id?: number;
  attempt_key: string;
  attemptId: number;
  testDesign: ITestDesignPayload;
  questions: any[];
  questionStates: any;
  date_time_start: any; /// date
  is_issue_reporting_enabled: number;
  time_ext_m: number;
  test_window_time: number;
  sections_allowed?: number[];
  subsession_index?: number;
  framework?;
  is_test_session_softlock_disabled: boolean;
  delivery_format?: string;
}

export interface ITestDesignPayload {
  label?: string;
  sections: ISectionDef[];
  panelModules?: IPanelModuleDef[];
  panelRouting?: {[key:string]: Array<IRoutingRule>};
  isPanelRoutingByNumCorrect?: boolean;
  questionDb?: {[key: number]: IQuestionConfig};
  testletIds?: any[];
  useQuestionLabel?: boolean;
  questionWordSlug?: string;
  useSectionCaptions?: boolean;
  sourceFormId?: string;
  testFormId?: string | number;
  __meta?: ITestMeta; // likely deprecated... from sample questions
  ////
  testFormType?: string,
  isTimerDisabled?: boolean,
  referenceDocumentPages?: IPageDef[],
  helpPageId__cache?: number,
  helpPageId?: IPageDef[],
  questionSrcDb?: Map<any, any>
}

interface IPageDef {
  itemId: number,
  caption:string,
}

export interface IQuestionSetDef {
  questions: number[];
  preamble?: number;
  preambleList?: number[];
  postambleList?: number[];
  caption?: string;

  hasCalculator?: boolean;
  hasNotepad?: boolean;
  hasFormulas?: boolean;
  isShuffleDisabled?: boolean;
  disableScoring?: boolean;
  disableFlagging?: boolean;
  msgReqFill?: string;
  disableLeftBar?: boolean;
  isTimeLimit?: boolean;
  infoCaption?: boolean;
  submissionText?: string;
  notepadText?: string;
  isConditional?: boolean;
  timeLimitMinutes?: number;
  preambleThumbnail?: string,
  preambleThumbnailSelected?: string,
  preambleThumbnailText?: string,
  conditionOnItem?: string;
  conditionOnOption?: string;
  isConfigExpanded?: string; // only used for the config screen
  areQuestionsSkippable?: boolean;
  sidebarThumbnailEn?: string;
  sidebarThumbnailFr?: string;
  disableBackButton?: boolean;
  isPauseOnSubm?: boolean;
}

export interface ISectionDef extends IQuestionSetDef {
  sectionId?:number,
  __meta?: ISectionMeta;  // likely deprecated... from sample questions
}

export interface IPanelModuleDef extends IQuestionSetDef {
  moduleId: number,
  routingExclusions?: {
    [itemId:number]: boolean,
  }
  orderedBuckets?: {
    position: number,
    itemIds:number[]
  }[]
}

export interface SectionDrawingCtx {
  section?: number,
  ctx: string
}

export interface ITestMeta {
  qs?: number; // how many questions are in the test
}
export interface ISectionMeta {
  qs?: number; // how many questions are in the current section
  qsPrec?: number; // how many questions appear
  markLoc?: string; // % value indicating where the marker should be placed
}


@Component({
  selector: 'view-tt-test-runner',
  templateUrl: './view-tt-test-runner.component.html',
  styleUrls: ['./view-tt-test-runner.component.scss']
})
export class ViewTtTestRunnerComponent implements OnInit, OnDestroy {

  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private route: ActivatedRoute,
    private loginGuard: LoginGuardService,
    public my: MyApplAccountService,
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    public lang: LangService,
    private studentSoftLock: StudentSoftLockService,
    public studentG9Connection: StudentG9ConnectionService,
    private dash: StudentG9DashboardService,
    private whiteLabel: WhitelabelService
  ) { }

  public breadcrumb: IBreadcrumbRoute[] = [];
  public sessionId: number;
  public instit_group_id: number;
  public testDesign: ITestDesignPayload;
  public questionSrcDb;
  public testLang: string;
  public selectedLang: string;
  public isLoadingAttempt: boolean;
  public WarningModes = WarningModes;
  public isLoaded: boolean;
  public time_ext_m = 0;
  public test_window_time = 0;

  private isInited: boolean;
  private routeSub: Subscription;

  public currentWarning = null;
  unknownWarningErrorMsg: string;


  regularTimeRemaining: string;
  extraTimeRemaining: string;
  isNotYetOpen: boolean;
  timeTillOpen: string;
  dateTimeStart: moment.Moment;
  testAttemptInfo: ITestAttemptRes;
  testTakerName: string;
  attemptKey: string;
  isIssueReportingEnabled: boolean;
  isSessionSoftLockDisabled: boolean;

  subscription = new Subscription();

  ngOnInit() {
    this.isSessionSoftLockDisabled = false;
    this.loginGuard.activate();
    this.breadcrumb = [
      this.breadcrumbsService.APPLICANT_LANDING(),
      this.breadcrumbsService.APPLICANT_DASHBOARD(),
      this.breadcrumbsService._CURRENT( this.lang.tra('booknow_btn'), this.router.url),
    ];
    this.routeSub = this.route.params.subscribe(routeParams => {
      this.sessionId = parseInt(routeParams['sessionId']);
      this.initRouteView();
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    document.removeEventListener('fullscreenchange', this.setStudentSoftLock, true);
    window.onblur = () => {}
  }

  initRouteView() {
    this.subscription.add(
      this.my.sub().subscribe(info => {
        if (info) {
          this.isInited = true;
          this.loadAttempt();
        }
      })
    );
  }

  acceptAttestation(){
    const test_session_id = this.getTestSessionId();
    this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST,
      { test_session_id }
    )
    .then(()=>{
      this.loadAttempt();
    })
  }

  smcsSelectTestLang(lang: string) {
    this.lang.setCurrentLanguage(lang);
    this.selectedLang = lang;
    this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG,
      { test_session_id: this.getTestSessionId(), lang }
    ).then(()=>{
      this.loadAttempt();
    })
  }

  selectTestLang(lang: string) {
    const test_session_id = this.getTestSessionId();
    this.loginGuard.confirmationReqActivate({
      caption: this.lang.tra('txt_confirm_lang_test_select'),
      btnProceedConfig: {
        caption: this.lang.tra('lbl_yes')
      },
      confirm: () => {
        this.lang.setCurrentLanguage(lang);
        this.selectedLang = lang;
        this.auth.apiCreate(
          this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG,
          { test_session_id, lang }
        )
        .then(()=>{
          this.loadAttempt();
        })
      }
    });
    // 
  }

  getTestSessionId(){
    return parseInt('' + this.sessionId);
  }

  asmtFmrk;
  loadAttempt() {
    this.isLoadingAttempt = true;
    const test_session_id: number = this.getTestSessionId();
    return this.auth
      .apiFind(
        this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT,
        { query: {test_session_id} }
      )
      .then((res: ITestAttemptRes[]) => {

        this.auth.apiFind(this.routes.TEST_TAKER_TEST_SESSIONS_BOOKING, this.sessionId).then(session => {
          if (session.length > 0) {
            this.instit_group_id = session[0].testSession.test_session_group_id;
          }

          const testAttemptInfo = res[0];
          this.testAttemptInfo = testAttemptInfo;
          this.isSessionSoftLockDisabled = this.testAttemptInfo.is_test_session_softlock_disabled;
        // if ((this.auth.activeKioskPassword || this.dash.getSchoolSoftLockEnable() || this.dash.getSchoolSoftLockEnableG9())) {
          if (true){
            this.forceFullScreen();
            this.detectFullScreen()
            window.onblur = this.windowBlurred;
            window.onfocus = this.windowFocused;
          }


          const testDesign = testAttemptInfo.testDesign;
          this.testDesign = {...testDesign},
              this.testLang = testAttemptInfo.lang;
          this.dateTimeStart = moment.tz(testAttemptInfo.date_time_start, moment.tz.guess());
          this.testTakerName = this.my.getDisplayName();
            this.asmtFmrk = testAttemptInfo.framework ? JSON.parse(testAttemptInfo.framework) : {};
          this.attemptKey = testAttemptInfo.attempt_key;
          this.isIssueReportingEnabled = testAttemptInfo.is_issue_reporting_enabled === 1;
          this.questionSrcDb = new Map();
          this.time_ext_m = testAttemptInfo.time_ext_m;
          this.test_window_time = testAttemptInfo.test_window_time;
          Object.keys(testDesign.questionDb).forEach(questionId => {
            try {
              const question = testDesign.questionDb[questionId];
              question.test_question_version_id = questionId;
              this.questionSrcDb.set(+questionId, question);
            } catch (err) {
              console.warn('could not process question id', questionId, err);
            }
          });
          this.updateTimeRemaining();
          setInterval(this.updateTimeRemaining, 10 * 1000);

          this.currentWarning = null;
          this.isLoaded = true;
          this.isLoadingAttempt = false;
          // console.log('new vals', this.testTakerName, this.attemptKey)
        });
      })
      .catch(e => {
        this.isLoadingAttempt = false;
        if(this.isSMCS()){
          this.acceptAttestation();
          this.smcsSelectTestLang('en');
        }else {
          this.currentWarning = this.parseAttemptLoadErrorMessage(e.message);
        }
      });
  }
  updateTimeRemaining = () => {
    // const regularTime = 2;
    const totalTime = this.test_window_time + this.time_ext_m;
    const leadingZero = (num: number) => {
      let str = '' + Math.floor(num);
      if (str.length === 1) {
        return '0' + str;
      }
      return str;
    };
    const renderTime = (timeSinceStart_m: number, totalTime= 0) => {
      const timeRemaining_m = Math.abs(totalTime - timeSinceStart_m);
      const timeRemaining_h = Math.floor(timeRemaining_m / 60);
      const timeRemaining_m_r = Math.floor(timeRemaining_m - timeRemaining_h * 60);
      return leadingZero(timeRemaining_h) + ':' + leadingZero(timeRemaining_m_r);
    };
    const timeSinceStart_ms = - this.dateTimeStart.diff(moment());
    const timeSinceStart_m = (timeSinceStart_ms / 1000) / 60;
    this.isNotYetOpen = (timeSinceStart_ms < 0);
    if (this.isNotYetOpen) {
      this.timeTillOpen = renderTime(-timeSinceStart_m);
    }
    if (timeSinceStart_m >= totalTime) {
      this.regularTimeRemaining = '00:00';
    } else {
      this.regularTimeRemaining = renderTime(timeSinceStart_m, totalTime);
    }
  }

  public saveQuestionResponse = (data: any) => {
    data = lzDecompressProps(data, ['response_raw', 'response']);
    return this.auth.apiCreate(
      this.routes.TEST_TAKER_INVIGILATION_QUESTION_RESPONSE,
      {
        ... data,
        test_attempt_id:  this.testAttemptInfo.attemptId,
      }
    );
  }
  public submitTest = () => {
    return this.auth.apiPatch(
      this.routes.TEST_TAKER_INVIGILATION_QUESTION_RESPONSE, // bad name ... to do
      1,
      { test_attempt_id:  <any> this.testAttemptInfo.attemptId, isTestCenter: this.whiteLabel.getSiteFlag("TEST_CENTER") ? true : undefined }
    )
    .then( r => {
      this.my.gotoDashboard();
    });
  }

  public checkTime = () => {
    const test_session_id: number = parseInt('' + this.sessionId);
    return this.auth.apiFind(
      this.routes.TEST_TAKER_INVIGILATION_TEST_ATTEMPT_TIME,
      { query: {test_session_id} }
    )
    . then( time => {
      this.time_ext_m = time[0].time_ext_m;
    });
  }

  public checkChat = () => {
    return Promise.resolve();
  }


  public goBack() {
    this.router.navigate([
      this.lang.c(),
      AccountType.TEST_TAKER,
      'dashboard'
    ]);
  }


  isInSelectionStep(currentWarning:WarningModes){
    switch(currentWarning){
      case WarningModes.SELECT_LANG:
      case WarningModes.ACCEPT_ATTEST:
        return true;
      
      default: return false;
    }
  }

  private parseAttemptLoadErrorMessage(message: string) {
    switch (message) {

      case WarningModes.INVALID_SEB:
      case 'SEB header used but no matching available SEB headers found for this institution':
      case 'SEB header hash matched but is revoked':
      case 'SEB header does not match expected hash':
      case 'Missing SEB header':
        this.unknownWarningErrorMsg = message;
        return WarningModes.INVALID_SEB;

      case ApiFailCode.GROUP_ROLE_REQ_FOR_ROUTE:
        return WarningModes.NOT_BOOKED_APPL;

      // to do: ideally every 
      case WarningModes.NO_QUESTIONS_FOUND:
      case WarningModes.SELECT_LANG:
      case WarningModes.ACCEPT_ATTEST:
        return <WarningModes> message;

      case 'Missing Institution ID':
      default :
        this.unknownWarningErrorMsg = message;
        return WarningModes.UNKNOWN;
    }
  }

  logout() {
    this.auth.logout() .then( r => {
      window.location.reload();
    });
  }

  async forceFullScreen() {
    let elem = document.documentElement;
    let methodToBeInvoked = elem.requestFullscreen || elem['mozRequestFullscreen']
      ||
      elem['msRequestFullscreen'];
    if (methodToBeInvoked) {
      try {
        await methodToBeInvoked.call(elem);
      }
      catch (err) {
        // Browser doesn't allow fullscreen without user interaction
        // Checking if current document is fullscreenn or not
        if(!this.isDocumentFullScreen()) this.getSessionSoftLockDisabled();
      }
    }
  }

  detectFullScreen() {
    document.addEventListener('fullscreenchange', (event) => {
      // console.log("full screen change!")
      if (!this.isDocumentFullScreen()) {
        this.getSessionSoftLockDisabled()
      }
    });
  }

  isDocumentFullScreen = () => document.fullscreenElement;

  currentStudentPosition = {
    stageIndex: null,
    questionCaption: null
  };
  seenStudentOffScreenWarning: boolean = false;


  setStudentSoftLock = (event?) => {
    
    //pause student
    // console.log('setting student soft lock..., this.testAttemptInfo: ', this.testAttemptInfo)
    this.studentSoftLock.pauseStudent()

    if (this.studentG9Connection) {
      this.studentG9Connection
        .updateStudentPosition({
          stageIndex: this.currentStudentPosition.stageIndex,
          questionCaption: this.currentStudentPosition.questionCaption,
          softLock: 1
        });
      if (!this.seenStudentOffScreenWarning) {
        this.seenStudentOffScreenWarning = false;
        this.studentSoftLock.getStudentSoftLock()
        .then(res => {
          if (res && (res.is_soft_lock_disabled /*&& res.is_test_session_softlock_disabled*/ )) return;
          this.loginGuard.confirmationReqActivate({
            caption: 'msg_student_navigate_popup',
            btnCancelConfig: {
              hide: true
            },
            confirm: () => {
              setTimeout( () => {
                if(this.isSMCS()) this.activatePauseNotif()
              }, 0)
            }
          })
        })
      }
    }
  }

  setStudentPosition($event) {
    this.currentStudentPosition.stageIndex = $event.stageIndex
    this.currentStudentPosition.questionCaption = $event.questionCaption
  }

  activatePauseNotif(){
    this.loginGuard.confirmationReqActivate({
      caption : this.lang.tra('msg_cannot_proceed_test') + ' ' + this.lang.tra('msg_session_pause') ,
      btnCancelConfig: {
        hide: true
      },
      btnProceedConfig: {
        caption: 'btn_retry'
      },
      confirm: () => {
        this.studentSoftLock.getStudentSoftLock()
          .then(res => {
            if (res){
              if(res.is_soft_lock_disabled /* || res.is_test_session_softlock_disabled*/ ) return; // todo: make sure API is returning a summarized variable
              if(!res.is_paused) return;              
              this.activatePauseNotif()              
            }
          })
      }
    })
  }

  isWindowFocused: boolean = true;

  windowFocused = (event?) => {
    this.isWindowFocused = true;
  }

  windowBlurred = (event?) => {
    this.isWindowFocused= false;
    let target = event?.target;
    if(target?.length && target[0].frameElement?.id === CALC_FRAME_ID) {
      target[0].onblur = this.windowBlurred;
      target[0].onfocus = this.windowFocused;
    }

    setTimeout(()=>{
      if(!this.isWindowFocused) {
        this.getSessionSoftLockDisabled(event)
      }
    }, 3000);
  }

  isSMCS(){
    return this.whiteLabel.getSiteFlag('IS_SMCS');
  }

  getSessionSoftLockDisabled(event?){
    this.studentSoftLock.getStudentSoftLock()
      .then(res => {
        if (res && res.is_soft_lock_disabled==1){
          this.isSessionSoftLockDisabled = true // todo: verify
        }
        if (!this.isSessionSoftLockDisabled){
          this.setStudentSoftLock(event)
        }
      })
  }

}
