import * as moment from 'moment-timezone';
import * as DBT from "../api/models/db-types";
import { Injectable } from '@angular/core';
import { CAEC } from '../domain/contexts/caec';
import { WhitelabelService } from '../domain/whitelabel.service';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {
  
  private timezone: string;
  private dynamicallyChecked: boolean = false;
  
  constructor(
      private whiteLabel: WhitelabelService,
      private auth: AuthService,
      private routes: RoutesService,
  ) { 
    this.initTimezone();
  }

  async initTimezone(){
    try{
      const timezoneCheck = await this.auth.apiGet(this.routes.DYNAMIC_TIMEZONE, -1); 
      if(timezoneCheck){
        this.timezone = timezoneCheck.timezone;
        this.dynamicallyChecked = true;
      }
    }catch(e){
      console.log(e);
      this.timezone = moment.tz.guess();    
    }
  }
  
  getTimezone() {
    if(!this.timezone || !this.dynamicallyChecked){
      return this.initTimezone().then(() => {
        return this.timezone;
      });
    }
    return this.timezone;  
  }
  
  moment(datetime: string | DBT.DATETIME | number): moment.Moment {
    if(!this.timezone){
      return this.initTimezone().then(() => {
        return moment.tz(datetime, this.timezone)
      });
    }
    return moment.tz(datetime, this.timezone)
  }

}
