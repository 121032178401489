import { Component, OnInit, Input } from '@angular/core';
import { ElementType, IContentElement, QuestionState } from '../models';
import { Subject } from 'rxjs';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { memo } from '../../ui-testrunner/element-render-video/element-render-video.component';
import { AuthService } from 'src/app/api/auth.service';
import { DomSanitizer } from '@angular/platform-browser';

const ALLOWED_FILENAME_CHARS_REGEX = /^([a-zA-ZÀ-Ÿ0-9 _\-\.\(\)\p{L}\u{327}\u0300-\u030C]+)$/gmu;
// Allowed character whitelist:
// a-z     Lowercase alphabets
// A-Z     Uppercase alphabets
// À-ÿ     Accepts lowercase and uppercase characters including letters with an umlaut
// 0-9     Numbers
//         Spaces
// _       Underscore
// -       Dashes
// .       Periods
// (       Opening brackets
// )       Closing brackets
// p{L}    Matches any kind of letter from any language
// \u{327} Combining Cedilla (can be used for ç, Ç)
// \u0300-\u030C French accents 

@Component({
  selector: 'element-render-upload',
  templateUrl: './element-render-upload.component.html',
  styleUrls: ['./element-render-upload.component.scss']
})
export class ElementRenderUploadComponent implements OnInit {

  @Input() element:IContentElement;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() changeCounter:number;
  @Input() questionPubSub?: QuestionPubSub;

  file = {}
  imagFileExtensions = ['jpg' , 'jpeg', 'jfif', 'pjpeg', 'pjp', 'png', 'svg', 'gif']
  imageUrls = new Map();
  isRequestingReupload:boolean;

  constructor(
    private auth: AuthService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {}

  getQuestionState(){
    if (this.questionState){
      let elQuestionState = this.questionState[this.element.entryId];
      if (!elQuestionState){
        elQuestionState = {
          type: ElementType.UPLOAD,
          url:  undefined
        };
        this.questionState[this.element.entryId] = elQuestionState;
      }
      return elQuestionState;
    }
  }

  getUploadedFileUrl(){
    const elQuestionState = this.getQuestionState();
    if (elQuestionState){
      return elQuestionState.url;
    }
  }

  getSanitizedUrl(url:string){
    return memo(this.imageUrls, url, url => {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

  getSanitizedUploadedFileUrl(){
    return this.getSanitizedUrl(this.getUploadedFileUrl());
  }

  startUpload(event: FileList) {
    // The File object
    const file = event.item(0);
    // Client-side validation example
    const fileTypeFull = file.type;
    const fileType = fileTypeFull.split('/')[0];
    const fileExt = file.name.split('.').pop();

    if (this.validateFileNameBeforeUpload(file.name)){
      this.auth
      .uploadFile(file, file.name, 'authoring', true)
      .then(res => {
        const qState = this.getQuestionState()
        qState.url = res.url;
        qState.fileName = file.name;
        qState.fileType = fileType
        qState.fileExt = fileExt
        qState.isStarted = true;
        qState.isFilled = true;
        this.isRequestingReupload = false;
      })
    }
  }

  fileNameIllegal: boolean = false;
  validateFileNameBeforeUpload(fileName: string){    
    if(fileName.match(ALLOWED_FILENAME_CHARS_REGEX)){
      this.fileNameIllegal = false;
      return true;
    }else{
      alert("Invalid file name, please make sure your file name only contains alphanumeric characters, spaces, and the following symbols: - _ . ( )");
      this.fileNameIllegal = true;
      return false;
    }
  }

  isWaitingForUpload(){
    return this.isRequestingReupload || !this.getUploadedFileUrl();
  }

  /**
   * Function to extract the file extension for a (s3) file download url
   * @param url The (s3) download link to the file
   * @returns Fhe file extension
   */
  getFileUrlExtension(url) {
    return url.split(/[#?]/)[0].split('.').pop().trim();
  }

  isFileImage(){
    const fileUrl = this.getUploadedFileUrl();
    const qState = this.getQuestionState();
    let ext = '';
    if (qState.fileExt)
      ext = qState.fileExt
    else
      ext = this.getFileUrlExtension(fileUrl);

    return this.imagFileExtensions.includes(ext);
  }

  getDownloadFileButtonName(){
    const qState = this.getQuestionState();
    if (qState.fileName)
      return qState.fileName;

    return "Download File";
  }
}
