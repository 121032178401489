import * as moment from 'moment-timezone';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges , Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangService } from 'src/app/core/lang.service';

enum Periods { // this is designed to match the format used by momentjs
  AM = 'am', 
  PM = 'pm' ,
};

export const FMT_TIME = 'hh:mma';

// export const dateAndTimeToDbDate = (date:string, time:string, timeFormat?:string) => {moment(`${date} ${time}`, 'YYYY-MM-DD '+ (timeFormat||FMT_TIME)).utc().format()};
export const dateAndTimeToDbDate = (date:string, time:string, timeFormat?:string, timezone?: any) => {
  if (!timezone) {
   return  moment(`${date} ${time}`, 'YYYY-MM-DD '+ (timeFormat||FMT_TIME)).utc().format()
  }
  return moment.tz(`${date} ${time}`, 'YYYY-MM-DD '+ (timeFormat||FMT_TIME), timezone).utc().format()
};

export const dateToTimeInputStr = (dateStr:string) => {
  const m = moment.tz(dateStr, moment.tz.guess());
  return m.format(FMT_TIME)
}

export const timeLeadingZero = (num:number) => {
  let str = ''+num;
  if (!str){
    str = '00';
  }
  if (str.length < 2){
    str = '0'+str;
  }
  return str.substr(0, 2)
}

@Component({
  selector: 'input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent implements OnInit, OnDestroy, OnChanges {

  @Input() fc:FormControl;
  @Input() isDisabled:boolean;
  @Output() timeSelected = new EventEmitter();

  constructor(private lang:LangService) { }

  hour = new FormControl();
  minutes = new FormControl();
  period = new FormControl();
  
  Periods = Periods;

  ngOnInit(): void {
    this.fc.valueChanges.subscribe(v => this.injectNewTime());
    this.hour.valueChanges.subscribe(v => this.updateFormValue());
    this.minutes.valueChanges.subscribe(v => this.updateFormValue());
    this.period.valueChanges.subscribe(v => this.updateFormValue());
    this.injectNewTime();
  }

  ngOnChanges(changes:SimpleChanges){
    if (changes.isDisabled){ this.updateDisabledStatus() }
  }

  ngOnDestroy() {

  }

  updateFormValue(){
    let hour = +(this.hour.value || 0);
    let minutes = +(this.minutes.value || 0);
    let period = this.period.value || 0;
    if (hour > 12){
      hour = 12;
      this.hour.setValue(hour);
    }
    if (minutes > 59){
      minutes = 59;
      this.minutes.setValue(minutes);
    }
    if (period !== Periods.PM){
      period = Periods.AM;
    }
    const timeStr = timeLeadingZero(hour) + ':' + timeLeadingZero(minutes) + period;
    if (timeStr !== this.fc.value){
      this.fc.setValue(timeStr);
      this.timeSelected.emit(timeStr);
    }
  }

  updateDisabledStatus(){
    if (this.isDisabled){
      this.hour.disable();
      this.minutes.disable();
      this.period.disable();
    }
    else{
      this.hour.enable();
      this.minutes.enable();
      this.period.enable();
    }
  }

  injectNewTime(){
    const time = this.fc.value;
    if (time){
      const hour = time.substr(0,2);
      const minutes = time.substr(3,2);
      const period = time.substr(5,2);
      this.hour.setValue(+hour);
      this.minutes.setValue(+minutes);
      this.period.setValue(period);
    }
  }

  getHourplaceholder(){
    return this.lang.tra('lbl_hour')
  }

  tra(slug){
    return this.lang.tra(slug);
  }

}
