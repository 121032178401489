<div *ngIf="isActive">
  <div *ngIf="isModeSelect" class="select is-small is-fullwidth">
    <select [formControl]="modeSelector" tabindex="0">
      <option *ngFor="let option of modeOptions" [value]="option.id">{{option.caption}}</option>
    </select>
  </div>
  <div [ngSwitch]="modeSelector.value">
    <div *ngSwitchCase="FilterSettingMode.VALUE">
      <div [ngSwitch]="dataType">
        <div *ngSwitchCase="FilterDataType.TEXT">
          <input class="input is-small is-fullwidth" type="text" [formControl]="value" tabindex="0">
        </div>
        <div *ngSwitchCase="FilterDataType.NUMBER">
          <input class="input is-small is-fullwidth" type="number" [formControl]="value" tabindex="0">
        </div>
      </div>
    </div>
    <div *ngSwitchCase="FilterSettingMode.RANGE">
      <input class="input is-small is-fullwidth" placeholder="Min" type="number" [formControl]="rangeFrom" tabindex="0">
      <input class="input is-small is-fullwidth" placeholder="Max" type="number" [formControl]="rangeTo" tabindex="0">
    </div>
    <div *ngSwitchCase="FilterSettingMode.LIST">
      <select [formControl]="selectedValue">
        <option *ngFor="let item of config.list" [value]="item.val"><tra [slug]="item.display"></tra></option>
      </select>
    </div>
    <div *ngSwitchCase="FilterSettingMode.RADIO">
      <div *ngFor="let option of selectionOptions">
        <input id={{option}} type="radio" [formControl]="optionSelected" [value]="radioValueMapping(option)">
        <label for={{option}} class="radio-label">{{option}}</label>
      </div>
    </div>
    <div *ngSwitchCase="FilterSettingMode.DATETIME">
      <div class="input-group">
        <div class="date-field-container">
          <label class="datetime-label"><tra slug="lbl_from"></tra></label>
          <input class="form-control" [placeholder]="lang.tra('lbl_date_1')"
                name="dp1" [formControl]="dateFrom" [(ngModel)]="dateFromModel" ngbDatepicker #d="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calendar-btn" (click)="d.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <ngb-timepicker class="time-display" size="small" [formControl]="timeFrom" [(ngModel)]="timeFromModel" [meridian]="meridian"></ngb-timepicker>
      </div>
      <div class="input-group">
        <div class="date-field-container">
          <div class="datetime-label" id="datetime-to-label"><label><tra slug="lbl_to"></tra></label></div>
          <input id="second-datepicker" class="form-control" [placeholder]="lang.tra('lbl_date_1')"
                name="dp2" [formControl]="dateTo" [(ngModel)]="dateToModel" ngbDatepicker #e="ngbDatepicker">
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar calendar-btn" (click)="e.toggle()" type="button">
              <i class="far fa-calendar-alt"></i>
            </button>
          </div>
        </div>
        <ngb-timepicker size="small" class="time-display" [formControl]="timeTo" [(ngModel)]="timeToModel" [meridian]="meridian"></ngb-timepicker>
      </div>
    </div>
  </div>
</div>