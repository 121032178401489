import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MyInstitutionService, IInstitutionInfo } from '../my-institution.service';
import { LangService } from '../../core/lang.service';
import { AccountType } from '../../constants/account-types';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from 'src/app/api/routes.service';

export enum InstMngView {
  INST_INFO = 'mng-inst-info',
  ACCOUNTS = 'mng-inst-accounts',
  ALL_SESSIONS = 'all-test-sessions',
  SEB_VALIDATION = 'seb-validation',
  STUDENTS = 'mng-students-accounts',
  REPORTING = 'mng-reporting',
  PENDING_APPEALS = 'pending-appeals',
  PENDING_ACCOMM = 'pending-accomm',
}

interface IIsEnabledObj {
  isEnabled: boolean
}

@Component({
  selector: 'pnel-my-institution',
  templateUrl: './pnel-my-institution.component.html',
  styleUrls: ['./pnel-my-institution.component.scss']
})
export class PnelMyInstitutionComponent implements OnInit, OnDestroy {

  @Input() currentView: InstMngView;
  subscription = new Subscription();
  public instInfo: IInstitutionInfo;
  public InstMngView = InstMngView;

  appealsEnabled: boolean = false;

  constructor(
    public lang: LangService,
    private myInst: MyInstitutionService,
    private whitelabel: WhitelabelService,
    private auth: AuthService,
    private routes: RoutesService,
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.myInst.sub().subscribe(this.updateInstInfo)
    );
    this.isAppealsEnabled();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateInstInfo = (instInfo: IInstitutionInfo) => {
    this.instInfo = instInfo;
    this.processInstInfoUnits();
  }

  processInstInfoUnits() {
    if(!this.instInfo) {
      return;
    }
    const unitProps = ["applicant_rt_policy_unit", "resp_time_policy_unit", "reg_buffer_policy_unit"]
    for(let unitProp of unitProps) {
      const unit = this.instInfo[unitProp];
      if(unit === "hours") {
        this.instInfo[unitProp]="txt_hours";
      } else if(unit === "days") {
        this.instInfo[unitProp]="txt_days";
      }
    }
  }



  isMeInstMngr() {
    return this.myInst.isInstMngr();
  }

  async isAppealsEnabled() {
    const isEnabled = await this.auth
      .apiFind(
        this.routes.TEST_ADMIN_ENABLED_APPEALS,
      )
    if (isEnabled[0]) this.appealsEnabled = true;
  }

  isShowBookingStats() {
    return this.isMeInstMngr() && this.whitelabel.getSiteFlag('BOOKING_POLICY_STATS');
  }

  isMeAccommCoord() {
    return this.myInst.isAccommCoord();
  }

  isMngStudents() {
    return this.whitelabel.getSiteFlag('INST_MNG_TT');
  }

  getRouterMngInstInfo ()        { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-inst-info`; }
  getRouterMngInstAccounts ()    { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-inst-accounts`; }
  getRouterMngStudentAccounts () { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-students-accounts`; }
  getRouterReporting()           { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/mng-reporting`; }
  getRouterAllTestSessions ()    { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/all-test-sessions`; }
  getRouterPendingAccomm ()      { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/pending-accomm/${this.instInfo.id}`; }
  getRouterPendingAppeals ()      { return `/${this.lang.getCurrentLanguage()}/${AccountType.TEST_ADMIN}/pending-appeals/${this.instInfo.id}`; }

  isReportShown() {
    return this.instInfo.is_report_shown;
  }

  isSMCS(){
    return this.whitelabel.getSiteFlag('IS_SMCS');
  }

  tra(slug, props?){
    const wxlSlug = this.whitelabel.getSiteText(slug);
    if(wxlSlug){
      slug = wxlSlug;
    }
    return this.lang.tra(slug, null, props);
  }

}
