import { API_ADDRESS_LOCAL } from "../../api/api-addr/l";
import { IContextData } from "./common";
import { EDeliveryOption } from "../../api/models/db/test-sessions.schema";
export let MPT_TEST_CTRL_GROUP_ID;


const footer = []

const apiAddress = (hostname:string) => {
    switch(hostname){
      case 'xqc1.mathproficiencytest.ca': return 'https://xqc1-api.mathproficiencytest.ca';
      // case 'qc1.mathproficiencytest.ca': return 'https://api-eassessment.vretta.com';
      // case 'qc1.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      case 'qc1.mathproficiencytest.ca': return 'https://qc1-api.mathproficiencytest.ca';
      // case 'localhost':
      case 'd2f95x08189c1c.cloudfront.net': return 'https://qc3-api.mathproficiencytest.ca';
      case 'qc3.mathproficiencytest.ca': return 'https://qc3-api.mathproficiencytest.ca';
      case 'caec-qc.vretta.com': return 'https://caec-qc-api.vretta.com';
      case 'caec-qc-dev.vretta.com': return 'https://caec-qc-dev-api.vretta.com';
      case 'caec-uat.vretta.com' : return 'https://caec-uat-api.vretta.com';
      case 'caec-transition.vretta.com' : return 'https://caec-uat-api.vretta.com';
      case 'caec.vretta.com' : return 'https://caec-api.vretta.com';
      case 'd1mk3faahbquaz.cloudfront.net' : 'https://caec-api.vretta.com';
      // case 'qc3.mathproficiencytest.ca': return 'https://api.mathproficiencytest.ca';
      // case 'localhost':  return 'https://api-eassessment.vretta.com';
      case 'localhost':
        // return 'https://qc3-api.mathproficiencytest.ca';
        // return 'https://api.mathproficiencytest.ca';
        // return 'https://caec-qc-dev-api.vretta.com';
        return API_ADDRESS_LOCAL;
      // case 'qc3.mathproficiencytest.ca':
      // case 'localhost':
      case 'testcompetencesmaths.ca':
      case 'mathproficiencytest.ca':
      case 'www.testcompetencesmaths.ca':
      case 'www.mathproficiencytest.ca':
      default: return 'https://api.mathproficiencytest.ca';
    }
  }

  const setMPTctrlID = (hostname:string) => {
    console.log(hostname)
    switch(hostname){
      case 'localhost': MPT_TEST_CTRL_GROUP_ID = 6397; break;
      case 'caec-qc.vretta.com':MPT_TEST_CTRL_GROUP_ID = 6397; break;
      default : MPT_TEST_CTRL_GROUP_ID = 6397;
    }
  }

export const CAEC:IContextData = {
    id: 'CAEC',
    homepageRoute: '/en/general/login',
    brandName: 'caec_long_title',
    defaultTimezone: 'America/Edmonton',
    logo: '',
    footer,
    setMPTctrlID,
    apiAddress,
    siteFlags: {
      BREADCRUMB_MENU: false,
      BOOKING_POLICY_STATS: true,
      TEST_CENTER: true,
      IS_RESULTS_PAGE_AVAILABLE: true,
      IS_CKEDITOR: true,
      IS_STU_WORDCOUNT_TOGGLE: true, // todo: currently only works with IS_STU_WORDCOUNT_SIMPLE
      IS_STU_WORDCOUNT_SIMPLE: true,
      IS_LANDING_PUBLIC_PRACTICE: true,
    },
    siteData: {
      deliveryOptions : [ EDeliveryOption.COMPUTER_LAB, EDeliveryOption.PAPER_BASED]
    },
    siteText: {
      copyright: `© Vretta Inc. ${new Date().getFullYear()} `,
      supportEmail: 'edc.caec@gov.ab.ca',
      supportPhone: '780-427-5318 (option 3)',
      my_inst: 'ta_my_faculty_lbl',
      my_inst_admin: 'caec_lbl_inst_mngr_you',
      txt_support_popup: 'txt_support_popup_caec',
      current_system: 'caec_short',
      tt_book_session_msg: 'gen_txt_instr_tt_book_ts', //mpt slug: txt_instr_tt_book_ts
      form_slug: 'caec',
      TW_CTRL_GROUP_SLUG: 'ABED_CAEC',
    },
    hosts: [
        'qc3.mathproficiencytest.ca',
        'localhost:4200',
        'mathproficiencytest.ca',
        'd2f95x08189c1c.cloudfront.net',
        'testcompetencesmaths.ca',
        'www.mathproficiencytest.ca',
        'www.testcompetencesmaths.ca',
        'qc1.mathproficiencytest.ca',
    ]
}
