import * as _ from 'lodash';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import {  LangService } from '../../core/lang.service';
import { questionSrcDb } from './data/questions';
import { Router, ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { Subscription } from 'rxjs';
import {  preambleBySection } from './data/preambles';
import { TextToSpeechService } from '../text-to-speech.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/api/auth.service';
import { RoutesService } from '../../api/routes.service';

type ISectionDef = any;
type ITestDef = any;
type ISectionMeta = any;

const LOCAL_STORAGE_KEY = 'mpt-sample';
const OBF_CONST = 'j292j';
const obf = (o: any) => {
  return OBF_CONST + JSON.stringify(o);
};
const deobf = (str: string) => {
  return JSON.parse( str.substr(OBF_CONST.length, str.length - OBF_CONST.length)  );
};

interface ITestState {
  languageCode: string;
  currentSectionIndex: number;
  currentQuestionIndex: number;
  questionStates: any;
  isSubmitted?: boolean;
}

@Component({
  selector: 'sample-questions',
  templateUrl: './sample-questions.component.html',
  styleUrls: ['./sample-questions.component.scss']
})
export class SampleQuestionsComponent implements OnInit, OnDestroy {

  constructor(
    public lang: LangService,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private routes: RoutesService,
    private textToSpeech: TextToSpeechService,
    private auth: AuthService
  ) { }

  @ViewChild('questionReviewRunner', { static: false }) questionReviewRunner: ElementRef<HTMLDivElement>;

  zoomLevel = 1;
  minZoomLevel = 0.6;
  maxZoomLevel = 3;
  zoomIncrement = 0.2;

  practiceNumber: string;
  testState: ITestState;
  currentTestDesign: ITestDef;

  routeSub: Subscription;
  currentLangCode: string;
  isShowingSectionInfo: boolean;
  isFormulasToggledOn: boolean;
  isCalcToggledOn: boolean;
  isHighContrast: boolean;
  isQuesInitialized: boolean = false;
  private questionSrcDb;
  private testFormData;


  currentModal: any;

  questionScores = new Map();

  isHelpOverlay: boolean;
  helpScreenLayout: any;
  lineReaderPosition: {x: number, y: number} = {x: 0, y: 0};

  toggleTextToSpeech() {
    this.textToSpeech.toggle();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.routeSub = this.route.params.subscribe(e => this.getRouteParams(e));
    this.logPracticeTestStart('PRACTICE_TEST_START');
    this.clearLocalStorage();
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
  }

  logPracticeTestStart(state: string) {
    this.auth.apiCreate('public/log', {
      slug: state,
      data: {
      }
    });
  }

  getRouteParams(routeParams: any) {
    switch (routeParams['lang']) {
      case 'fr': this.currentLangCode = 'fr'; break;
      default:
      case 'en': this.currentLangCode = 'en'; break;
    }
    this.practiceNumber = routeParams['practiceNum']
    const params = {query: {lang: this.lang.c()}}
    return this.auth
      .apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, this.practiceNumber, params)        // 1. https://www.mathproficiencytest.ca/test-runner/mpt-practice-test/practice-test.json
      .then(PRACTICE_TEST_DATA => {            
        if (this.practiceNumber == '1') {
          this.currentTestDesign = <any> PRACTICE_TEST_DATA.currentTestDesign;
          this.questionSrcDb = <any> PRACTICE_TEST_DATA.questionSrcDb;
        } else {
          this.currentTestDesign = <any> PRACTICE_TEST_DATA;
          this.questionSrcDb = <any> PRACTICE_TEST_DATA.questionDb;
        }
        //delete this.currentTestDesign.sections[3].isShuffleDisabled  //remove this later
      })
      .then(() => {
        return this.auth
          .apiGet(this.routes.TEST_TAKER_DATA_DOWNLOAD, 0) // https://www.mathproficiencytest.ca/test-runner/mpt-help/help.json
          .then(helpScreenLayout => {
            this.helpScreenLayout = <any> helpScreenLayout;
          });
      })
      .then(() => {
        this.initTestDef();
        this.initTestState();
        if (this.testState.isSubmitted) {
          this.scoreAllQuestions();
          this.feedAnswersToAllQuestions();
        }
    });
  }


  initTestDef() {
    const practiceNum = parseInt(this.practiceNumber);
    if(practiceNum === 2){
      this.questionSrcDb = this.currentTestDesign.questionSrcDb;
    }
    this.isQuesInitialized = true;
    
    // compute section meta (mostly for the progress bar)
    let qsPrec = 0;
    this.currentTestDesign.sections.forEach(section => {
      const qs = section.questions.length;
      let meta: ISectionMeta = { qs, qsPrec, };
      section.__meta = meta;
      qsPrec += qs;
    });
    const qsTotal = qsPrec;
    // store the total number of questions
    this.currentTestDesign.__meta = {qs: qsTotal};
    // compute the position of the marker on the progress bar
    this.currentTestDesign.sections.forEach(section => {
      const m = section.__meta;
      const qIG = m.qsPrec + m.qs;
      const proportion = qIG / qsTotal;
      const markLoc = this.renderLocProp(proportion);
      section.__meta.markLoc = markLoc;
    });
  }

  private renderLocProp(p: number, asNum: boolean= false) {
    return Math.round(100 * p) + (asNum ? '' : '%');
  }

  getCurrentProgressLoc(asNum: boolean= false) {
    const section = this.getCurrentSection();
    const qIR = this.getCurrentQuestionIndex() + 1;
    const qsTotal = this.currentTestDesign.__meta.qs;
    const qIG = section.__meta.qsPrec + qIR;
    const proportion = qIG / qsTotal;
    return this.renderLocProp(proportion, asNum);
  }

  getHelpScreenLayout() {
    if (this.helpScreenLayout) {
      if (this.lang.c() === 'fr') {
        return this.helpScreenLayout.langLink;
      }
      return this.helpScreenLayout;
    }
  }

  toggleHelpScreen() {
    this.isHelpOverlay = !this.isHelpOverlay;
  }

  isLineReaderActive:boolean;

  private lineReaderKeyboardDown = (event: KeyboardEvent) => {
    const baseStepX = 10;
    const baseStepY = 10;
    const fastRatio = 2.5;
    const slowRatio = 0.3;
    const defaultRatio = 1;
    let ratio = defaultRatio;
    let stepX: number;
    let stepY: number;

    if (event.shiftKey && event.ctrlKey) {
      ratio = defaultRatio;
    } else if (event.shiftKey) {
      ratio = fastRatio;
    } else if (event.ctrlKey) {
      ratio = slowRatio;
    }
    stepX = baseStepX * ratio;
    stepY = baseStepY * ratio;

    const pos = this.lineReaderPosition;
    switch (event.key) {
      case 'ArrowUp':
        this.lineReaderPosition = { x: pos.x, y: pos.y - stepY }; break;
      case 'ArrowDown':
        this.lineReaderPosition = { x: pos.x, y: pos.y + stepY }; break;
      case 'ArrowLeft':
        this.lineReaderPosition = { x: pos.x - stepX, y: pos.y }; break;
      case 'ArrowRight':
        this.lineReaderPosition = { x: pos.x + stepX, y: pos.y }; break;
    }
  }

  toggleLineReader() {
    this.isLineReaderActive = !this.isLineReaderActive;
    if (this.isLineReaderActive) {
      window.addEventListener('keydown', this.lineReaderKeyboardDown);
    } else {
      this.lineReaderPosition = {x:0, y:0};
      window.removeEventListener('keydown', this.lineReaderKeyboardDown);
    }
  }

  initTestState() {
    this.testState = null;
    try {
      let cachedState = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (cachedState && cachedState !== '') {
        this.testState = deobf(cachedState);
        if (this.testState.languageCode !== this.lang.c()) {
          // do not restore state if it was in a different language
          this.testState = null;
        }
      }
    } catch (e) {
      console.error('malformed test state');
    }
    if (!this.testState) {
      this.testState = {
        languageCode: this.lang.c(),
        currentSectionIndex: 0,
        currentQuestionIndex: 0,
        questionStates: {},
      };
    }
    this.isShowingSectionInfo = true;
  }

  showSectionInfo() {
    this.isShowingSectionInfo = true;
  }
  hideSectionInfo() {
    this.isShowingSectionInfo = false;
  }

  getSectionInfoContent() {
    const sectionIndex = this.getCurrentSectionIndex();
    const sectionInfoByIndex = this.getSectionInfoContentByIndex(sectionIndex);
    const sectionInfo = this.questionSrcDb.filter(sec => {
      return sec.key == sectionInfoByIndex;
    });
    return sectionInfo[0].val;
    // return this.getSectionInfoContentByIndex(this.getCurrentSectionIndex());
  }
  getSectionInfoContentByIndex(i: number) {
    const currentSection = this.getCurrentSection();
    const preambleIdx = currentSection.preamble;
    return preambleIdx;
  }
  activateModal(caption: string, onConfirm: any) {
    this.currentModal = {caption, onConfirm};
  }
  confirmModal() {
    this.currentModal.onConfirm();
    this.closeModal();
  }
  closeModal() {
    this.currentModal = null;
  }

  updateLocalStorage() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, obf(this.testState));
  }

  clearLocalStorage() {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, '');
    this.testState = null;
  }

  isFormulasAvailable() {
    return this.getCurrentSection().hasFormulas;
  }
  isCalcAvailable() {
    return this.getCurrentSection().hasCalculator;
  }

  getCurrentSectionIndex(): number {
    if (this.testState) {
      return this.testState.currentSectionIndex;
    }
    return -1;
  }
  getCurrentQuestionIndex(): number {
    if (this.testState) {
      return this.testState.currentQuestionIndex;
    }
    return -1;
  }
  getCurrentQuestionStates(): any {
    if (this.testState) {
      return this.testState.questionStates;
    }
    return {};
  }
  getCurrentSection(): ISectionDef {
    return this.getSection(this.getCurrentSectionIndex());
  }

  getSection(i: number) {
    return this.currentTestDesign.sections[i] || {hasCalculator: false, hasFormulas: false, questions: []};
  }

  getCurrentQuestions(): string[] {
    return (this.getCurrentSection()).questions || [];
  }

  getActiveQuestionId() {
    const qId = this.getCurrentQuestions()[this.getCurrentQuestionIndex()];
    if (!qId) {
      // console.warn('Null question');
    }
    return qId;
  }

  getActiveQuestionContent() {
    let content;
    if (this.testState.isSubmitted) {
      const currentSectionQuestions = (this.getSection(this.getCurrentSectionIndex())).questions || [];
      const currentQuesId = currentSectionQuestions[this.getCurrentQuestionIndex()];
      content = this.getQuestionDef(currentQuesId);
    } else {
      content = this.getQuestionDef(this.getActiveQuestionId());
    }
    // console.log('content', content)
    return content;
  }


  isCurrentQuestionFilled() {
    return this.isQuestionFilled(this.getCurrentQuestionIndex());
  }

  isQuestionFilled(qIndex: number) {
    const states = this.getCurrentQuestionStates();
    const qId = (this.getCurrentQuestions() || [])[qIndex];
    if (!qId) {
      return false;
    }
    let qState = states[qId];
    if (qState) {
      try {
        let isAllFilled = true;
        let isAtLeastOneFill = false;
        _.each(qState, entry => {
          isAtLeastOneFill = true;
          if (!entry.isFilled && entry.type === 'mcq') {
            isAllFilled = false;
          }
        });
        return isAllFilled || !isAtLeastOneFill;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  getActiveQuestionState() {
    const states = this.getCurrentQuestionStates();
    let qId = this.getActiveQuestionId();
    if (this.testState.isSubmitted) {
      const firstSectionQuestions = (this.getSection(this.getCurrentSectionIndex())).questions || [];
      const firstQuesId = firstSectionQuestions[this.getCurrentQuestionIndex()];
      qId = firstQuesId;
    }
    let qState = states[qId];
    if (!qState) {
      qState = states[qId] = {};
    }
    return qState;
  }

  getFirstQuesIndex() {
    return 0;
  }

  getFirstSectionIndex() {
    return 0;
  }

  getQuestionDef(taskId: string) {
    const question = this.questionSrcDb.filter(ques => {
      return ques.key == taskId;
    });
    return question[0].val;
  }

  scrollQuestionIntoView() {
    if (!this.isQuesInitialized) {
      console.log('waiting for questions to load');
    } else {
      if (this.questionReviewRunner && this.questionReviewRunner.nativeElement) {
        const el = this.questionReviewRunner.nativeElement;
        el.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    }
  }

  selectSectionAndQuestion(sectionIndex, questionIndex) {
    this.testState.currentSectionIndex = sectionIndex;
    this.testState.currentQuestionIndex = questionIndex;
    this.scrollQuestionIntoView();
    this.updateLocalStorage();
  }
  getQuestionScore(id: string) {
    return this.questionScores.get(id);
  }

  getAnswerKey() {
    // if(this.practiceNumber == '1'){
    //   if (this.lang.getCurrentLanguage() === 'fr') {
    //     return this.testFormData.ANS_FR;
    //   }
    //   return this.testFormData.ANS_EN;
    // }else{
    return this.questionSrcDb;  // need to chanage this
    // }
  }
  private isEResCorrect(eRes, eAns) {
    if (eRes && eRes.selections) {
      if (eRes.selections[0] && eRes.selections[0].i === eAns.optionIndex) {
        return true;
      }
    }
    return false;
  }
  feedAnswersToAllQuestions() {
    let answerKey = this.getAnswerKey();
    this.currentTestDesign.sections.forEach(section => {
      section.questions.forEach(qId => {
        const qAns = answerKey.find(i => i.key === qId);
        const q = this.getQuestionDef(qId);
        // to do : this is fixed to MCQ
        Object.keys(qAns).forEach(entryId => {
          const eAns = qAns[entryId];
          // to do: assuming flat question structures
          q.content.forEach(el => {
            if (el.entryId === entryId) {
              el.options.forEach( (option, optionIndex) => {
                if (eAns.optionIndex === optionIndex) {
                  option.isCorrect = true;
                }
              });
            }
          });
        });
      });
    });
  }
  scoreAllQuestions() {
    let answerKey = this.getAnswerKey();
    const states = this.getCurrentQuestionStates();
    this.currentTestDesign.sections.forEach(section => {
      section.questions.forEach(qId => {
        const qAns = answerKey.find(i => i.key === qId);
        const qRes = states[qId];
        let isAllCorrect = true;
        if (!qRes) {
          isAllCorrect = false;
        } else {
          Object.keys(qAns).forEach(entryId => {
            const eAns = qAns[entryId];
            const eRes = qRes[entryId];
            isAllCorrect = isAllCorrect && this.isEResCorrect(eRes, eAns);
          });
        }
        const isCorrect = isAllCorrect;
        this.questionScores.set(qId, isCorrect);
      });
    });
  }

  selectQuestion(questionIndex) {
    this.isShowingSectionInfo = false;
    this.isHelpOverlay = false;
    this.updateLocalStorage();
    this.testState.currentQuestionIndex = questionIndex;
    this.isFormulasToggledOn = false;
    this.isCalcToggledOn = false;
    window.scrollTo(0, 0);
    // console.log('isQuestionFilled', this.isQuestionFilled(questionIndex))
  }

  gotoNextQuestion() {
    this.selectQuestion(this.testState.currentQuestionIndex + 1);
  }

  isOnLastQuestion() {
    return this.testState.currentQuestionIndex >= this.getCurrentQuestions().length - 1;
  }

  countNumCurrentQuestionsUnfilled() {
    let numUnfilled = 0;
    let questionIds = this.getCurrentQuestions();
    if (!questionIds) {
      console.error('no questions');
      return 0;
    }
    questionIds.forEach((qId, qIndex) => {
      if (!this.isQuestionFilled(qIndex)) {
        numUnfilled ++;
      }
    });
    return numUnfilled;
  }

  leaveTest() {
    this.clearLocalStorage();
    this.router.navigate([
      this.lang.getCurrentLanguage(),
      'applicant',
      'learn',
      'prepare:sample_questions',
    ]);
  }

  reviewAndSubmit() {
    if (this.testState.currentSectionIndex > 2) {
      this.logPracticeTestStart('PRACTICE_TEST_END');
    }
    const numUnfilled = this.countNumCurrentQuestionsUnfilled();
    let preMessage = '';
    if (numUnfilled > 0) {
      preMessage += this.lang.tra('mpt_alert_unfilled_questions', null, {questionNum: numUnfilled}) + ' ';
    }



    // check if the test will be done with this
    if (this.testState.currentSectionIndex >= this.currentTestDesign.sections.length - 1) {
      this.activateModal(preMessage + this.lang.tra( this.getTestSubmissionTraSlug() ), () => {
        this.testState.isSubmitted = true;
        this.updateLocalStorage();
        this.scoreAllQuestions();
        this.feedAnswersToAllQuestions();
        this.selectSectionAndQuestion(0, 0);
      });
    } else {
      this.activateModal(preMessage + this.lang.tra('alert_KK_SUBMIT_SECTION'), () => {
        this.testState.currentSectionIndex ++;
        this.testState.currentQuestionIndex = 0;
        this.updateLocalStorage();
        this.isShowingSectionInfo = true;
      });
    }
  }

  getTestSubmissionTraSlug(){
    if (this.currentTestDesign && this.currentTestDesign.sections && this.currentTestDesign.sections.length > 1){
      return 'alert_KK_SUBMIT_TEST';
    }
    else{
      return 'alert_KK_SUBMIT_TEST_single_section'
    }
  }

  zoomIn() {
    if (this.zoomLevel + this.zoomIncrement <=  this.maxZoomLevel) {
      this.zoomLevel += this.zoomIncrement;
    }

  }
  zoomOut() {
    if (this.zoomLevel - this.zoomIncrement >=  this.minZoomLevel) {
      this.zoomLevel -= this.zoomIncrement;
    }
  }

  reportIssue() {
    this.activateModal( this.lang.tra('alert_REPORT_ISSUE'), () => {} );
  }

  checkTimeLeft() {
    this.activateModal( this.lang.tra('alert_TIME_LEFT'), () => {} );
  }

  toggleHiContrast() {
    this.isHighContrast = !this.isHighContrast;
  }
  toggleFormulas() {
    this.isFormulasToggledOn = !this.isFormulasToggledOn;
    if (this.isFormulasToggledOn) {
      window.scrollTo(0, 0);
    }
  }
  toggleCalc(event) {
    event.currentTarget.blur();
    this.isCalcToggledOn = !this.isCalcToggledOn;
    if (this.isCalcToggledOn) {
      window.scrollTo(0, 0);
    }
  }

  isLang(langCode: string) {
    return (langCode === this.lang.getCurrentLanguage());
  }

  isShowingCalc() {
    return this.isCalcToggledOn;
  }

}
