import { Injectable } from '@angular/core';
import { AuthService, getFrontendDomain } from './auth.service';

// local short hand used for defining valid api calls routes
const GET = 'get';
const POST = 'post';
const FIND = 'find';
const PATCH = 'patch';
const REMOVE = 'remove';
@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  constructor(
    private auth: AuthService,
  ) { }
  currentHost() {
    return getFrontendDomain() + '#';
    // return 'https://mathproficiencytest.ca/#'
  }
  
  ABED_MY_WALKN                                        = 'public/student/walk-in';
  ABED_PASI                                            = 'public/abed-pasi';
  ABED_WALKN_STUDENT                                   = 'public/educator/walk-in-students';
  ANALYTICS_EVENT                                      = 'public/analytics/event';
  ANALYTICS_SESSION                                    = 'public/analytics/session';
  ANON_SAMPLE_TEST_DESIGN_FORM                         = 'public/anon/sample-test-design-form';
  ASSESSMENTS_INFO                                     = 'public/assessments-info';
  AUTH_CONFIRM_EMAIL                                   = 'public/auth/confirm-email';
  AUTH_REGISTER_MFA                                    = 'public/auth/register-mfa';
  AUTH_RESET_PASSWORD                                  = 'public/auth/reset-password';
  AUTH_RESET_PASSWORD_REQUEST                          = 'public/auth/reset-password-request';
  AUTH_TEST_ADMIN                                      = 'public/auth/test-admin';
  AUTH_TEST_CERT                                       = 'public/auth/test-cert';
  AUTH_TEST_CTRL                                       = 'public/auth/test-ctrl';
  AUTH_TEST_TAKER                                      = 'public/auth/test-taker';
  BOARD_CURR_LEAD_SCHOOLS                              = 'public/board-curr-lead/schools';
  CONFIDENTIALITY_AGREEMENT                            = 'public/educator/confidentiality-agreement'
  CREDITS_CREDIT_SYSTEM                                = 'public/credits/credit-system';
  CREDITS_PAYMENT_SYSTEM                               = 'public/credits/payment-system';
  SYS_SEB_ENFORCEMENT                                  = 'public/sys-const/is-seb-enforced';
  CRON_PROCESS_SCORING_READS                           = 'public/cron/process-data-export-queue';
  DATA_EXPORT_SLOTS                                    = 'public/data-exporter/data-export-slots';
  DATA_EXPORTER_DATA_EXPORT                            = 'public/data-exporter/data-export';
  DATA_EXPORTER_PROCESS_EXPORT                         = 'public/cron/process-data-export-queue';
  DATA_EXPORTER_PROCESS_PSYCH_RUN                      = 'public/cron/process-psych-run-queue';
  DATA_EXPORTER_PSYCH_CONFIG_HISTORY                   = 'public/data-exporter/psych-config-history'
  DATA_EXPORTER_PSYCH_PIPELINE_CONFIGS                 = 'public/data-exporter/psych-pipeline-configs'
  DATA_EXPORTER_PSYCH_RUN                              = 'public/data-exporter/data-psych-run';
  DICTIONARY_SUGGESTIONS                               = 'public/test-ctrl/dictionary';
  DIST_ADMIN_CHECKLIST                                 = 'public/dist-admin/tech-readi/checklist';
  DIST_ADMIN_CONTACT                                   = 'public/dist-admin/contact';
  DIST_ADMIN_DIST                                      = 'public/dist-admin/district';
  DIST_ADMIN_LOCK_DOWN_INFO                            = 'public/dist-admin/tech-readi/lock-down-info';
  DIST_ADMIN_OSSLT_REPORT                              = 'public/dist-admin/osslt-report';
  DIST_ADMIN_PEN_LOOKUP                                = 'public/dist-admin/pen-lookup';
  DIST_ADMIN_ROLES                                     = 'public/dist-admin/roles';
  DIST_ADMIN_SCORE_ENTRY                               = 'public/dist-admin/score-entry';
  DIST_ADMIN_SESSIONS                                  = 'public/dist-admin/sessions';
  DIST_ADMIN_SIGNED_URL                                = 'public/dist-admin/signed-url';
  DIST_ADMIN_SUMMARY                                   = 'public/dist-admin/summary';
  EDUCATOR_CHECKLIST                                   = 'public/educator/checklist';
  EDUCATOR_CLASS_ASSESSMENTS                           = 'public/educator/class-assessments';
  EDUCATOR_CLASS_SCAN_INFO                             = 'public/educator/class-scan-info';
  EDUCATOR_CLASSROOM                                   = 'public/educator/classroom';
  EDUCATOR_GEN_RESPONSE_SHEETS                         = 'public/educator/gen-response-sheets';
  EDUCATOR_GUEST_STUDENTS                              = 'public/educator/guest-students';
  EDUCATOR_INVIGILATORS                                = 'public/educator/invigilators';
  EDUCATOR_REPORT_ISSUES_CATEGORIES                    = 'public/educator/report-issues-categories';
  EDUCATOR_REPORT_RESULTS                              = 'public/educator/report-results'
  EDUCATOR_RESP_SHEET_CONFIRMATION                     = 'public/educator/response-sheet-confirmation';
  EDUCATOR_RESP_SHEET_UPLOAD_QR_CONTENTS               = 'public/educator/resp-sheet-upload/qrcode-contents';
  EDUCATOR_RESP_SHEET_UPLOAD_RES_SHEETS                = 'public/educator/resp-sheet-upload/upload-response-sheets';
  EDUCATOR_RESPONSIBILITY_AGREEMENTS                   = 'public/educator/responsibility-agreement';
  EDUCATOR_SCHOOL                                      = 'public/educator/school';
  EDUCATOR_SCHOOL_SEMESTER_TW_DATES                    = 'public/educator/school-semester-tw-dates';
  EDUCATOR_SESSION                                     = 'public/educator/session';
  EDUCATOR_SESSION_SUB                                 = 'public/educator/session-sub';
  EDUCATOR_SOFT_LOCK                                   = 'public/educator/soft-lock';
  EDUCATOR_STUDENT_TEST_ATTEMPT                        = 'public/educator/student-test-attempt';
  EDUCATOR_STUDENTS                                    = 'public/educator/students';
  EDUCATOR_SUBSESSION_PRESETS                          = 'public/educator/subsession-presets';
  EDUCATOR_TEST_ATTEMPT_UNSUBMISSION_REASONS           = 'public/educator/test-attempt-unsubmission-reasons';
  EDUCATOR_UPDATE_TEST_FORM                            = 'public/educator/update-test-form';
  EDUCATOR_USER_METAS                                  = 'public/educator/user-metas';
  EDUCATOR_WEBSOCKET_CONNECTED_USERS                   = 'public/educator/websocket/connected-users';
  EDUCATOR_WEBSOCKET_STUDENT_SOFTLOCK                  = 'public/educator/websocket/student-softlock';
  EDUCATOR_WEBSOCKET_STUDENT_SUBSESSION_NOTIFICATION   = 'public/educator/websocket/student-subsession-notification';
  FIELD_TESTER_SESSION                                 = 'public/field-tester/session';
  FIELD_TESTER_SESSION_QUESTION                        = 'public/field-tester/session-question';
  KNOWLEDGE_BASE                                       = 'public/landing/knowledge-base';
  TEST_CTRL_KNOWLEDGE_BASE                                       = 'public/test-ctrl/knowledge-base';
  LANDING_APPLICANT_REGISTRATION                       = 'public/landing/applicant-registration';
  LANDING_CONFIRM_USER_ROLE                            = 'public/landing/confirm-user-role';
  LOG                                                  = 'public/log';
  MINISTRY_ADMIN_ACCOUNTS                              = 'public/bc-admin-coordinator/accounts';
  MINISTRY_ADMIN_ACTIVITY_LOGS                         = 'public/bc-admin-coordinator/activity-logs';
  MINISTRY_ADMIN_ASSESSMENT_COMPONENTS                 = 'public/bc-admin-coordinator/assessment-component';
  MINISTRY_ADMIN_BCG_STUDENTS_REGISTER                 = 'public/bc-admin-coordinator/bcgrad-students/register';
  MINISTRY_ADMIN_BCGRAD_SR_RESULT                      = 'public/bc-admin-coordinator/sr-results';
  MINISTRY_ADMIN_BCGRAD_STUDENTS                       = 'public/bc-admin-coordinator/bcgrad-students/students';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_ASSESSMENTS           = 'public/bc-admin-coordinator/bcgrad-students/assessment';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_REQUESTS              = 'public/bc-admin-coordinator/bcgrad-students/requests';
  MINISTRY_ADMIN_BCGRAD_STUDENTS_TEST_SESSIONS         = 'public/bc-admin-coordinator/bcgrad-students/test-sessions';
  MINISTRY_ADMIN_CONFIRMATION_CODES                    = 'public/bc-admin-coordinator/confirmation-codes';
  MINISTRY_ADMIN_DISTRICTS                             = 'public/bc-admin-coordinator/districts';
  MINISTRY_ADMIN_EDFMC2                                = 'public/bc-admin-coordinator/edfmc-2';
  MINISTRY_ADMIN_EXEMPTION                             = 'public/bc-admin-coordinator/exemption';
  MINISTRY_ADMIN_IRT_READY                             = 'public/bc-admin-coordinator/irt-ready';
  MINISTRY_ADMIN_IRT_SCORE                             = 'public/bc-admin-coordinator/irt-score';
  MINISTRY_ADMIN_PRINT_ORDERS                          = 'public/bc-admin-coordinator/print-orders';
  MINISTRY_ADMIN_PRINT_PACKAGES                        = 'public/bc-admin-coordinator/print-packages';
  MINISTRY_ADMIN_REGISTRATION                          = 'public/bc-admin-coordinator/registration';
  MINISTRY_ADMIN_REPORTS                               = 'public/bc-admin-coordinator/reports';
  MINISTRY_ADMIN_SCHOOL_PARTICIPATION                  = 'public/bc-admin-coordinator/school-participation';
  MINISTRY_ADMIN_SCHOOLS                               = 'public/bc-admin-coordinator/schools';
  MINISTRY_ADMIN_SCORE_ENTRY_LOGS                      = 'public/bc-admin-coordinator/score-entry-logs';
  MINISTRY_ADMIN_SESSION_PASSWORDS                     = 'public/bc-admin-coordinator/session-passwords';
  MINISTRY_ADMIN_SIMULATIONS                           = 'public/bc-admin-coordinator/simulation';
  MINISTRY_ADMIN_SPECIAL_MATERIAL_REQUESTS             = 'public/bc-admin-coordinator/special-material-requests';
  MINISTRY_ADMIN_SUBMISSION_PROGRESS                   = 'public/bc-admin-coordinator/submission-progress';
  MINISTRY_ADMIN_TEST_ATTEMPT_METAS                    = 'public/bc-admin-coordinator/test-attempt-metas';
  MINISTRY_ADMIN_TEST_WINDOW                           = 'public/bc-admin-coordinator/test-window';
  MINISTRY_ADMIN_UNSUBMISSIONS                         = 'public/bc-admin-coordinator/unsubmissions';
  MRKG_CAND_CERT_TEST                                  = 'public/mrkg-cand/cert-test';                           // get or submit required cert test
  MRKG_CAND_PROFILE                                    = 'public/mrkg-cand/profile';                             // get user or set profile
  MRKG_CTRL_MARKING_WINDOW                             = 'public/mrkg-ctrl/marking-window';                      // get all or 1 marking windows or create new one
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_CERT_MRKRS         = 'public/mrkg-ctrl/marking-window/accounts/cert-mrkrs';  // update or get all markers who are certified for the window
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_MRKRS              = 'public/mrkg-ctrl/marking-window/accounts/mrkrs';       // update or get marking window markers
  MRKG_CTRL_MARKING_WINDOW_ACCOUNTS_SUPRS              = 'public/mrkg-ctrl/marking-window/accounts/suprs';       // update or get marking window supervisors
  MRKG_CTRL_MARKING_WINDOW_CERT_TESTS                  = 'public/mrkg-ctrl/marking-window/cert-tests';           // update or get all cert tests for the marking window
  MRKG_CTRL_MARKING_WINDOW_DATE_FROM                   = 'public/mrkg-ctrl/marking-window/date-from';            // update marking window start date
  MRKG_CTRL_MARKING_WINDOW_DATE_TO                     = 'public/mrkg-ctrl/marking-window/date-to';              // update marking window end date
  MRKG_CTRL_MARKING_WINDOW_NAME                        = 'public/mrkg-ctrl/marking-window/name';                 // update marking window name
  MRKG_CTRL_MARKING_WINDOW_STATUS                      = 'public/mrkg-ctrl/marking-window/status';               // get or some marking windows
  MRKG_CTRL_MARKING_WINDOW_UNIQ_ITEMS                  = 'public/mrkg-ctrl/marking-window/unique-items';         // get 1/all unique items OR update 1 (q-specific-marking)
  MRKG_CTRL_MRKRS                                      = 'public/mrkg-ctrl/mrkrs';                               // get all markers
  MRKG_CTRL_PROFILE                                    = 'public/mrkg-ctrl/profile';                             // get user profile
  MRKG_CTRL_SUPRS                                      = 'public/mrkg-ctrl/suprs';                               // get all supervisors
  MRKG_LEAD_STAGE                                      = 'public/mrkg-lead/stage';
  MRKG_MRKR_CERT_TEST                                  = 'public/mrkg-mrkr/cert-test';                           // get or submit required cert test
  MRKG_MRKR_PROFILE                                    = 'public/mrkg-mrkr/profile';                             // get user profile
  MRKG_MRKR_Q_SCORING                                  = 'public/mrkg-mrkr/question-marking';                    // get or update 1 question
  MRKG_MRKR_Q_SCORING_BATCH                            = 'public/mrkg-mrkr/question-marking-batch';              // get or update multiple questions
  MRKG_MRKR_QUESTIONS                                  = 'public/mrkg-mrkr/assigned-qs';                         // get assigned questions
  MRKG_SUPR_ACCOUNTS_MRKRS                             = 'public/mrkg-supr/accounts/mrkrs';                      // get all their markers
  MRKG_SUPR_MARKING_LOG                                = 'public/mrkg-supr/marking-log';                         // get the marking log
  MRKG_SUPR_MODE                                       = 'public/mrkg-supr/mode';                                // get or update assignment mode
  MRKG_SUPR_MRKR_RE_CERT                               = 'public/mrkg-supr/mrkr/re-cert';                        // require re-certification for 1 marker
  MRKG_SUPR_MRKR_STATUS                                = 'public/mrkg-supr/mrkr/status';                         // update status (pause, resume) for 1 marker
  MRKG_SUPR_PERF                                       = 'public/mrkg-supr/mrkr-perf';                           // get 1 marker's performance
  MRKG_SUPR_PRIORITY                                   = 'public/mrkg-supr/priority';                            // get or update item priority
  MRKG_SUPR_PROFILE                                    = 'public/mrkg-supr/profile'                              // get user profile
  MRKG_SUPR_Q_SCORING                                  = 'public/mrkg-supr/question-marking';                    // get or update 1 question
  MRKG_SUPR_Q_SCORING_BATCH                            = 'public/mrkg-supr/question-marking-batch';              // get or update multiple questions
  MRKG_SUPR_SCHEDULE                                   = 'public/mrkg-supr/schedule';                            // get or update scheduling
  MRKG_UPLD_PROFILE                                    = 'public/mrkg-upld/profile';                             // get user profile
  MRKG_UPLD_RESP                                       = 'public/mrkg-upld/response';                            // upload new response, update existing one, or get all/1
  MRKG_UPLD_RESP_DOC                                   = 'public/mrkg-upld/response/doc';                        // update response document
  MRKG_UPLD_RESP_TEST_FORM                             = 'public/mrkg-upld/response/test-form';                  // update response test form
  MRKG_UPLD_RESP_TEST_TAKER                            = 'public/mrkg-upld/response/test-taker';                 // update response test taker id
  MRKG_UPLD_RESP_TEST_WINDOW                           = 'public/mrkg-upld/response/test-window';                // update response test window
  PASI_STATUS                                          = 'public/educator/pasi-statuses';
  PAYMENT_CTRL_ALTERNATIVE_PAYMENTS                    = 'public/payment-ctrl/alternative-payments';
  PAYMENT_CTRL_REFUND_SETUP                            = 'public/payment-ctrl/refund-setup';
  PAYMENT_CTRL_SCHOOL_ADMIN_AGREEMENTS                 = 'public/payment-ctrl/school-admin-agreements';
  PAYMENT_CTRL_STRIPE_PAYMENTS                         = 'public/payment-ctrl/stripe-payments';
  PAYMENT_CTRL_STUDENTS_ATTEMPTS                       = 'public/payment-ctrl/student-attempts';
  SCHOOL_ADMIN_BCG_STUDENTS                            = 'public/school-admin/bcgrad-students/students';
  SCHOOL_ADMIN_BCG_STUDENTS_ENROLLMENT                 = 'public/school-admin/bcgrad-students/enrollment';
  SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP                     = 'public/school-admin/bcgrad-students/lookup';
  SCHOOL_ADMIN_BCG_STUDENTS_LOOKUP2                    = 'public/school-admin/bcgrad-students/lookup-2';
  SCHOOL_ADMIN_BCG_STUDENTS_REGISTER                   = 'public/school-admin/bcgrad-students/register';
  SCHOOL_ADMIN_BCG_STUDENTS_REGISTRATION               = 'public/school-admin/bcgrad-students/registration';
  SCHOOL_ADMIN_BCG_STUDENTS_REQUESTS                   = 'public/school-admin/bcgrad-students/requests';
  SCHOOL_ADMIN_CHECKLIST                               = 'public/school-admin/tech-readi/checklist';
  SCHOOL_ADMIN_CLASSES                                 = 'public/school-admin/classes';
  SCHOOL_ADMIN_CONTACT                                 = 'public/school-admin/tech-readi/it-contact';
  SCHOOL_ADMIN_INVIGILATE_CLASS                        = 'public/school-admin/invigilate-class';
  SCHOOL_ADMIN_INVIGILATORS                            = 'public/school-admin/invigilators';
  SCHOOL_ADMIN_INVITE                                  = 'public/school-admin/invite';
  SCHOOL_ADMIN_IP                                      = 'public/school-admin/tech-readi/ip';
  SCHOOL_ADMIN_LOCK_DOWN_INFO                          = 'public/school-admin/tech-readi/lock-down-info';
  SCHOOL_ADMIN_OEN_SCHOOL                              = 'public/school-admin/oen-school';
  SCHOOL_ADMIN_PAYMENT                                 = 'public/school-admin/payment';
  SCHOOL_ADMIN_PAYMENT_AGREEMENT                       = 'public/school-admin/payment-agreements';
  SCHOOL_ADMIN_PJ_REPORTS                              = 'public/school-admin/pj-reports';
  SCHOOL_ADMIN_PURCHASES                               = 'public/school-admin/purchases'
  SCHOOL_ADMIN_REPORTS                                 = 'public/school-admin/reports';
  SCHOOL_ADMIN_RESPONSIBILITY_AGREEMENTS               = 'public/school-admin/responsibility-agreement';
  SCHOOL_ADMIN_ROLES                                   = 'public/school-admin/roles';
  SCHOOL_ADMIN_SCHOOL                                  = 'public/school-admin/school';
  SCHOOL_ADMIN_SCHOOL_ACCESS                           = 'public/school-admin/school-access';
  SCHOOL_ADMIN_SCHOOL_PROFILE                          = 'public/school-admin/school-profile';
  SCHOOL_ADMIN_SCORE_PROFILE                           = 'public/school-admin/score-profile';
  SCHOOL_ADMIN_SESSION                                 = 'public/school-admin/session';
  SCHOOL_ADMIN_SESSION_COMPLETE                        = 'public/school-admin/session-complete';
  SCHOOL_ADMIN_SESSION_PURCHASE                        = 'public/school-admin/session-purchase';
  SCHOOL_ADMIN_STUDENT                                 = 'public/school-admin/student';
  SCHOOL_ADMIN_STUDENT_ASMT_INFO_SIGNOFF               = 'public/school-admin/student-asmt-info-signoff';
  SCHOOL_ADMIN_STUDENT_ASSIGN                          = 'public/school-admin/student/assign';
  SCHOOL_ADMIN_STUDENT_CREATION_FORM                   = 'public/school-admin/student-creation-form';
  SCHOOL_ADMIN_STUDENT_DELETE                          = 'public/school-admin/student-delete';
  SCHOOL_ADMIN_TEACHERS                                = 'public/school-admin/teachers';
  SCHOOL_ADMIN_TEACHERS_INVITE                         = 'public/school-admin/teachers/invite';
  SCHOOL_ADMIN_TEST_WINDOW                             = 'public/school-admin/test-window';
  SCOR_LEAD_DUPLICATE_READ                             = 'public/scor-lead/duplicate-read';
  SCOR_LEAD_FORCE_MARKER_CLAIM                         = 'public/scor-lead/force-marker-claim';
  SCOR_LEAD_PROCESS_SCORING_SECOND_READS               = 'public/scor-lead/process-scoring-second-reads';
  SCOR_LEAD_PROCESS_SCORING_THIRD_READS                = 'public/scor-lead/process-scoring-third-reads';
  SCOR_LEAD_RESPONSE_REPOOLING                         = 'public/scor-lead/response-repooling';
  SCOR_LEAD_SCORE_OPTIONS                              = 'public/scor-lead/score-options';
  SCOR_LEAD_SCORE_PROFILES                             = 'public/scor-lead/score-profiles';
  SCOR_SCOR_BATCH_ALLOC_POLICIES                       = 'public/scor-lead/batch-alloc-policies'
  SCOR_SCOR_BATCH_GROUPS                               = 'public/scor-scor/batches/batch-groups';
  SCOR_SCOR_MARKING_CLAIMED_BATCH_RESPONSES            = 'public/scor-scor/batches/marking-claimed-batch-responses'
  SCOR_SCOR_SCORING_READS                              = 'public/scor-scor/batches/scoring-reads';
  SCOR_SCOR_SUMMARY                                    = 'public/scor-scor/summary';
  SESSION_QUESTIONNAIRE                                = 'public/educator/session-questionnaire';
  SIMULATE_EXTRACT_RESPONSE                            = 'public/student/extract-item-response';
  STRIPE_WEBHOOKS                                      = 'public/stripe-webhooks'
  STUDENT_ACCOMMODATIONS                               = 'public/educator/student-accommodations';
  STUDENT_ACTIVE_SUBSESSION_STATUS                     = 'public/student/active-subsession-status'
  STUDENT_ASMT_FILTER                                  = 'public/student/asmt-filter';
  STUDENT_ATTEMPT                                      = 'public/student/attempt';
  STUDENT_CHECKLIST                                    = 'public/student/checklist';
  STUDENT_CLASS_ID                                     = 'public/student/class-id';
  STUDENT_CREATE_CONNECTION                            = 'public/student/student-create-connection'
  STUDENT_CURRENT_SUBSESSION_INFO                      = 'public/student/current-subsession-info'
  STUDENT_MAP                                          = 'public/student/map';
  STUDENT_SESSION                                      = 'public/student/session';
  STUDENT_SESSION_QUESTION                             = 'public/student/session-question';
  STUDENT_SOFT_LOCK                                    = 'public/student/soft-lock';
  STUDENT_STAGE_SUBMISSION                             = 'public/student/stage-submission';
  STUDENT_TEST_WINDOW                                  = 'public/student/test-window';
  SUPPORT_INVITATION_CODE                              = 'public/support/u-invitation-code';
  SUPPORT_MAIL_CAMPAIGN                                = 'public/support/mail-campaign';
  SUPPORT_MAIL_CAMPAIGN_BATCH                          = 'public/support/mail-campaign-batch';
  SUPPORT_MAIL_CAMPAIGN_TEST                           = 'public/support/mail-campaign-test';
  SUPPORT_OVERRIDE_START_SESSION                       = 'public/support/test-session/date';
  SUPPORT_PASSWORD_ATTEMPT_RESET                       = 'public/support/password-attempt-reset';
  SUPPORT_PRIVATE_G9_ACCESS                            = 'public/support/private-g9-access';
  SUPPORT_RESET_NAMES                                  = 'public/support/reset-names';
  SUPPORT_RESET_PROGRESS                               = 'public/support/reset-progress';
  SUPPORT_RESET_SECTION                                = 'public/support/reset-section';
  SUPPORT_SCORING_ROLES                                = 'public/support/scoring/roles';
  SUPPORT_SCORING_WINDOWS                              = 'public/support/scoring/windows';
  SUPPORT_SESSION_REOPEN                               = 'public/support/session-reopen';
  SUPPORT_SYS_FLAGS                                    = 'public/support/sys-flags';
  SUPPORT_TEST_ATTEMPT_RESET                           = 'public/support/test-attempt-reset';
  SUPPORT_TEST_FORM_PRINT                              = 'public/support/test-form-print';
  SUPPORT_TT_ATTEMPTS                                  = 'public/support/tt-attempts'
  SUPPORT_TT_BOOKING                                   = 'public/support/tt-booking';
  SUPPORT_TT_BOOKING_STATUS                            = 'public/support/tt-booking-status';
  SUPPORT_TT_LANG_REQ                                  = 'public/support/tt-lang-req';
  SUPPORT_TT_NOTIFICATIONS                             = 'public/support/tt-notifications';
  SUPPORT_TT_REPORT_NOTIFICATIONS                      = 'public/support/tt-report-notifications';
  SUPPORT_TT_UPCOMING_SESSIONS                         = 'public/support/tt-upcoming-sessions';
  SUPPORT_USERS                                        = 'public/support/users';
  SUPPORT_VALIDATE_OCT_MEMBER                          = 'public/support/validate-oct-member';
  SURVEY_SESSION                                       = 'public/educator/survey/session';
  SURVEY_SESSION_QUESTION                              = 'public/educator/survey/session-question';
  TEST_ADMIN_ACCOMM_PENDING_REQUESTS                   = 'public/test-admin/accomm/pending-requests';
  TEST_ADMIN_ACCOMM_REQUESTS_RESPONSES                 = 'public/test-admin/accomm/responses';
  TEST_ADMIN_ACCOMM_TRANSFER_REQ                       = 'public/test-admin/accomm/transfer-req';
  TEST_ADMIN_ACCOUNTS_ACCESS                           = 'public/test-admin/accounts/access';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_COORD                   = 'public/test-admin/accounts/assigned-coord';
  TEST_ADMIN_ACCOUNTS_ASSIGNED_SESSIONS                = 'public/test-admin/accounts/assigned-sessions';
  TEST_ADMIN_APPEALS                                   = 'public/test-admin/appeals/appeals';
  TEST_ADMIN_ENABLED_APPEALS                            = 'public/test-admin/appeals/enabled-appeals';
  TEST_ADMIN_INSTITUTION                               = 'public/test-admin/institution';
  TEST_ADMIN_JURISDICTION                              = 'public/test-admin/jurisdiction';
  TEST_ADMIN_INVIGILATION_                             = 'public/test-admin/invigilation/';
  TEST_ADMIN_INVIGILATION_ABSENCE_REPORT               = 'public/test-admin/invigilation/absence-report';
  TEST_ADMIN_INVIGILATION_CLOSE                        = 'public/test-admin/invigilation/close';
  TEST_ADMIN_INVIGILATION_GROUP_ISSUE                  = 'public/test-admin/invigilation/group-issue';
  TEST_ADMIN_INVIGILATION_GROUP_TIME_EXTEND            = 'public/test-admin/invigilation/group-time-extend';
  TEST_ADMIN_INVIGILATION_IDENTITY_NO                  = 'public/test-admin/invigilation/identity-no';
  TEST_ADMIN_INVIGILATION_IDENTITY_VERIF               = 'public/test-admin/invigilation/identity-verif';
  TEST_ADMIN_INVIGILATION_INDIV_ISSUE                  = 'public/test-admin/invigilation/indiv-issue';
  TEST_ADMIN_INVIGILATION_INDIV_TIME_EXTEND            = 'public/test-admin/invigilation/indiv-time-extend';
  TEST_ADMIN_INVIGILATION_ONBEHALF_QUESTION_RESPONSE   = 'public/test-admin/invigilation/onbehalf/question-response';
  TEST_ADMIN_INVIGILATION_ONBEHALF_TEST_ATTEMPT        = 'public/test-admin/invigilation/onbehalf/test-attempt';
  TEST_ADMIN_INVIGILATION_PAUSE                        = 'public/test-admin/invigilation/pause';
  TEST_ADMIN_INVIGILATION_REPORT_APPLICANT_ISSUE       = 'public/test-admin/invigilation/report-applicant-issue';
  TEST_ADMIN_INVIGILATION_SEND                         = 'public/test-admin/invigilation/send';
  TEST_ADMIN_INVIGILATION_TEMP_ACCESS                  = 'public/test-admin/invigilation/temp-access';
  TEST_ADMIN_SEB_DOWNLOAD_LINK                         = 'public/test-admin/seb-download-link';
  TEST_ADMIN_STUDENTS_BOOKING                          = 'public/test-admin/students/booking';
  TEST_ADMIN_STUDENTS_SCHOOL                           = 'public/test-admin/students/school';
  TEST_ADMIN_STUDENTS_WALK_IN                          = 'public/test-admin/students/walk-in';
  TEST_ADMIN_TEST_SESSION_ASSIGNED_INVIGILATOR         = 'public/test-admin/test-session/assigned-invigilator';
  TEST_ADMIN_TEST_SESSION_BOOKING_TRANSFER_REQ         = 'public/test-admin/test-session/booking-transfer-req';
  TEST_ADMIN_TEST_SESSION_BOOKINGS                     = 'public/test-admin/test-session/bookings';
  TEST_ADMIN_TEST_SESSION_BOOKINGS_INDIC               = 'public/test-admin/test-session/bookings-indic';
  TEST_ADMIN_TEST_SESSION_CANCELLATION                 = 'public/test-admin/test-session/cancellation';
  TEST_ADMIN_TEST_SESSION_CAPACITY                     = 'public/test-admin/test-session/capacity';
  TEST_ADMIN_TEST_SESSION_INVITATION_CODE              = 'public/test-admin/test-session/invitation-code';
  TEST_ADMIN_TEST_SESSION_RESTRICTIONS                 = 'public/test-admin/test-session/restrictions';
  TEST_ADMIN_TEST_SESSION_SETUP_ACCOMM_PING            = 'public/test-admin/test-session-setup/accomm-ping';
  TEST_ADMIN_TEST_SESSION_SETUP_ACTIVE                 = 'public/test-admin/test-session-setup/active';
  TEST_ADMIN_TEST_SESSION_SETUP_CHECKLIST              = 'public/test-admin/test-session-setup/checklist';
  TEST_ADMIN_TEST_SESSION_SETUP_LOCATIONS              = 'public/test-admin/test-session-setup/locations';
  TEST_ADMIN_TEST_SESSION_SETUP_SESSIONS               = 'public/test-admin/test-session-setup/sessions';
  TEST_ADMIN_TEST_SESSION_SETUP_TECH_READY             = 'public/test-admin/test-session-setup/tech-ready';
  TEST_ADMIN_TEST_SESSION_SETUP_TEST_WINDOWS           = 'public/test-admin/test-session-setup/test-windows';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOT_RESTRICTION  = 'public/test-admin/test-session-setup/time-slot-restriction';
  TEST_ADMIN_TEST_SESSION_SETUP_TIME_SLOTS             = 'public/test-admin/test-session-setup/time-slots';
  TEST_ADMIN_TEST_SESSION_SETUP_TIMEZONE               = 'public/test-admin/test-session-setup/timezone';
  TEST_ADMIN_TEST_SESSION_SOFTLOCK                     = 'public/test-admin/test-sessions/soft-lock';
  TEST_ADMIN_TEST_SESSION_STATUS_UPDATE                = 'public/test-admin/test-session/status-update';
  TEST_ADMIN_TEST_SESSION_VIDEOSTREAM_LINK             = 'public/test-admin/test-session/videostream-link'
  TEST_ADMIN_TEST_SESSION_WAIT_LISTINGS_INDIC          = 'public/test-admin/test-session/wait-listings-indic';
  TEST_ADMIN_TEST_SESSION_WAITLISTER_TRANSFER_REQ      = 'public/test-admin/test-session/waitlister-transfer-req';
  TEST_ADMIN_TEST_SESSION_WAITLISTERS                  = 'public/test-admin/test-session/waitlisters';
  TEST_ADMIN_TEST_SESSIONS_ALL                         = 'public/test-admin/test-sessions/all';
  TEST_ADMIN_TEST_SESSIONS_MY_SINGLE                   = 'public/test-admin/test-sessions/my-single';
  TEST_ADMIN_TEST_SESSIONS_MY_UPCOMING                 = 'public/test-admin/test-sessions/my-upcoming';
  TEST_ADMIN_TEST_SESSIONS_SINGLE                      = 'public/test-admin/test-sessions/single';
  TEST_ADMIN_TEST_SESSIONS_SUMMARY                     = 'public/test-admin/test-sessions/summary';
  TEST_ADMIN_VALIDATE_SEB                              = 'public/test-admin/validate-seb';
  TEST_AUTH_ACCOUNTS_ACCESS                            = 'public/test-auth/accounts/access';
  TEST_AUTH_ACCOUNTS_RESEND_INVITE                     = 'public/test-auth/accounts/resend-invite';
  TEST_AUTH_ASSET                                      = 'public/test-auth/assets';
  TEST_AUTH_ASSET_GROUPS                               = 'public/test-auth/asset-groups';
  TEST_AUTH_ASSET_LIBRARIES                            = 'public/test-auth/asset-libraries';
  TEST_AUTH_ASSET_LIBRARY_FIELDS                       = 'public/test-auth/asset-library-fields';
  TEST_AUTH_ASSET_VERSIONS                             = 'public/test-auth/asset-versions';
  TEST_AUTH_CONTENT_SEARCH                             = 'public/test-auth/content-search';
  TEST_AUTH_FRAMEWORKS                                 = 'public/test-auth/frameworks';
  TEST_AUTH_FRAMEWORKS_AUDIT                           = 'public/test-auth/frameworks-audit'
  TEST_AUTH_GROUP                                      = 'public/test-auth/group';
  TEST_AUTH_GROUP_MEMBERS                              = 'public/test-auth/group-members';
  TEST_AUTH_GROUP_ROLES                                = 'public/test-auth/group-roles';
  TEST_AUTH_GROUPS                                     = 'public/test-auth/groups';
  TEST_AUTH_HIGHLIGHT_NOTES                            = 'public/test-auth/highlight-notes';
  TEST_AUTH_INIT_WEBSOCKETS                            = 'public/test-auth/init-websockets';
  TEST_AUTH_ITEM_IMPRESSION                            = 'public/test-auth/item-impressions';
  TEST_AUTH_ITEM_SET                                   = 'public/test-auth/item-set';
  TEST_AUTH_ITEM_SET_AUDITS                            = 'public/test-auth/item-set-audits'
  TEST_AUTH_ITEM_TAG_LINK                              = 'public/test-auth/item-tag-link';
  TEST_AUTH_ITEM_TAGS                                  = 'public/test-auth/item-tags';
  TEST_AUTH_MANAGE_AUTHOR                              = 'public/test-auth/manage-author';
  TEST_AUTH_NOTE_FILES                                 = 'public/test-auth/note-files';
  TEST_AUTH_NOTES                                      = 'public/test-auth/notes';
  TEST_AUTH_NOTES_AUDIT                                = 'public/test-auth/notes/audit';
  TEST_AUTH_NOTIFICATIONS                              = 'public/test-auth/notifications';
  TEST_AUTH_PUB_PWD_PROTECTED                          = 'public/test-auth/public-pwd-protected';
  TEST_AUTH_QUESTION_CHANGE_LOG                      = 'public/test-auth/question-change-log';
  TEST_AUTH_QUESTION_GRAPHIC_REQUESTS                  = 'public/test-auth/question-graphic-requests';
  TEST_AUTH_QUESTION_REVISIONS                         = 'public/test-auth/question-revisions';
  TEST_AUTH_QUESTION_SET_LISTS                         = 'public/test-auth/question-set-lists';
  TEST_AUTH_QUESTION_WORKFLOW_STAGES                   = 'public/test-auth/question-workflow-stages';
  TEST_AUTH_QUESTIONS                                  = 'public/test-auth/questions';
  TEST_AUTH_SHOW_COMMENTS                              = 'public/test-auth/show-comments';
  TEST_AUTH_SINGLE_GROUP                               = 'public/test-auth/single-group';
  TEST_AUTH_STYLE_PROFILES                             = 'public/test-auth/style-profiles';
  TEST_AUTH_SUGGESTIONS                                = 'public/test-auth/suggestions';
  TEST_AUTH_TEST_DESIGN_FORMS                          = 'public/test-auth/test-designs-forms';
  TEST_AUTH_TEST_DESIGNS                               = 'public/test-auth/test-designs';
  TEST_AUTH_TEST_DESIGNS_IS_PUBLIC                     = 'public/test-auth/test-designs/is-public';
  TEST_AUTH_TEST_QUESTION_SCORING_CODES                = 'public/test-auth/test-question-scoring-codes'
  TEST_AUTH_TEST_QUESTION_SCORING_INFO                 = 'public/test-auth/test-question-scoring-info'
  TEST_AUTH_TEXT_VOICE                                 = 'public/test-auth/text-voice';
  TEST_CERT_DATA_FILE                                  = 'public/test-cert/data-file';
  TEST_CERT_ROLES                                      = 'public/test-cert/roles';
  TEST_COVER_PAGE_INFO                                 = 'public/student/asmt-cover-page'
  TEST_CTRL_ACCOUNTS_INSTITUTIONS                      = 'public/test-ctrl/accounts/institutions';
  TEST_CTRL_ACCOUNTS_SCHOOL_ADMINS                     = 'public/test-ctrl/accounts/school-admins';
  TEST_CTRL_ACCOUNTS_TEST_ADMIN_REVOKE                 = 'public/test-ctrl/accounts/test-admin-revoke';
  TEST_CTRL_ACCOUNTS_TEST_ADMINS                       = 'public/test-ctrl/accounts/test-admins';
  TEST_CTRL_ACCOUNTS_TEST_CERT_REVOKE                  = 'public/test-ctrl/accounts/test-cert-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CERTS                        = 'public/test-ctrl/accounts/test-certs';
  TEST_CTRL_ACCOUNTS_TEST_CTRL_REVOKE                  = 'public/test-ctrl/accounts/test-ctrl-revoke';
  TEST_CTRL_ACCOUNTS_TEST_CTRLS                        = 'public/test-ctrl/accounts/test-ctrls';
  TEST_CTRL_APPEALS                                    = 'public/test-ctrl/appeals';
  TEST_CTRL_BOARDS                                     = 'public/test-ctrl/schools/boards';
  TEST_CTRL_CLASSES                                    = 'public/test-ctrl/schools/classes';
  TEST_CTRL_DATA_EXPORTS                               = 'public/test-ctrl-data-export/exports';
  TEST_CTRL_DATA_FILE                                  = 'public/test-ctrl/data-file';
  TEST_CTRL_ISSUE_REVW_SESSION_QUESTION_LOGS           = 'public/test-ctrl-issue-revw/session-question-logs';
  TEST_CTRL_LANG_REQ                                   = 'public/test-ctrl/lang-req';
  TEST_CTRL_ROLES                                      = 'public/test-ctrl/roles';
  TEST_CTRL_SCHOOLS                                    = 'public/test-ctrl/schools/school';
  TEST_CTRL_STUDENTS                                   = 'public/test-ctrl/schools/students';
  TEST_CTRL_SUMMARY                                    = 'public/test-ctrl/summary';
  TEST_CTRL_SYNC_SCORING_AUTHORING                     = 'public/test-ctrl/sync-scoring-authoring';
  TEST_CTRL_TEACHERS                                   = 'public/test-ctrl/schools/teachers';
  TEST_CTRL_TECHNICAL_READINESS_FORM_DOWNLOAD          = 'public/test-ctrl/technical-readiness-form-download';
  TEST_CTRL_TECHNICAL_READINESS_VERIFICATION           = 'public/test-ctrl/technical-readiness-tracking';
  TEST_CTRL_TECHNICAL_READINESS_VERIFY_FORM            = 'public/test-ctrl/technical-readiness-verify-form';
  TEST_CTRL_TEST_WINDOW_ASSESSMENT_PRICE_CONFIG        = 'public/test-ctrl/test-window/assessment-price-config'
  TEST_CTRL_TEST_WINDOW_CONFIG                         = 'public/test-ctrl/test-window/config';
  TEST_CTRL_TEST_WINDOW_PUBLISH                        = 'public/test-ctrl/test-window/publish';
  TEST_CTRL_TEST_WINDOW_RELEASE_NOTES                  = 'public/test-ctrl/test-window/release-notes';
  TEST_CTRL_TEST_WINDOW_RESPONSE_SETS                  = 'public/test-ctrl/test-window/response-sets';
  TEST_CTRL_TEST_WINDOW_RETRIEVALS                     = 'public/test-ctrl/test-window/retrievals';
  TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_ITEMS           = 'public/test-ctrl/test-window/scoring-window-items';
  TEST_CTRL_TEST_WINDOW_SCORING_WINDOW_SETUP           = 'public/test-ctrl/test-window/scoring-window-setup';
  TEST_CTRL_TEST_WINDOW_SCORING_WINDOWS                = 'public/test-ctrl/test-window/scoring-windows';
  TEST_CTRL_TEST_WINDOW_SUMMARY                        = 'public/test-ctrl/test-window/summary';
  TEST_CTRL_TEST_WINDOW_TEST_DESIGN                    = 'public/test-ctrl/test-window/test-design';
  TEST_CTRL_TEST_WINDOW_TEST_SESSIONS                  = 'public/test-ctrl/test-window/test-sessions';
  TEST_CTRL_VALIDATION_DATES                           = 'public/test-ctrl/validation/dates';
  TEST_CTRL_VALIDATION_ISSUE                           = 'public/test-ctrl/validation/issue';
  TEST_CTRL_VALIDATION_ISSUE_RESOLUTION                = 'public/test-ctrl/validation/issue-resolution';
  TEST_CTRL_VALIDATION_READY                           = 'public/test-ctrl/validation/ready';
  TEST_CTRL_VALIDATION_TEST_ATTEMPTS                   = 'public/test-ctrl/validation/test-attempts';
  TEST_CTRL_VALIDATION_TEST_FORM                       = 'public/test-ctrl/validation/test-form';
  TEST_CTRL_VALIDATION_TEST_SESSIONS                   = 'public/test-ctrl/validation/test-sessions';
  TEST_DESIGN_QUESTION_VERSIONS                        = 'public/test-auth/test-design-question-versions';
  TEST_TAKER_ACCOMMODATIONS_PENDING_REQ                = 'public/test-taker/accommodations/pending-req';
  TEST_TAKER_ALLOW_APPEALS                             = 'public/test-taker/allow-appeals';
  TEST_TAKER_APPEAL_INIT                               = 'public/test-taker/appeal-init';
  TEST_TAKER_APPEALS                                   = 'public/test-taker/appeals';
  TEST_TAKER_CREDIT_DETAILS                            = 'public/test-taker/credit-details';
  TEST_TAKER_DATA_DOWNLOAD                             = 'public/test-taker/data/download';
  TEST_TAKER_INVIGILATION_QUESTION_CONTENT             = 'public/test-taker/invigilation/question-content';
  TEST_TAKER_INVIGILATION_QUESTION_RESPONSE            = 'public/test-taker/invigilation/question-response';
  TEST_TAKER_INVIGILATION_REPORT_ISSUE                 = 'public/test-taker/invigilation/report-issue';
  TEST_TAKER_INVIGILATION_SCREEN_SESSION               = 'public/test-taker/invigilation/screen-session';
  TEST_TAKER_INVIGILATION_SECTION_SUBMIT               = 'public/test-taker/invigilation/section-submit';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT                 = 'public/test-taker/invigilation/test-attempt';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_ATTEST          = 'public/test-taker/invigilation/test-attempt-attest';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_SECTION_DATA    = 'public/test-taker/invigilation/test-attempt-section-data';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_LANG            = 'public/test-taker/invigilation/test-attempt-lang';
  TEST_TAKER_INVIGILATION_TEST_ATTEMPT_TIME            = 'public/test-taker/invigilation/test-attempt-time';
  TEST_TAKER_INVIGILATION_TEST_FORM                    = 'public/test-taker/invigilation/test-form';
  TEST_TAKER_REPORT_RESULTS                            = 'public/test-taker/report/results';
  TEST_TAKER_SEB_DOWNLOAD_LINK                         = 'public/test-taker/seb-download-link';
  TEST_CERT_RECORD_MGNT                                = 'public/jurisdiction/records-management';
  TEST_CERT_RECORD_MGNT_ASSIGN_CREDITS                 = 'public/jurisdiction/assign-credits';
  TEST_CERT_RECORD_MGNT_UNCLOSED_SESSIONS              = 'public/jurisdiction/records-management/unclosed-sessions';
  TEST_CERT_RECORD_MGNT_GEN_DOC                        = 'public/jurisdiction/records-management/pdf-generator';
  TEST_CERT_SUMMARY                                    = 'public/jurisdiction/summary';
  TEST_CERT_CREATE_CANDIDATE                           = 'public/jurisdiction/no-email-candidate';
  TEST_CERT_JURISDICTION_SETTINGS                      = 'public/jurisdiction/registration-settings';
  DYNAMIC_TIMEZONE                                     = 'public/jurisdiction/timezone';
  JURISDICTION_ACCOUNTS_MANAGERS                       = 'public/jurisdiction/accounts/managers';
  JURISDICTION_ACCOUNTS_INSTITUTIONS                   = 'public/jurisdiction/accounts/institutions';
  JURISDICTION_ACCOUNTS_TEST_ADMIN_REVOKE              = 'public/jurisdiction/accounts/test-admin-revoke';
  JURISDICTION_ACCOUNTS_TEST_ADMINS                    = 'public/jurisdiction/accounts/test-admins';
  JURISDICTION_CAND_REPORTS                            = 'public/jurisdiction/candidate-reports';
  LANDING_JURISDICTION_SETTINGS                        = 'public/landing/registration-settings';
  TEST_TAKER_SESSION_ID                                = 'public/test-taker/session-id';
  TEST_TAKER_TEST_SESSIONS_ALL                         = 'public/test-taker/test-sessions/all';
  TEST_TAKER_TEST_SESSIONS_BOOKING                     = 'public/test-taker/test-sessions/booking';
  TEST_TAKER_TEST_SESSIONS_INVITATION                  = 'public/test-taker/test-sessions/invitation';
  TEST_TAKER_TEST_SESSIONS_OCT_VALIDATION              = 'public/test-taker/test-sessions/oct-validation';
  TEST_TAKER_TEST_SESSIONS_PAST_ATTEMPTS               = 'public/test-taker/test-sessions/past-attempts';
  TEST_TAKER_TEST_SESSIONS_PENDING                     = 'public/test-taker/test-sessions/pending';
  TEST_TAKER_TEST_SESSIONS_STRIPE_CHECKOUT             = 'public/test-taker/test-sessions/stripe-checkout';
  TEST_TAKER_TEST_SESSIONS_WAITLIST                    = 'public/test-taker/test-sessions/waitlist';
  TRANSLATION                                          = 'public/translation';
  VERIFY_STUDENT                                       = 'public/student/verify-student';
  ZOOM_MEETING               = 'public/zoom/meetings';
  ZOOM_ACCESS_TOKEN          = 'public/zoom/access-token'
}