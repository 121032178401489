import { getYearMonthMaxDate } from './../../core/util/date';
import { rangeToArr } from './../../core/util/generate';
import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { DROPDOWN_MONTHS, MONTH_LABELS, MONTH_MATCH_FUZZ, RENDER_MONTH_LABEL_BY_CODE, numMatchFuzz } from './model/months';
import { LangService } from 'src/app/core/lang.service';

enum EInput {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  DAY = 'DAY',
}

interface IInputConfig {
  eId: EInput,
  label: string,
  render: (str:string) => string,

}

interface IIdMapList {
  [code: string]: string[]
}

interface IIdMapVal {
  [code: string]: string
}

const defaultRender = (str:string) => str;

@Component({
  selector: 'dob-input',
  templateUrl: './dob-input.component.html',
  styleUrls: ['./dob-input.component.scss']
})
export class DobInputComponent implements OnInit {

  @Input() formControlModel: FormControl;
  @Input() isDateHidden: boolean;
  @Output() itemSelected = new EventEmitter();
  @Output() validationMessageChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public lang: LangService,
    private whitelabelService: WhitelabelService,
  ) { }

  @ViewChildren('autocomplete') autocompletes: QueryList<MatAutocomplete>;

  eInput = EInput

  lists:IIdMapList = {};
  filteredLists:IIdMapList = {};
  selection:IIdMapVal = {};

  inputsRef = {
    [EInput.MONTH]: {
      eId: EInput.MONTH,
      label: 'lbl_dob_month',
      render: (monthCode:string) => RENDER_MONTH_LABEL_BY_CODE(monthCode, this.lang.c()),
      matchFuzz: () => MONTH_MATCH_FUZZ(this.selection[EInput.MONTH], this.lang.c())
    },
    [EInput.DAY]: {
      eId: EInput.DAY,
      label: 'lbl_dob_day',
      render: defaultRender,
      matchFuzz: () => numMatchFuzz(this.selection[EInput.DAY], this.lists[EInput.DAY]),
    },
    [EInput.YEAR]: {
      eId: EInput.YEAR,
      label: 'lbl_dob_year',
      render: defaultRender,
      matchFuzz: () => numMatchFuzz(this.selection[EInput.DAY], this.lists[EInput.YEAR]),
    }
  }
  inputs:IInputConfig[] = []

  ngOnInit(): void {
    this.initOrderByLang()
    this.initLists()
    this.ingestFc();
  }

  initOrderByLang(){
    // note: component is not currently configured to change lang on the fly
    if (this.lang.c() == 'fr'){
      // french order
      this.inputs = [
        this.inputsRef[EInput.DAY],
        this.inputsRef[EInput.MONTH],
        this.inputsRef[EInput.YEAR],
      ]
    }
    else{
      // english order
      this.inputs = [
        this.inputsRef[EInput.MONTH],
        this.inputsRef[EInput.DAY],
        this.inputsRef[EInput.YEAR],
      ]
    }
  }
  
  initLists(){
    this.initMonths();
    this.initYears();
    this.adjustDays();
    for (let input of this.inputs){
      this.filteredLists[input.eId] = this.lists[input.eId].concat()
    }
  }

  private initMonths(){
    this.lists[EInput.MONTH] = DROPDOWN_MONTHS
  }

  private initYears(){
    const currentDate = new Date(); 
    const yearTo = +currentDate.getFullYear();
    const yearFrom = +(this.whitelabelService.getSiteText('DOB_YEAR_MIN') || 1900);
    this.lists[EInput.YEAR] = rangeToArr(yearFrom, yearTo, false).map(n => String(n));
  }

  public adjustDays(){
    const dateFrom = 1;
    let dateTo = 31;
    const sel = this.selection
    // console.log('adjustDays', !!sel[EInput.MONTH], +sel[EInput.YEAR], +sel[EInput.MONTH])
    if (sel[EInput.MONTH] && sel[EInput.YEAR]){
      dateTo = getYearMonthMaxDate(+sel[EInput.YEAR], +sel[EInput.MONTH])
    }
    this.lists[EInput.DAY] = rangeToArr(dateFrom, dateTo, true).map(n => String(n));
  }

  subFc(){
    this.formControlModel.valueChanges
  }

  ingestFc(){
    const YYYMMDD = (this.formControlModel.value || '')
    if (YYYMMDD){
      const YYYMMDD_arr = YYYMMDD.split('-');
      this.selection[EInput.YEAR] = YYYMMDD_arr[0]
      this.selection[EInput.MONTH] = YYYMMDD_arr[1]
      this.selection[EInput.DAY] = String(+YYYMMDD_arr[2])
    }
    for (let input of this.inputs){
      this.validateInputs(input.eId)
    }
  }
  
  onChange(){
    let isAllDefined = true;
    const sel = this.selection
    for (let input of this.inputs){
      if (!sel[input.eId]){
        isAllDefined = false;
      }
    }
    if (isAllDefined){
      let day = sel[EInput.DAY]
      if (day.length == 1){ day = '0'+day }
      this.formControlModel.setValue(`${sel[EInput.YEAR]}-${sel[EInput.MONTH]}-${day}`)
    }
    else {
      this.formControlModel.reset()
    }
  }

  validationMessage:string = ''
  
  validateInputs(targetInput:EInput){
    this.validationMessage = '';
    const selVal = this.selection[targetInput]
    if (selVal){
      if (! this.lists[targetInput].includes( String(selVal) ) ){
        this.selection[targetInput] = this.inputsRef[targetInput].matchFuzz(); // defaults to ''
        if (this.selection[targetInput]){
          this.onOptionSelected(targetInput)
        }
        else {
          this.validationMessage = 'INVALID_'+targetInput; // todo:translate
        }
      }
      else {
        this.onOptionSelected(targetInput)
      }
    }
    this.validationMessageChange.emit(this.validationMessage)
  }
  

  onOptionSelected(targetInput:EInput, event?:any){
    this.validationMessage = '';
    this.validationMessageChange.emit(this.validationMessage)
    console.log('onOptionSelected', targetInput)
    if (targetInput !== EInput.DAY){
      this.adjustDays()
      this.validateInputs(EInput.DAY)
    }
    this.onChange()
  }
}
