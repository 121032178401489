import { Component, OnInit, OnDestroy, Input, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ScrollService } from '../../core/scroll.service';
import { AuthService, IUserInfo, F_ERR_MSG__INVALID_LOGIN, F_ERR_MSG__INVALID_EMAIL , F_ERR_MSG__NO_PASSWORD} from '../../api/auth.service';
import { LangService } from '../../core/lang.service';
import { Subscription } from 'rxjs';
import { AccountType } from '../../constants/account-types';
import { LoginValidationService } from 'src/app/core/login-validation.service';
import { FormFail, LoginCtrl } from '../../core/login-ctrl';
import { LoginGuardService } from 'src/app/api/login-guard.service';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';
import { RoutesService } from 'src/app/api/routes.service';

interface ILoginFormErrors {
  isBlank?:boolean,
  isInvalid?: boolean,
}

@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit, OnDestroy {

  @Input() accountType:AccountType;
  @Input() prefilledEmail:string;
  @Output() onLoginAttempt = new EventEmitter();

  public breadcrumb = [];
  public isFormValidated:boolean;
  public isFormSent:boolean;
  public isFormFailed:boolean;
  public formFailReason:FormFail;
  public routeSub:Subscription;
  public loginAttemptsSub:Subscription;
  public formGroupEmailAddress = new FormControl();
  public formGroupPassword = new FormControl();
  public formGroup = new FormGroup({
    emailAddress: this.formGroupEmailAddress,
    password: this.formGroupPassword,
  })
  public FormFail = FormFail;
  private formSubs:Subscription[] = [];
  public loginCtrl:LoginCtrl
  private isPasswordHidden: boolean = true;

  loginErrorTroubleshootMsg;

  allowSelfReg: boolean = false;
  
  constructor(
    public lang: LangService,
    private auth: AuthService,
    private loginGuard: LoginGuardService,
    public whiteLabel: WhitelabelService,
    private breadcrumbsService: BreadcrumbsService,
    private router:Router,
    private route:ActivatedRoute,
    private scrollService:ScrollService,
    private routes: RoutesService,
    private loginValidatorService: LoginValidationService
  ) 
  {
 
  }
  
  ngOnInit() {
    this.loginCtrl = this.loginValidatorService.initCtrl();
    const onFormValueChange = (r) => this.isFormSent = false;
    this.getLoginAttempts();
    this.formSubs = this.formSubs.concat([
      (<FormControl>this.loginCtrl.formGroup.controls.emailAddress).valueChanges.subscribe(onFormValueChange),
      (<FormControl>this.loginCtrl.formGroup.controls.password).valueChanges.subscribe(onFormValueChange)
    ])
    if (this.prefilledEmail){
      this.formGroup.controls.emailAddress.setValue(this.prefilledEmail);
    }

    this.route.queryParams.subscribe((queryParams) => {
      if(queryParams['key'] && queryParams['key'].length > 0) {
        console.log("GOT LOGIN KEY:" + queryParams['key']);

        this.submitLoginKey(queryParams['key']);
      }
    });
    this.auth
      .apiGet(this.routes.LANDING_APPLICANT_REGISTRATION, 0)
      .then(res => {
        this.allowSelfReg = res.isOpen;
      })

  }


  ngOnDestroy() {
    this.formSubs.forEach(sub => sub.unsubscribe() );
    this.loginAttemptsSub.unsubscribe();
  }

  getMainHeader(){
    return 'page_login_h1';
    switch(this.accountType){
      case AccountType.CERT_BODY: return this.lang.tra('page_login_h1_cb');
      case AccountType.TEST_ADMIN: return this.lang.tra('page_login_h1_ta');
      case AccountType.TEST_CTRL: return this.lang.tra('page_login_h1_tc');
      case AccountType.TEST_TAKER: return this.lang.tra('page_login_h1_tt');
      default: return this.lang.tra('page_login_h1')
    }
  }

  getMainInstruction(){
    return '';
    switch(this.accountType){
      case AccountType.CERT_BODY: return 'instr_login_cb';
      case AccountType.TEST_ADMIN: return 'instr_login_test_admin';
      case AccountType.TEST_CTRL: return 'instr_login_tc';
      case AccountType.TEST_TAKER: return 'instr_login_tt';
      default: return ''
    }
  }

  getCurrentAccountType(){
    return this.accountType || AccountType.TEST_TAKER;
  }

  getForgotPasswordRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/forgot-password`
  }

  getNoEmailLoginRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/no-email-login`;
  }
  
  getAccountCreationRoute(){
    return `/${this.lang.c()}/${this.getCurrentAccountType()}/create-account`
  }
  getPrivacyStatementRoute(){
    return `/${this.lang.c()}/privacy`;
  }


  isInputBlank(control:FormControl){
    return control.untouched && control.hasError('isBlank');
  }

  isInputInvalid(control:FormControl){
    return control.untouched && control.hasError('isInvalid');
  }

  getAccountApplicant(){
    return `/${this.lang.c()}/${AccountType.TEST_TAKER}/create-account`
  }

  getAccountAdmin(){
    return `/${this.lang.c()}/${AccountType.TEST_ADMIN}/create-account`
  }

  getPracticeTestPageLink(){
    return `/${this.lang.c()}/candidate-public-practice`
  }

  onApiError(e:{message:string}){
    this.formGroup.enable();
    this.isFormValidated = false;
    this.isFormFailed = true;
    if (e.message == 'NOT_VERIFIED'){
      this.formFailReason = FormFail.NOT_VERIFIED;
    }
    else if(e.message == F_ERR_MSG__INVALID_EMAIL){
      this.formFailReason = FormFail.EMAIL
    }
    else if(e.message == F_ERR_MSG__NO_PASSWORD){
      this.formFailReason = FormFail.PASSWORD
    }
    else if (e.message == F_ERR_MSG__INVALID_LOGIN){
      this.formFailReason = FormFail.NOT_FOUND;
    }
    else{
      this.formFailReason = FormFail.UNKNOWN;
    }

    // easier troubleshooting
    try {
      this.loginErrorTroubleshootMsg = JSON.stringify(e, null, '  ');
    }
    catch(e){
      this.loginErrorTroubleshootMsg = e.code +' '+ e.message;
    }
    // console.log('formFailReason', this.formFailReason)
  }

  private _onApiError(){
    this.formGroup.enable();
    this.isFormValidated = false;
  }

  submitLoginKey(key) {
    this.onLoginAttempt.emit()
    this.isFormSent = true;
    this.auth
        .loginWithKey(key)
        .then(success => {
          if (!success){
            this._onApiError();
            this.clearForm();
          }
        })
        .catch(e => {
          this.onApiError(e);
          this.formGroup.controls.password.reset();
          this.auth.apiCreate('public/log', {
            slug: 'FAILED_LOGIN',
            data: {
              key, // password, // todo: remove this
              errorMessage: e.message
            }
          })
        })
  }

  submitForm(){
    this.loginGuard.setIsLoginFormActivated(true); //only used for MFA logins
    this.loginCtrl.setIsLoginFormActivated(true); //only used for MFA logins
    this.loginCtrl.submitForm();
  }
  
  getLoginAttempts(){
    this.loginAttemptsSub = this.loginCtrl.isLoginAttemptedEvent.subscribe( attempt =>{  
      if (attempt){
        this.onLoginAttempt.emit();
      }
    }

    )
  }

  clearForm(){
    this.formGroup.controls.emailAddress.reset();
    this.formGroup.controls.password.reset();
  }
  
  supportReqActivate()
  {
    let techSupportInfo = this.whiteLabel.getSiteData(null);
    this.loginGuard.supportReqActivate(techSupportInfo);
  }

  checkIfTOTPLogin(): boolean
  {
    if (this.auth.totpAuthenticationFailed)
    {
      this.loginCtrl.isFormFailed = true;
      this.loginCtrl.formFailReason = (FormFail.NOT_FOUND);
      this.auth.totpAuthenticationFailed = false;
    }

    return this.loginGuard.getisTOTPLogin();
  }

  togglePasswordShow() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  isShowingPassword() {
    return !this.isPasswordHidden;
  }

  getReturningUsersSlug(){
    return this.lang.tra(this.whiteLabel.getSiteText("returning_user_title", "lbl_returning_users" ))
  }

  getEmailSlug(){
    return this.lang.tra(this.whiteLabel.getSiteText("email_title", "lbl_user_or_email" ))
  }

  getPasswordSlug(){
    return this.lang.tra(this.whiteLabel.getSiteText("password_title", "lbl_password" ))
  }

  getErrorPasswordSlug(){
    return this.lang.tra("error_enter_password")
  }

  getForgotPasswordSlug(){
    return this.lang.tra("lbl_forgot_password")
  }

  getBadLoginSlug(){
    return this.lang.tra("txt_alert_bad_login")
  }

  getEnterEmail(){
    return this.lang.tra("error_enter_email")
  }

  getInvalidEmailSlug(){
    return this.lang.tra("error_login_invalid_email")
  }

  displaySelfRegButtons(){
    if(this.whiteLabel.isTestCenter()){
      return this.allowSelfReg;
    }
    return true;
  }
}
