import { Injectable } from '@angular/core';
import { IContextData } from './contexts/common';
import {ENS} from './contexts/ens';
import {MPT} from './contexts/mpt';
import {VEA} from './contexts/vea';
import {EQAO} from './contexts/eqao';
import {CAEC} from './contexts/caec';
import {BCED} from './contexts/bced';
import { MBED } from './contexts/mbed';
import { ABED } from './contexts/abed';  
import { EResultsPageTypes } from '../ui-item-maker/item-set-editor/models/assessment-framework';
import { ANON } from './contexts/anon';

const availableContexts:IContextData[] = [ // first entry is also the default
  CAEC,
  // ENS,
  // MPT,
  // EQAO,
  // VEA,
  // MEES,
];

export enum FLAGS 
{
  ABED = "ABED",
  NBED = "NBED",
  EQAO = "EQAO",
  BCED = "BCED",
  MBED = "MBED",
  CAEC = "CAEC",
  SMCS = "SMCS",
}

export const TEMPLATE_BRAND_NAME = '%BRAND_NAME%';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {

  context:string;
  contextData:IContextData;
  defaultWhitelabelContext:string;

  currentHomepageRoute:string;
  currentLoginRoute:string;
  currentBrandName:string;
  currentLogo:string;

  constructor() {
    const hostMap:{}[] = []
    const contextRef = this.registerContexts(availableContexts);

    console.log(contextRef)

    // const storedWhitelabel = localStorage.getItem('defaultWhitelabel'); // need to check if the data is defined before using
    this.context = contextRef.hostRef.get(window.location.host) || contextRef.defaultContextId;

    console.log('whitelabel context', this.context)

    this.contextData = contextRef.data.get(this.context);
    this.currentHomepageRoute = this.contextData.homepageRoute;
    this.currentLoginRoute = this.contextData.loginRoute;
    this.currentBrandName = this.contextData.brandName;
    this.currentLogo = this.contextData.logo;
    this.ensureDefaultContext();
  }

  updateRouteOnLang(homepageRoute: string, lang: string = 'en') {
    let homepage = homepageRoute;
    if (lang === 'fr') {
      // /en/login-router-st
      const homepageArr = homepageRoute.split('/');
      homepageArr[1] = 'fr';
      homepage = homepageArr.join('/');
    }
    return homepage;
  }

  registerContexts(contexts:IContextData[]){
    type ContextId = string;
    const hostRef:Map<ContextId, string> = new Map();
    const data:Map<ContextId, IContextData> = new Map();
    const defaultContextId = contexts[0].id;
    contexts.forEach(contextData => {
      if (data.has(contextData.id)){
        console.warn('Duplicate WL context definition.', contextData.id);
      }
      else{
        data.set(contextData.id, contextData);
        contextData.hosts.forEach(host => {
          if (hostRef.has(host)){
            console.warn('Duplicate host WL definition.', host, contextData.id, hostRef.get(host));
          }
          else{
            hostRef.set(host, contextData.id);
          }
        });
      }
    });
    return {data, hostRef, defaultContextId};
  }

  ensureDefaultContext(){
    const context = this.contextData;
    if (!context){
      console.error('using undefined context');
    }
    context.siteText = this.contextData.siteText || {};
    context.siteFlags = this.contextData.siteFlags || {};
    context.siteData = this.contextData.siteData || {};
    context.footer = this.contextData.footer || [];
    context.siteText = this.contextData.siteText || {};
    const siteText = this.contextData.siteText;
    if (!siteText['SITE_LINK']){
      siteText['SITE_LINK'] = window.location.host;
    }
  }

  onReady( callback ){
    callback(); // need to delay this if config is loaded over a file
  }

  updateTemplateContent(str:string){
    if (str){
      return str.split(TEMPLATE_BRAND_NAME).join(this.currentBrandName);
    }
    return str;
  }

  getApiAddress(){ return this.contextData.apiAddress(window.location.hostname); }
  setMPTctrlID(){this.contextData.setMPTctrlID(window.location.hostname)}
  getApiAddressForWS(){ 
    // return 'https://1ee7-108-162-129-138.ngrok-free.app' // todo: make this conditional on localhost through whitelabel
    return this.contextData.apiAddress(window.location.hostname); 
  }
  getHomepageRoute(){ return this.currentHomepageRoute; }
  getLoginRoute(lang?: string){ 
    return this.currentLoginRoute || this.updateRouteOnLang(this.currentHomepageRoute, lang); 
  }
  getSiteFlag(slug:string){ return this.contextData.siteFlags[slug]; } 
  getSiteData(slug:string){ return this.contextData.siteData[slug]; } 
  getSiteText(slug:string, defaultText:string=''){ return this.contextData.siteText[slug] || defaultText; }
  getFooter(){ return this.contextData.footer; }
  getDefaultCurriculumId(){ return ''; }

  // shortcuts
  isEQAO = () => this.getSiteFlag('IS_EQAO');
  isNBED = () => this.getSiteFlag('IS_NBED'); 
  isABED = () => this.getSiteFlag('IS_ABED'); 
  isMBED = () => this.getSiteFlag('IS_MBED'); 
  isBCED = () => this.getSiteFlag('IS_BCED'); 
  isSMCS = () => this.getSiteFlag('IS_SMCS'); 
  isTestCenter = () => this.getSiteFlag('TEST_CENTER'); 



  // Results Page based on site flag
  isResultPageAvailable = () => this.getSiteFlag('IS_RESULTS_PAGE_AVAILABLE');

  getTimeZone(): string
  {
    if (this.isEQAO())
    {
      return "America/Toronto";
    }

    else if (this.isNBED())
    {
      return "America/Halifax";
    }

    else if (this.isABED() || this.isTestCenter())
    {
      return "America/Edmonton";
    }

    else if (this.isMBED())
    {
      return "America/Chicago";
    }

    else if (this.isBCED())
    {
      return "America/Vancouver";
    }

    return "";
  }

  getWhitelabelFlag() 
  {
    if(this.isABED()) {
      return FLAGS.ABED;
    } else if(this.isNBED()) {
      return FLAGS.NBED;
    } else if(this.isEQAO()) {
      return FLAGS.EQAO;
    } else if(this.isBCED()) {
      return FLAGS.BCED;
    } else if(this.isMBED()) {
      return FLAGS.MBED;
    } else if(this.isSMCS()) {
      return FLAGS.SMCS;
    } else {
      return "NA";
    }
  }

  getTimezoneAbbr(): string
  {
    if (this.isEQAO())
    {
      return "EST";
    }

    else if (this.isNBED())
    {
      return "AST";
    }

    else if (this.isABED())
    {
      return "MST";
    }

    else if (this.isMBED())
    {
      return "CST";
    }

    else if (this.isBCED())
    {
      return "PST";
    }

    return "";
  }
}
