import { THIS_EXPR, ThrowStmt } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { bindFormControl, bindFormControls } from '../../ui-item-maker/services/data-bind';
import {NgbDatepickerI18n, NgbDateStruct, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import { LangService } from '../../core/lang.service';
import { SlugCalendarService } from '../slug-calendar.service';


export enum FilterSettingMode {
  VALUE = 'value',
  RANGE = 'range',
  RADIO = 'radio',
  DATETIME = 'datetime',
  LIST = 'list',
}

export enum FilterDataType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'datetime',
  DROPDOWN = 'dropdown',
}

export interface IFilterSetting {
  mode: FilterSettingMode,
  config: IFilterSettingConfigValue | IFilterSettingConfigRange | IFilterSettingConfigList | IFilterSettingConfigDatetime
}

export interface IFilterSettingConfigValue {
  value?: any,
  selected?: string,
  config?: IFilterSettingConfigValue | IFilterSettingConfigRange | IFilterSettingConfigList | IFilterSettingConfigDatetime
}

export interface IFilterSettingConfigRange {
  from: number,
  to: number,
}
export interface IFilterSettingConfigDatetime {
  dateFrom: Object,
  dateTo: Object,
  timeFrom: Object,
  timeTo: Object,
}

export interface IFilterSettingConfigList {
  selectedValue: string,
  list?: any[],
  config?: IFilterSettingConfigValue | IFilterSettingConfigRange | IFilterSettingConfigList | IFilterSettingConfigDatetime
}

@Component({
  selector: 'capture-filter-range',
  templateUrl: './capture-filter-range.component.html',
  styleUrls: ['./capture-filter-range.component.scss'],
  providers: [{provide: NgbDatepickerI18n, useClass: SlugCalendarService}]
})

export class CaptureFilterRangeComponent implements OnInit {

  @Input() isActive:boolean;
  @Input() model:{[key:string]:IFilterSetting}
  @Input() id:string;
  @Input() isModeSelect:boolean = false;
  @Input() dataType:FilterDataType = FilterDataType.TEXT;
  @Input() defaultMode:FilterSettingMode = FilterSettingMode.VALUE;
  @Input() selectionOptions?: string[];
  @Input() radioValueMapping?: Function;
  @Input() preDefinedFilterValue?: string;
  @Output() change = new EventEmitter();

  FilterSettingMode = FilterSettingMode;
  modeOptions = [
    {id: FilterSettingMode.VALUE, caption:'Value'},
    {id: FilterSettingMode.RANGE, caption:'Range'},
    {id: FilterSettingMode.RADIO, caption:'Radio'},
    {id: FilterSettingMode.DATETIME, caption:'Datetime'},
    {id: FilterSettingMode.LIST, caption:'List'},
  ];
  
  timeFromModel: NgbTimeStruct;
  timeToModel: NgbTimeStruct;
  meridian = true;
  dateFromModel: NgbDateStruct;
  dateToModel: NgbDateStruct;
  state:IFilterSetting;
  config;
  modeSelector = new FormControl();
  value = new FormControl();
  rangeFrom = new FormControl();
  rangeTo = new FormControl();
  dateFrom = new FormControl();
  dateTo = new FormControl();
  timeFrom = new FormControl();
  timeTo = new FormControl();

  optionSelected = new FormControl();
  list = new FormControl();
  selectedValue = new FormControl();
  isInited:boolean;

  FilterDataType = FilterDataType;

  constructor(
    public fb: FormBuilder,
    public lang: LangService,
  ) { }

  ngOnInit() {
    if (!this.model[this.id]){
      if (this.id === 'response') {
        this.model[this.id] = {
          mode: FilterSettingMode.RADIO,
          config: { selected: null }
        }
      } else if (this.id === 'date_time_start') {
        this.model[this.id] = {
          mode: FilterSettingMode.DATETIME,
          config: { dateFrom: null, dateTo: null, timeFrom: null, timeTo: null }
        }
      }
      else {
        this.model[this.id] = {
          mode: this.defaultMode || FilterSettingMode.VALUE,
          config: { value: null }
        }
      }
    }
    this.state = this.model[this.id];
    if(this.model[this.id].mode == FilterSettingMode.LIST){
      this.config = <IFilterSettingConfigList> this.model[this.id].config;
    }

    bindFormControls(this.state, [
      {f: this.modeSelector, p: 'mode'},
    ]);
    bindFormControls(this.state.config, [
      {f: this.value, p: 'value'},
      {f: this.rangeFrom, p: 'from'},
      {f: this.rangeTo, p: 'to'},
      {f: this.dateFrom, p: 'dateFrom'},
      {f: this.dateTo, p: 'dateTo'},
      {f: this.timeFrom, p: 'timeFrom'},
      {f: this.timeTo, p: 'timeTo'},
      {f: this.optionSelected, p: 'selected' }
    ]);
    bindFormControls(this.state.config, [
      {f: this.list, p: 'list'},
      {f: this.selectedValue, p: 'selectedValue'}
    ])
    if(this.model[this.id].mode == FilterSettingMode.LIST){
      const filterConfig = <IFilterSettingConfigList> this.state.config;
      const val = filterConfig.selectedValue;
      if(val){
        this.selectedValue.setValue(val);
      }
    }
    this.modeSelector.setValue(this.model[this.id].mode);
    this.modeSelector.valueChanges.subscribe(obs => this.reflectChange())
    this.value.valueChanges.subscribe(obs => this.reflectChange())
    this.rangeFrom.valueChanges.subscribe(obs => this.reflectChange())
    this.rangeTo.valueChanges.subscribe(obs => this.reflectChange())
    this.optionSelected.valueChanges.subscribe(obs => this.reflectChange())
    this.dateFrom.valueChanges.subscribe(obs => this.reflectChange())
    this.timeFrom.valueChanges.subscribe(obs => this.reflectChange())
    this.dateTo.valueChanges.subscribe(obs => this.reflectChange())
    this.timeTo.valueChanges.subscribe(obs => this.reflectChange())
    this.list.valueChanges.subscribe(obs => this.reflectChange())
    this.selectedValue.valueChanges.subscribe(obs => this.reflectChange())
    this.isInited = true;

    if(this.preDefinedFilterValue && this.defaultMode == FilterSettingMode.VALUE){
      this.value.setValue(this.preDefinedFilterValue)
    }
  }

  reflectChange(){
    if (this.isInited){
      this.change.emit();
    }
  }

}
