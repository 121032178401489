<!-- <capture-image></capture-image> -->
<input type="file" (change)="startUpload($event.target.files)" class="upload-file-input" [class.is-transparent]="getQuestionState().fileName || fileNameIllegal">

<div *ngIf="getUploadedFileUrl() && !isRequestingReupload" style="margin-top:1em; font-size:0.8em;">
    <p><tra slug="this_is_what_you_uploaded"></tra></p>
    <div *ngIf="isFileImage()">
        <img [src]="getSanitizedUploadedFileUrl()">
    </div>
    <div *ngIf="!isFileImage()">
        <a [href]="getSanitizedUploadedFileUrl()">
          <i class="fas fa-file-download" style="margin-top: 0.5em;"></i>
          <span style="font-size:0.8em;">
            {{getDownloadFileButtonName()}}
          </span>
        </a>
      </div>
</div>