import { I } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { nodeName } from 'jquery';
import { AuthService } from '../api/auth.service';
import { RoutesService } from '../api/routes.service';
import { LangService } from '../core/lang.service';
import { processText, removeLeadingEllipses, StyleprofileService, StylingProcess } from '../core/styleprofile.service';
import { IContentElementCanvas } from '../ui-testrunner/element-render-canvas/model';
import { IContentElementCustomMcqOption } from '../ui-testrunner/element-render-custom-mcq/model';
import { IContentElementDndDraggable } from '../ui-testrunner/element-render-dnd/model';
import { IContentElementFrame } from '../ui-testrunner/element-render-frame/model';
import { IContentElementGroup } from '../ui-testrunner/element-render-grouping/model';
import { IContentElementDynamicImage, IContentElementImage, ImageStates } from '../ui-testrunner/element-render-image/model';
import { IContentElementInput } from '../ui-testrunner/element-render-input/model';
import { IContentElementInsertion } from '../ui-testrunner/element-render-insertion/model';
import { IContentElementMath } from '../ui-testrunner/element-render-math/model';
import { IContentElementMcq, IContentElementMcqOption, McqDisplay, IContentElementMcqOptionInTable } from '../ui-testrunner/element-render-mcq/model';
import { IContentElementMoveableDragDrop } from '../ui-testrunner/element-render-moveable-dnd/model';
import { reorderOrderOptionsToInit as reorderOrderOptions, useSetOrders } from '../ui-testrunner/element-render-order/element-render-order.component';
import { IContentElementOrder, OrderMode } from '../ui-testrunner/element-render-order/model';
import { IContentElementSelectionTable } from '../ui-testrunner/element-render-selection-table/model';
import { isSolutionHeaderVisible } from '../ui-testrunner/element-render-solution/element-render-solution.component';
import { IContentElementSolution } from '../ui-testrunner/element-render-solution/model';
import { IContentElementTable, IContentElementTableCell } from '../ui-testrunner/element-render-table/model';
import { IContentElementText, TextParagraphStyle } from '../ui-testrunner/element-render-text/model';
import { ElementType, IContentElement, IQuestionConfig } from '../ui-testrunner/models';
import { DEFAULT_VOICEOVER_PROP } from './element-config-mcq-option-info/element-config-mcq-option-info.component';
import { QUESTION_WORDING_OPTS } from './item-set-editor/models/assessment-framework';
import { IContentElementPassage } from '../ui-testrunner/element-render-passage/model';
import { IContentElementTemplate } from '../ui-testrunner/element-render-template/model';
import { IContentElementVirtualTools } from '../ui-testrunner/element-render-virtual-tools/model';

// const OPTION_TEXT_EN = 'Option'
export interface IScriptGenMeta {
  optionScripts:string[],
  useOldScripts?:boolean,
  useOldScriptsDecision?:boolean,
}

const optionLetters = 'ABCDEFGHIJKLMNOP'.split('')

const MAX_SCRIPT_SIZE = 2500;

@Injectable({
  providedIn: 'root'
})
export class ScriptGenService {
  constructor(
    private auth:AuthService,
    private routes:RoutesService,
    private lang: LangService,
    private profile: StyleprofileService
  ) { }

  numUploadsStarted:number;
  numUploadsCompleted:number;

  // public autoGenQuestionCaptionVoiceover(sectionId: number, question: IQuestionConfig, questionTitle: string, lang:string = 'en') {
  //   if(!question.captionVoiceover) {
  //     question.captionVoiceover = {};
  //   }

  //   if(!question.captionVoiceover[sectionId]) {
  //     question.captionVoiceover[sectionId] = {};
  //   }
  //   const captionVoiceoverDef = question.captionVoiceover[sectionId];

  //   const oldCaptionVoiceover = captionVoiceoverDef.script;
  //   captionVoiceoverDef.script = questionTitle;

  //   if(oldCaptionVoiceover !== captionVoiceoverDef.script && captionVoiceoverDef.script) {
  //     for(const opt of QUESTION_WORDING_OPTS) {
  //       const qWord = this.lang.tra(opt, lang);
  //       const qWordEn = this.lang.tra(opt, 'en');
  //       const regex = new RegExp(`${qWord} (\\d+)`);
  //       const qMatch = captionVoiceoverDef.script.match(regex);
  //       if(qMatch && qMatch.length > 1) {
  //         const number = qMatch[1];
  //         const slug = `lbl_${qWordEn.toLowerCase()}_${number}`
  //         captionVoiceoverDef.url = this.lang.traVoice(slug, lang);
  //         captionVoiceoverDef.fileType = "audio/mp3";
  //         return Promise.resolve();
  //       } 
  //     }
  //     return this.uploadNewVoice(captionVoiceoverDef.script, captionVoiceoverDef, lang);
  //   }
  //   return Promise.resolve();
  // }

  public autoGenElVoiceover(el: IContentElement, lang:string = 'en', meta:IScriptGenMeta = {optionScripts: []}) {
    if(!el) {
      return;
    }
    if(!el.voiceover) {
      el.voiceover = {};
    }
    const oldScript = el.voiceover.script; 
    const script = this.extractScriptFromNode(el, lang, meta, []);
    this.useScriptDecision(el, meta, script)
    if(script && script !== oldScript) {
      this.uploadNewVoice(el.voiceover.script, el.voiceover, lang);
    }
  }

  autoGenQuestionVoiceover(question:IQuestionConfig, lang:string='en', isOverridesDisabled:boolean=false){
    this.numUploadsStarted = 0;
    this.numUploadsCompleted = 0;
    let optionScripts = [];
    // try to pull manual overrides on the question text
    if (question.voiceover && question.voiceover.script){
      let lastLineWithOption = null;
      let hasNonAdjacentLines = false;
      question.voiceover.script.split('\n').forEach((lineStr, i) => {
        const optionLineStart = 'Option "';
        if (lineStr.substr(0, optionLineStart.length) === optionLineStart){
          if (lastLineWithOption && lastLineWithOption !== i-1){
            hasNonAdjacentLines = true;
          }
          optionScripts.push(lineStr);
          lastLineWithOption = i;
        }
      });
      if (!isOverridesDisabled){
        if (hasNonAdjacentLines){
          if (!confirm('The option text looks a little more complex than usual, and some text might have been missed. Would you still like to apply manual overrides from the main text to the option text?')){
            optionScripts = [];
          }
        }
        else if (optionScripts.length > 0){
          if (!confirm('Would you like to apply manual overrides from the main text to the option text?')){
            optionScripts = [];
          }
        }
      }
    }
    const meta:IScriptGenMeta = {optionScripts}; 
    if (isOverridesDisabled){
      meta.useOldScriptsDecision = true;
      meta.useOldScripts = true;
    }
    // compute the script from the child nodes
    let script = this.extractScriptFromNodes(question.content, lang, meta, []);
    
    if(this.profile.getStyleProfile()[lang].voiceScript.general.removeBeginningEllipses) {
      script = removeLeadingEllipses(script);
    }

    if (!question.voiceover){
      question.voiceover = {};
    }

    question.voiceover.script = script;
    

    this.autoGenElVoiceover(question.bannerSubtitle, lang, meta);
    this.autoGenElVoiceover(question.bannerTitle, lang, meta)

    return script;
  }

  private extractScriptFromNodes(nodes:IContentElement[], lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[], delim:string='\n', pauseAroundExpression:boolean=false) {
    let script = "";
    if(!nodes) {
      return "";
    }
    for(let i = 0; i < nodes.length; i++) {
      if(pauseAroundExpression && i !== 0 && nodes[i].elementType === ElementType.MATH && 
        nodes[i - 1].elementType === ElementType.TEXT) {
          script += " ... ";
        }
      script += this.extractScriptFromNode(nodes[i], lang, meta, preProcesses);
      if(pauseAroundExpression && i !== nodes.length - 1 && nodes[i].elementType === ElementType.MATH && 
        nodes[i + 1].elementType === ElementType.TEXT) {
        script += ` ... ${delim}`
      } else {
        script += delim;
      }
    }

    return script;
  }
  private extractScriptFromNode(node:IContentElement, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    // console.log('check : ', node.elementType, node);
    switch(node.elementType){
      case ElementType.TEXT: return this.extractScriptFromTextNode( <IContentElementText> node, lang, meta, preProcesses);
      case ElementType.TABLE: return this.extractScriptFromTableNode( <IContentElementTable> node, lang, meta);
      case ElementType.SELECT_TABLE: return this.extractScriptFromSelectionTableNode( <IContentElementSelectionTable> node, lang, meta, preProcesses);
      case ElementType.INSERTION: return this.extractScriptFromInsertionNode(<IContentElementInsertion>node, lang, meta, preProcesses);
      case ElementType.MATH: return this.extractScriptFromMathNode( <IContentElementMath> node, lang);
      case ElementType.IMAGE: return this.extractScriptFromImageNode( <IContentElementImage> node, lang, meta);
      case ElementType.DYNAMIC_IMAGE: return this.extractScriptFromImageNode( <IContentElementImage> node, lang, meta);
      case ElementType.MCQ: return this.extractScriptFromMcqNode( <IContentElementMcq> node, lang, meta, preProcesses);
      case ElementType.CUSTOM_MCQ: return this.extractScriptFromMcqNode( <IContentElementMcq> node, lang, meta, preProcesses);
      case ElementType.GROUPING: return this.extractScriptFromGroupNode(<IContentElementGroup> node, lang, meta, preProcesses);
      case ElementType.PASSAGE: return this.extractScriptFromPassageNode(<IContentElementPassage> node, lang, meta, preProcesses);
      case ElementType.TEMPLATE: return this.extractScriptFromTemplateNode(<IContentElementTemplate> node, lang, meta, preProcesses);
      case ElementType.ORDER: return this.extractScriptFromOrdering(<IContentElementOrder> node, lang, meta, preProcesses);
      case ElementType.INPUT: return this.extractScriptFromInputNode(<IContentElementInput> node, lang, meta, preProcesses);
      case ElementType.CANVAS: return this.extractScriptFromCanvas(<IContentElementCanvas> node, lang, meta, preProcesses);
      case ElementType.FRAME: return this.extractScriptFromFrame(<IContentElementFrame> node, lang, meta, preProcesses);
      case ElementType.MOVEABLE_DND: return this.extractScriptFromMoveableDnd(<IContentElementMoveableDragDrop> node, lang, meta, preProcesses);
      case ElementType.VIRTUAL_TOOLS: return this.extractScriptFromVirtualToolsNode(<IContentElementVirtualTools> node, lang, meta, preProcesses);
      case ElementType.SOLUTION: return this.extractScriptFromSolution(<IContentElementSolution>node, lang, meta, preProcesses);
    }
    return "";
  }

  extractScriptFromSolution(node: IContentElementSolution, lang: string, meta: IScriptGenMeta, preProcesses:StylingProcess[]) {
    let script = "";

    if(isSolutionHeaderVisible(node)) {
      script += this.lang.tra('lbl_correct_answer', lang);
      script += this.lang.tra('txt_colon', lang);
      script += '\n';
    }   
    script += this.extractScriptFromNodes(node.content, lang, meta, preProcesses);
    this.useScriptDecision(node, meta, script);
    this.uploadNewVoice(node.voiceover.script, node.voiceover, lang);
    return ""; //Don't include solution script in overall voiceover script.
  }

  extractScriptFromMoveableDnd(node: IContentElementMoveableDragDrop, lang: string, meta: IScriptGenMeta, preProcesses:StylingProcess[]) {
    let script = [];

    script.push(`...  ${this.lang.tra('voice_insertion_terms', lang)} ... \n`);

    for(const drag of node.draggables) {
      script.push(this.extractScriptFromDraggableNode(drag, lang, meta, preProcesses));
    }

    if(node.targets?.length) {
      script.push(`... ${this.lang.tra('voice_dnd_num_targets', lang, {NUM_TARGETS: node.targets?.length, OPTIONAL_S: node.targets?.length > 1 ? 's' : ''})} ... \n`);
    }
    return script.join(' ... \n');
  }

  extractScriptFromFrame(node:IContentElementFrame, lang, meta, preProcesses) {
    let script = [];
    node.content.forEach((subnode, index)=>{
      script.push(this.extractScriptFromNode(subnode, lang, meta, preProcesses));
    })
    return script.join('...\n');
  }

  extractScriptFromCanvas(node:IContentElementCanvas, lang, meta, preProcesses) {
    let script = [];
    node.pages.forEach((page, index)=>{
      page.displayList.forEach((el)=>{
        script.push(this.extractScriptFromNode(el, lang, meta, preProcesses));
      })
    })

    return script.join('...\n');
  }

  extractScriptFromInputNode(node:IContentElementInput, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    let script = [];
    const inputScript = this.profile.getStyleProfile()[lang].voiceScript.input;
    // const blank = this.profile.getStyleProfile()[lang].voiceScript.general;
    // const numWords = node.maxWords;
    // const str = `${this.lang.tra(inputScript.total_words, lang)}`
    //if (node.maxWords) script.push(str.replace("{{blank}}", numWords.toString()));
    return script
  }

  extractScriptFromPassageNode(node:IContentElementPassage, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    return this.processPlainTextScript(node.text, lang, preProcesses);
  }

  extractScriptFromTemplateNode(node:IContentElementTemplate, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    return this.extractScriptFromNodes(node.content, lang, meta, preProcesses)
  }

  extractScriptFromGroupNode(node:IContentElementGroup, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    // const response = [];
    // response.push('Draggable options. ')
    // node.draggables.forEach((draggable, i) => {
    //   response.push('Option 1. '+ this.extractScriptFromNode(draggable.element, lang, meta)); 
    // })
    // response.push('Draggable options. ')
    // node.draggables.forEach((draggable, i) => {
    //   response.push('Option 1. '+ this.extractScriptFromNode(draggable.element, lang, meta)); 
    // })
    let script = [];   
    const groupingScript = this.profile.getStyleProfile()[lang].voiceScript.grouping;
    script.push(`... ${this.lang.tra(groupingScript?.blocks || 'voice_grouping_terms', lang)} ...`);
    node.draggables.forEach((drag, index)=>{
      script.push(this.extractScriptFromDraggableNode(drag, lang, meta, preProcesses));
    })
    
    if (!node.isInstructionsDisabled) script.push(` ... ${this.lang.tra(groupingScript?.grouping_instr || 'txt_default_drag_instr', lang)}`)

    node.targets.forEach((target, index)=>{
      // script.push(`... ${this.lang.tra(groupingScript?.targets || 'voice_grouping_targets', lang)} `+(1+index));
      this.getNumberedOrder(node, script, index, index==node.draggables.length-1, lang);
      const sub = this.extractScriptFromNode(target.element, lang, meta, preProcesses);
      this.useScriptDecision(target, meta, sub);
      script.push("...");
      script.push(target.voiceover.script);
    })

    return script.join(' ');
  }

  private extractScriptFromDraggableNode(node: IContentElementDndDraggable, lang: string, meta: IScriptGenMeta, preProcesses: StylingProcess[]) {
    let block;
    if (node.element.voiceover && node.element.voiceover.script!=null) block = this.processPlainTextScript(node.element.voiceover.script, lang, preProcesses)+" ...\n";
    else {
      block = this.extractScriptFromNode( node.element, lang, meta, preProcesses)+" ... \n";
    }
    this.useScriptDecision(node, meta, block);
    return " ... "+node.voiceover.script;
  }

  private extractScriptFromInsertionNode(node:IContentElementInsertion, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    let script = [];   
    const insertionScript = this.profile.getStyleProfile()[lang].voiceScript.insertion;
    const blank = this.profile.getStyleProfile()[lang].voiceScript.general.blank;
    //script.push(`${this.lang.tra(insertionScript?.insertion || 'voice_insertion', lang)} ... `);

    if (node.isDragBetweenWords && (node.isShowInstructions == undefined ||node.isShowInstructions)) script.push(`... ${this.lang.tra(insertionScript?.instr_blind || 'voice_insertion_blind_instr', lang)} ... `);
    else if ((node.isShowInstructions == undefined ||node.isShowInstructions)) script.push(`... ${this.lang.tra(insertionScript?.instr_blocks || 'voice_insertion_instr', lang)} ... `);

    const voiceTargets = ()=>{
      if (lang!="en") script.push(`${this.lang.tra(insertionScript?.text || 'voice_insertion_text', lang)} ... \n`);
        node.textBlocks.forEach((target)=>{
          if(target.element.elementType === ElementType.TEXT) {
            const textBlockScript = this.extractScriptFromNode(target.element, lang, meta, preProcesses)+"...\n";
            this.useScriptDecision(target.element, meta, textBlockScript);
            this.uploadNewVoice(target.element.voiceover.script, target.element.voiceover, lang);
            script.push(target.element.voiceover.script);
          } else if ( (target.element.elementType=="" || target.element.elementType == ElementType.BLANK) && !node.isDragBetweenWords) {
            script.push("... " + `${this.lang.tra(blank, lang)} ...` );
          }
        })
    }

    if (node.isTargetsAbove) voiceTargets();

    script.push(`...  ${this.lang.tra(insertionScript?.terms || 'voice_insertion_terms', lang)} ... \n`);
    node.draggables.forEach((drag)=>{
        script.push(this.extractScriptFromDraggableNode(drag, lang, meta, preProcesses));
    })

    if (!node.isTargetsAbove) voiceTargets();

    return script.join(' ');
  }

  private extractScriptFromTextNode(node:IContentElementText, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    switch(node.paragraphStyle){
      case TextParagraphStyle.ADVANCED_INLINE:
        const pauseAroundExpression = this.profile.getStyleProfile()[lang].voiceScript.advancedInline.pauseAroundExpression;
        const blank = this.profile.getStyleProfile()[lang].voiceScript.mcq.dropDown_blank;
        if (this.checkIfOneDropDown(node.advancedList)) {
          let script = [];
          let mcqNode;
          node.advancedList.forEach(node => {
            if (node.elementType==ElementType.MCQ) {
              mcqNode = node;
              if (!node["defaultDropdownText"] || node["defaultDropdownText"]=='') script.push("... " + `${this.lang.tra(blank, lang)}` + "...");
              else script.push("... "+node["defaultDropdownText"]+"...")
            } else {
              script.push(this.extractScriptFromNode(node, lang, meta, preProcesses));
            }
          });
          const selOptions = this.profile.getStyleProfile()[lang].voiceScript.mcq.dropDown;
          // script.push("..."+`${this.lang.tra(selOptions, lang)}`+"...");
          script.push(this.extractScriptFromMcqNode(mcqNode, lang, meta, preProcesses));
          return script.join(' ');
        } 
        return this.extractScriptFromNodes(node.advancedList, lang, meta, preProcesses, ' ', pauseAroundExpression);
      case TextParagraphStyle.BULLET:
      case TextParagraphStyle.NUMBERED:
        if (node.simpleList.length > 0){
          return node.simpleList.map( str => this.processPlainTextScript(str, lang, preProcesses)).join('...\n')
        }
        else if (node.advancedList){
          return this.extractScriptFromNodes(node.advancedList, lang, meta, preProcesses)
        }
        else{
          return '';
        }
      case TextParagraphStyle.PARAGRAPHS: 
        if(!node.paragraphList?.length) {
          return '';
        } 
        return node.paragraphList.map( par => this.processPlainTextScript(par.caption, lang, preProcesses)).join('...\n')
      case TextParagraphStyle.HEADLINE:
      case TextParagraphStyle.HEADLINE_SMALL:
      case TextParagraphStyle.REGULAR:
      case TextParagraphStyle.SMALL:
      case TextParagraphStyle.LINK:
      case TextParagraphStyle.ANNOTATION:
      default:
        return this.processPlainTextScript(node.caption, lang, preProcesses);
    }
  }

  private checkIfOneDropDown(nodes:IContentElement[]) {
    let numMcq = 0;
    nodes.forEach((node)=>{
      if (node.elementType==ElementType.MCQ) {
        numMcq++;
      }
    })

    if (numMcq==1) return true;
    return false;
  }

  private processPlainTextScript(str:string, lang:string, preProcesses: StylingProcess[]) {
    return processText(str, preProcesses.concat(<StylingProcess[]>this.profile.getStyleProfile()[lang].voiceScript.plainText));
  }

  private extractScriptFromTableCell(cell:IContentElementTableCell, i_row:number, i_col:number, node:IContentElementTable, lang:string, meta:IScriptGenMeta, inverted:boolean) {
    const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
    const firstColumnIsHeader = node.isHeaderCol;
    const firstRowIsHeader = node.isHeaderRow;

    let rowColNumber = (inverted ? i_row : i_col) + 1; //we should actually be looking at the rows if inverted
    
    const isHeaderCell = (i_row === 0 && firstRowIsHeader) || (i_col === 0 && firstColumnIsHeader);
    
    if(!isHeaderCell && tableProfile.onlyReadHeaderCells) {
      return [];
    }

    const preProcesses = isHeaderCell ? tableProfile.headerProcesses : [];
    
    let str = cell.elementType ? this.extractScriptFromNode(<IContentElement> cell, lang, meta, preProcesses) : this.processPlainTextScript(cell.val, lang, preProcesses);
    
    if(isHeaderCell && tableProfile.onlyReadHeaderCells) {
      str += " ... "
    }
    
    let cellScript = [];

    if(!tableProfile.onlyReadHeaderCells) {
      cellScript.push(` ... ${this.lang.tra(inverted ? tableProfile.beginRow : tableProfile.beginColumn, lang)} ${rowColNumber} ${cell.val} ... `);
    }
    cellScript.push(str);
    
    return cellScript;
  }

  private extractScriptFromTableNode(node:IContentElementTable, lang:string, meta:IScriptGenMeta) {
    const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
    
    const firstColumnIsHeader = node.isHeaderCol;
    const firstRowIsHeader = node.isHeaderRow;

    let script = [];
    const beginTableSlug = node.isTableOfValues ? tableProfile.beginTableValues : tableProfile.beginTable;
    script.push(`${this.lang.tra(beginTableSlug, lang)} ... `);

    if(firstColumnIsHeader && tableProfile.columnHeaderReadRowsFirst && node.grid.length > 0) {
      for(let c = 0; c < node.grid[0].length; c++) {
        let cellScript = [];
        for(let r = 0; r < node.grid.length; r++) {
          cellScript = cellScript.concat(this.extractScriptFromTableCell(node.grid[r][c], r, c, node, lang, meta, true));
        }

        let colNumber = c + 1;
        if(!tableProfile.onlyReadHeaderCells) {
          script.push(` ... ${this.lang.tra(tableProfile.beginColumn, lang)} ${colNumber} ... `);
        }
        script = script.concat(cellScript)

      }
    } else {
      node.grid.forEach( (row:IContentElementTableCell[], i_row) => {
        let cellScript = [];
        row.forEach((cell:IContentElementTableCell, i_col) => {
          cellScript = cellScript.concat(this.extractScriptFromTableCell(cell, i_row, i_col, node, lang, meta, false));
        });
  
        let rowNumber = i_row + 1;
        if(!tableProfile.onlyReadHeaderCells) {
          script.push(` ... ${this.lang.tra(tableProfile.beginRow, lang)} ${rowNumber} ... `);
        }
        script = script.concat(cellScript)
      });
    }

    const endTableSlug = node.isTableOfValues ? tableProfile.endTableOfValues : tableProfile.endTable;
    if(endTableSlug) {
      script.push(` ... ${this.lang.tra(endTableSlug, lang)} ... `);
    }
    return script.join(' ');
  }

  private extractScriptFromSelectionTableNode(node:IContentElementSelectionTable, lang:string, meta:IScriptGenMeta, preProcesses: StylingProcess[]) {
    const rows = node.leftCol;
    const cols = node.topRow;
    const script = [];

    if(node.topLeftText) {
      const topLeftScript = this.extractScriptFromNode(node.topLeftText, lang, meta, preProcesses);
      this.useScriptDecision(node, meta, topLeftScript, 'topLeft_voiceover');
      if(node.topLeft_voiceover.script) {
        script.push(node.topLeft_voiceover.script + " ... ");
      }
    }

    rows.forEach((rowHead, r)=>{
      const rowScript = this.extractScriptFromNode(rowHead.content, lang, meta, preProcesses);
      this.useScriptDecision(rowHead, meta, rowScript);
      script.push(rowHead.voiceover.script + " ... ");
      cols.forEach((colHead, c)=>{
        const colScript = this.extractScriptFromNode(colHead.content, lang, meta, preProcesses);
        this.useScriptDecision(colHead, meta, colScript);
        script.push(colHead.voiceover.script + " ... ")
        if (c!=cols.length-1) {
          script.push(`${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.general.or, lang)} ... `)
        }
      });
    });

    return script.join(' ');
  }

  private getNumberedOrder(node:IContentElement, script, index:number, isLast:boolean, lang:string) {
    const box = `${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.ordering?.box || 'voice_box')}`
    if (index==0) {
      script.push("... "+`${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.general.first)}`+" "+box)
    } else if (index == 1) {
      script.push("... "+`${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.general.second)}`+" "+box)
    } else if (index == 2) {
      script.push("... "+`${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.general.third)}`+" "+box)
    } else if (!isLast || lang=='fr') {
      if (lang=='en') script.push("... "+(index+1)+"th "+box);
      else script.push("... "+(index+1)+"e "+box)
    } else {
      script.push("... "+`${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.general.last)}`+" "+box)
    }
  } 

  private extractScriptFromOrdering(node:IContentElementOrder, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    const orderProfile = this.profile.getStyleProfile()[lang].voiceScript.ordering;
    const script = [];
    const isReorder = (node.orderMode == OrderMode.REORDER);
    if (node.showDefaultText || node.showDefaultText==undefined) script.push(`... ${this.lang.tra(orderProfile?.order_instr || 'txt_default_order_instr' )}`);
    let numFixed = 0;
    const item = `${this.lang.tra(orderProfile?.items || 'voice_order_items' )}`;
    script.push(" ... "+item)
    
    let use_options = node.scrambledOptions

    if(useSetOrders(node)) {
      use_options = reorderOrderOptions(use_options);
    }
    
    use_options.forEach((option, index)=>{
      let block;
      if (option.elementType == ElementType.TEXT) block = this.processPlainTextScript(option.caption || option.content, lang, preProcesses);
      else if (option.elementType == ElementType.MATH) block = this.extractScriptFromMathNode(option, lang);
      else if (option.elementType == ElementType.IMAGE && option.images[ImageStates.DEFAULT]) block = this.extractScriptFromImageNode(option.images[ImageStates.DEFAULT].image, lang, meta);
      
      if (!option.isReadOnly) {
        this.useScriptDecision(option, meta, block);
        script.push(" ... "+option.voiceover.script) 
      }
    })

    if(!isReorder){
      use_options = node.options;
      if(useSetOrders(node)) {
        use_options = reorderOrderOptions(use_options);
      }
      use_options.forEach((option, index)=>{
        this.getNumberedOrder(node, script, index, index==use_options.length-1, lang)
        let labelMsg;
        if (option.labelType == ElementType.IMAGE && option.labelImg) {
          labelMsg = this.extractScriptFromImageNode(option.labelImg, lang, meta);
        } else if ((!option.labelType || option.labelType==ElementType.TEXT) && option.label) {
          labelMsg = this.processPlainTextScript(option.label, lang, preProcesses);
        }       
        if (labelMsg) {
          this.useScriptDecision(option, meta, labelMsg, 'label_voiceover')
          script.push(" ..."+option.label_voiceover.script)
        }
  
        if (option.isReadOnly) {
          let block;
          if (option.elementType == ElementType.TEXT) block = this.processPlainTextScript(option.content, lang, preProcesses);
          else if (option.elementType == ElementType.MATH) block = this.extractScriptFromMathNode(option, lang);
          else if (option.elementType == ElementType.IMAGE && option.images[ImageStates.DEFAULT]) block = this.extractScriptFromImageNode(option.images[ImageStates.DEFAULT].image, lang, meta);         
          this.useScriptDecision(option, meta, block);
          script.push(" ... "+option.voiceover.script)
        }        
      })
    }    

    return script.join(' ');
  }

  private extractScriptFromMathNode(node:any, lang:string) {
    let latex = node.latex || (<any>node).content; // second alt is for mcq math
    
    if(!latex) {
      latex = "";
    }
    return processText(latex, <StylingProcess[]>this.profile.getStyleProfile()[lang].voiceScript.math);
  }

  

  private extractScriptFromImageNode(node:IContentElementDynamicImage, lang:string, meta:IScriptGenMeta) {
    const script = [];
    if (!node) return '';
    
    if (node.altText) {
      script.push(node.altText);
    }
    
    else if (node.images) {
      const img = node.images[ImageStates.DEFAULT];
      if (img && img.image.altText) {
        script.push(img.image.altText);
      }
      
    }
    if (node.subtexts) {
      node.subtexts.forEach((text)=>{
        script.push(" ... " + text.text +" ... ")
      })
    }

    this.useScriptDecision(node, meta, script.join(' '));
    this.uploadNewVoice(node.voiceover.script, node.voiceover, lang);
    return node.voiceover?.script; 
  }


  public extractScriptFromMcqNode(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    let scriptParts:string[] = [];
    if(node.displayStyle === McqDisplay.TABLE){
      const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
      if(!node.isRadioRowHeaderDisabled) {
        const rowHeaderLabel = this.lang.tra('lbl_mcq_row');
        let script = ` ... ${rowHeaderLabel} ... `;
        const beginColLabel = this.lang.tra(tableProfile.beginColumn, lang);
        node.tableCols?.forEach((colHeader, i) => script += ` ... ${beginColLabel} ${i+1} ... ${this.processPlainTextScript(colHeader.label, lang, preProcesses)} ... `);
        scriptParts.push(script);
      }
    }
    const beginMcqOptions = `${this.lang.tra(this.profile.getStyleProfile()[lang].voiceScript.mcq.beginOptions)}`;
    scriptParts.push(beginMcqOptions);
    node.options.forEach((option, i) => this.extractScriptFromMcqOptionNode(node, lang, meta, option, i, scriptParts, preProcesses) );
    return scriptParts.join('...\n')
  }

  public extractScriptFromMcqNodeAsync(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]) {
    let scriptParts:string[] = [];
    return Promise.all(
      node.options.map((option, i) => this.extractScriptFromMcqOptionNode(node, lang, meta, option, i, scriptParts, preProcesses) )
    )
  }

  public useScriptDecision(option, meta, script, voiceoverProp: string = DEFAULT_VOICEOVER_PROP) {
    option[voiceoverProp] = option[voiceoverProp] || {};
    if (option[voiceoverProp].script && !meta.useOldScriptsDecision){
      meta.useOldScripts = confirm('Some of the options already have some script generated inside. Would you want to use the old script?');
      meta.useOldScriptsDecision = true;
    }
    if (!meta.useOldScripts && meta.optionScripts.length > 0){
      const injectedScript = meta.optionScripts.splice(0,1)[0];
      option[voiceoverProp].script = injectedScript
    }
    else if (option[voiceoverProp].script && meta.useOldScripts){
      if (script !== option[voiceoverProp].script){
        console.warn('Option script has been modified from original recording', [script, option[voiceoverProp].script])
      }
    }
    else {
      option[voiceoverProp].script = script;
    }
  }

  public extractScriptFromMcqOptionNode(node:IContentElementMcq, lang:string, meta:IScriptGenMeta, option:IContentElementMcqOption, i:number, scriptParts:string[], preProcesses:StylingProcess[]) {
    const mcqProfile = this.profile.getStyleProfile()[lang].voiceScript.mcq;
    const tableProfile = this.profile.getStyleProfile()[lang].voiceScript.table;
      // save the script into the option voice slot
      if (!option.voiceover){
        option.voiceover = {};
      }
      let script;
      switch (option.elementType){
        case 'text':
          let elText:IContentElementText = <any> option;
          if (elText.paragraphStyle){
            script = this.extractScriptFromNode(option, lang, meta, preProcesses);
          }
          else{
            script = this.processPlainTextScript(option.content, lang, preProcesses);
          }
        break;
        default: 
          if (option.elementType != ElementType.DYNAMIC_IMAGE) {
            script = this.extractScriptFromNode(option, lang, meta, preProcesses); 
          } else {
            const dyn_option = <IContentElementCustomMcqOption> option;
            script = this.extractScriptFromNode(dyn_option.dynamicImage, lang, meta, preProcesses);
          }
          break;
      }
      const returnOptionsScript = (optionLetter)=>{ script = `${this.lang.tra(mcqProfile.beginOption, lang)} ` +optionLetter+`. ${script} ...`;}
      
      if(!node.isOptionLabelsDisabled){
        returnOptionsScript(`${optionLetters[i]}`)
      } else{
        returnOptionsScript('');
      }

      if(node.displayStyle === McqDisplay.TABLE ){
        const beginColLabel = this.lang.tra(tableProfile.beginColumn, lang);
        (<IContentElementMcqOptionInTable>option).cols?.forEach((col, i) => script += ` ... ${beginColLabel} ... ${i+1} ... ${this.processPlainTextScript(col.content, lang, preProcesses)} ... `);
      }

      if (!node.isOptionLabelsDisabled && node.displayStyle != McqDisplay.DROPDOWN && node.elementType != ElementType.CUSTOM_MCQ) script = `${this.lang.tra(mcqProfile.beginOption, lang)} "${optionLetters[i]}". ${script} ...`;
      
      /*if (option.voiceover.script && !meta.useOldScriptsDecision){
        meta.useOldScripts = confirm('Some of the options already have some script generated inside. Would you want to use the old script?');
        meta.useOldScriptsDecision = true;
      }
      if (!meta.useOldScripts && meta.optionScripts.length > 0){
        const injectedScript = meta.optionScripts.splice(0,1)[0];
        option.voiceover.script = injectedScript
      }
      else if (option.voiceover.script && meta.useOldScripts){
        if (script !== option.voiceover.script){
          console.warn('Option script has been modified from original recording', [script, option.voiceover.script])
        }
      }
      else {
        option.voiceover.script = script;
      }*/
      this.useScriptDecision(option, meta, script);
      scriptParts.push(option.voiceover.script +"...");
      return this.uploadNewVoice(option.voiceover.script, option.voiceover, lang);
  }

  private extractScriptFromVirtualToolsNode(node:IContentElementVirtualTools, lang:string, meta:IScriptGenMeta, preProcesses:StylingProcess[]){
    let script = '';
    script += this.extractScriptFromImageNode(node.bgImage, lang, meta);
    return script;
  }

  uploadNewVoice(script, element:{url?: string, fileType?:string, index?:number}, lang, fromItemVoiceOver?:boolean){   
    if (script.length>=MAX_SCRIPT_SIZE) {
      const sentences = script.split('.');
      let currSentences = "";
      let partitions = [];
      sentences.forEach(sentence => {
        if (currSentences.length+sentence.length>MAX_SCRIPT_SIZE) {
          partitions.push(currSentences);
          currSentences = "";
        }
        currSentences += sentence;
      });
      if (currSentences.length>0) {
        partitions.push(currSentences);
      }
      let promiseList = [];
      if(fromItemVoiceOver){
        partitions.forEach((part, index)=>{
          this.itemVoiceOverAudios = []
          promiseList.push(this.voiceUploadItemVoiceOver(part, element, lang, index, partitions.length))
        })
        return Promise.all(promiseList);
      } else {
        return Promise.all(partitions.map((part)=> this.voiceUpload(part, element, lang)));
      }
    } else {
      return this.voiceUpload(script, element, lang);
    }
  }

  private voiceUpload(script, element:{url?: string, fileType?:string}, lang){
    return new Promise((resolve, reject) => {
      return this.auth
        .apiCreate(
          this.routes.TEST_AUTH_TEXT_VOICE,
          { script, lang }
        )
        .then((res:{url:string})=> {
          element.url = res.url;
          element.fileType = 'audio/mp3';
          setTimeout(() => {
            resolve();
          }, 500)
        })
    });
  }

  itemVoiceOverAudios: {url: string, index: number}[] = [] as {url: string, index: number}[]
  private voiceUploadItemVoiceOver(script, element:{url?: string, fileType?:string, urlList?: {url: string, index: number}[]}, lang, index: number, length:number){
    return new Promise((resolve, reject) => {
      return this.auth
        .apiCreate(
          this.routes.TEST_AUTH_TEXT_VOICE,
          { script, lang }
        )
        .then((res:{url:string})=> {
          console.log(res.url, index);
          element.fileType = 'audio/mp3';
          if(!this.itemVoiceOverAudios || !this.itemVoiceOverAudios.find(entry => entry.url == res.url)){
            this.itemVoiceOverAudios.push({url: res.url, index: index})
            this.itemVoiceOverAudios.sort((a, b)=>{
              if(a.index < b.index) { return -1; }
              if(a.index > b.index) { return 1; }
              return 0;
            })
            element.urlList = this.itemVoiceOverAudios;
            if(element.urlList.length == length){
              this.combineAudioAndUpload(element).then(()=>{
                resolve();
              });
            }
            else{
              setTimeout(() => {
                resolve();
              }, 500)
            }
          }     
        })
    });
  }

  async combineAudioAndUpload(element:{url?: string, fileType?:string, urlList?: {url: string, index: number}[]}){
    if(element.urlList){
      let uris = element.urlList.map(url => url.url),
      proms = uris.map(uri => fetch(uri).then(r => r.blob()));
      console.log(element.urlList);
      const blobs: any =  await Promise.all(proms);
      let blob = new Blob(blobs, {type : 'audio/mp3'}),
        blobUrl = URL.createObjectURL(blob),
        audio = new Audio(blobUrl);
        audio.duration;
      const file = new File([blob], 'voice.wav');
        return await this.auth
        .uploadFile(file, file.name, 'authoring', true)
        .then(res => {
            element.url = res.url;
            element.fileType = 'audio/mp3';
            console.log('file ready')
        })
    }
  }
}
