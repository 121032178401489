import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BodyBlockArrSelectComponent } from './body-block-arr-select/body-block-arr-select.component';
import { UiChartsModule } from '../ui-charts/ui-charts.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { ListTravArrowsComponent } from './list-trav-arrows/list-trav-arrows.component';
import { CheckToggleComponent } from './check-toggle/check-toggle.component';
import { TraComponent } from './tra/tra.component';
import { TraMdComponent } from './tra-md/tra-md.component';
import { MarkdownModule } from 'ngx-markdown';
import { InfoCenterDetailsComponent } from './info-center-details/info-center-details.component';
import { ModalComponent } from './modal/modal.component';
import { PaginatorComponent } from './paginator/paginator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MenuBarComponent } from './menu-bar/menu-bar.component';
import { TableRowSelectorComponent } from './table-row-selector/table-row-selector.component';
import { EditSaveComponent } from './edit-save/edit-save.component';
import { InputTimeComponent } from './input-time/input-time.component';
import { InputDateComponent } from './input-date/input-date.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { CaptureFilterRangeComponent } from './capture-filter-range/capture-filter-range.component';
import { ExpectationTableComponent } from './expectation-table/expectation-table.component';
import { ChatBoxComponent } from './chat-box/chat-box.component';
import { ChatEmojiPickerComponent } from './chat-emoji-picker/chat-emoji-picker.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ModalFooterComponent } from './modal-footer/modal-footer.component';
import { CustomColumnFilterComponent } from './custom-column-filter/custom-column-filter.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { UiSidebarComponent } from '../ui-sidebar/ui-sidebar.component';
import { FilterTogglesComponent } from './filter-toggles/filter-toggles.component';
import { TwiddleComponent } from './twiddle/twiddle.component';
import { NumScrubComponent } from './num-scrub/num-scrub.component';
import { BcExportButtonComponent } from './bc-export-button/bc-export-button.component';
import { BcPaginatedTableComponent } from './bc-paginated-table/bc-paginated-table.component';
import { BcPaginatorComponent } from './bc-paginator/bc-paginator.component';
import { BcHeaderLayoutComponent } from './bc-header-layout/bc-header-layout.component';
import { BcToggleComponent } from './bc-toggle/bc-toggle.component';
import { ProgressReportComponent } from './progress-report/progress-report.component';
import { SchoolLevelDataReportComponent } from './school-level-data-report/school-level-data-report.component';
import { SchoolCompletionReportComponent } from './school-completion-report/school-completion-report.component';
import { BcSearchDropdownComponent } from './bc-search-dropdown/bc-search-dropdown.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { ConfirmationCodeReportComponent } from './confirmation-code-report/confirmation-code-report.component';
import { BcTabsComponent } from './bc-tabs/bc-tabs.component';
import { FsaSchoolsNotStartedReportComponent } from './fsa-schools-not-started-report/fsa-schools-not-started-report.component';
import { BcAccountsAdminsSchoolComponent } from './bc-accounts-admins-school/bc-accounts-admins-school.component';
import { BcAccountsAdminsDistrictComponent } from './bc-accounts-admins-district/bc-accounts-admins-district.component';
import { BcAccountsAdminsComponent } from './bc-accounts-admins/bc-accounts-admins.component';
import { BcUploadWidgetComponent } from './bc-upload-widget/bc-upload-widget.component';
import { ExportTableContentsComponent } from './export-table-contents/export-table-contents.component';
import { AudioDirective } from './audio-directive.directive';
import { WlCtxDirective } from './wl-ctx.directive';
import { MatExpansionModule } from '@angular/material/expansion';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { StudentAccommodationsComponent } from './student-accommodations/student-accommodations.component';
import { ConfidentialityAgreementComponent } from './confidentiality-agreement/confidentiality-agreement.component';
import { InvigIntroVidComponent } from './invig-intro-vid/invig-intro-vid.component';
import { DobInputComponent } from './dob-input/dob-input.component';
import { FilterableDropdownComponent } from './filterable-dropdown/filterable-dropdown.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MarkdownModule,
    ReactiveFormsModule,
    FormsModule,
    UiChartsModule,
    TextFieldModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    AutoSizeInputModule,
    MatExpansionModule,
    NgxJsonViewerModule,
    NgbModule,
  ],
  declarations: [
    InvigIntroVidComponent,
    ConfidentialityAgreementComponent,
    StudentAccommodationsComponent,
    BodyBlockArrSelectComponent,
    FooterComponent,
    HeaderComponent,
    ListTravArrowsComponent,
    CheckToggleComponent,
    TraComponent,
    TraMdComponent,
    InfoCenterDetailsComponent,
    ModalComponent,
    PaginatorComponent,
    MenuBarComponent,
    TableRowSelectorComponent,
    EditSaveComponent,
    InputTimeComponent,
    InputDateComponent,
    TableHeaderComponent,
    CaptureFilterRangeComponent,
    ExpectationTableComponent,
    ChatBoxComponent,
    UiSidebarComponent,
    ChatEmojiPickerComponent,
    ChatMessageComponent,
    ModalFooterComponent,
    CustomColumnFilterComponent,
    LoadingSpinnerComponent,
    FilterTogglesComponent,
    TwiddleComponent,
    NumScrubComponent,
    BcExportButtonComponent,
    BcPaginatedTableComponent,
    BcPaginatorComponent,
    BcHeaderLayoutComponent,
    BcToggleComponent,
    ProgressReportComponent,
    SchoolLevelDataReportComponent,
    SchoolCompletionReportComponent,
    BcSearchDropdownComponent,
    FsaSchoolsNotStartedReportComponent,
    ConfirmationCodeReportComponent,
    BcTabsComponent,
    BcAccountsAdminsComponent,
    BcAccountsAdminsSchoolComponent,
    BcAccountsAdminsDistrictComponent,
    BcUploadWidgetComponent,
    ExportTableContentsComponent,
    AudioDirective,
    WlCtxDirective,
    ExpansionPanelComponent,
    DobInputComponent,
    FilterableDropdownComponent
  ],
  exports: [
    InvigIntroVidComponent,
    ConfidentialityAgreementComponent,
    StudentAccommodationsComponent,
    UiSidebarComponent,
    BodyBlockArrSelectComponent,
    FooterComponent,
    HeaderComponent,
    ListTravArrowsComponent,
    CheckToggleComponent,
    TraComponent,
    TraMdComponent,
    InfoCenterDetailsComponent,
    PaginatorComponent,
    MenuBarComponent,
    EditSaveComponent,
    TableRowSelectorComponent,
    TableHeaderComponent,
    InputTimeComponent,
    InputDateComponent,
    CaptureFilterRangeComponent,
    ExpectationTableComponent,
    ChatBoxComponent,
    ModalFooterComponent,
    FilterTogglesComponent,
    TwiddleComponent,
    NumScrubComponent,
    BcExportButtonComponent,
    BcHeaderLayoutComponent,
    BcToggleComponent,
    BcPaginatedTableComponent,
    DobInputComponent,
    MatSlideToggleModule,
    ProgressReportComponent,
    SchoolLevelDataReportComponent,
    FsaSchoolsNotStartedReportComponent,
    ConfirmationCodeReportComponent,
    BcTabsComponent,
    BcUploadWidgetComponent,
    ExportTableContentsComponent,
    AudioDirective,
    WlCtxDirective,
    FilterableDropdownComponent,
    ExpansionPanelComponent
  ]
})
export class UiPartialModule { }
