import * as moment from 'moment-timezone';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountType } from '../../constants/account-types';
import { LoginGuardService } from '../../api/login-guard.service';
import { MyCtrlOrgService } from '../my-ctrl-org.service';
import { AuthService } from '../../api/auth.service';
import { RoutesService } from '../../api/routes.service';
import { ScrollService } from '../../core/scroll.service';
import { LangService } from '../../core/lang.service';
import { TimezoneService } from '../../core/timezone.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreadcrumbsService, IBreadcrumbRoute } from '../../core/breadcrumbs.service';
import { ITCDashboardSummaryRes } from '../types/api-response';
import { ITestWindowSummary, IAccountsSummary } from './types/db';
import { FormControl } from '@angular/forms';
import { WhitelabelService } from '../../domain/whitelabel.service';
import { MPT_TEST_CTRL_GROUP_ID } from '../../domain/contexts/caec';

 //export const MPT_TEST_CTRL_GROUP_ID = 27; //hardcode for now, should only get test windows for MPT in test-admin, test-ctrl and test-taker
//export const MPT_TEST_CTRL_GROUP_ID = 24; //hardcode for now, should only get test windows for MPT in test-admin, test-ctrl and test-taker

interface ITestWindow { 
  id: number,
  captionStr: string,
  dateStart: string
  dateEnd: string,
  numSessionsCreated: number,
  numSessionsAdministered: number,
  numResultsPendingRelease: number,
  is_allow_new_bookings:boolean,
  test_ctrl_group_id:number;
}

interface ILangReqRow {
  testSessionId: number,
  date: string,
  time: string,
  location:string,
  locationInst: string,
  locationDetails: string,
  lang: string,
  tally: number,
  isOrganized:boolean,
  notes: string,
  __notesInput: FormControl,
  __isShowLocDetail?: boolean,
}

export interface ILangReqRecord {
  id: number, // test session id
  test_window_id: number,
  lang_req: string,
  tally: number,
  institution_name: string,
  room: string,
  campus_building: string,
  address: string,
  city: string,
  province: string,
  postal_code: string,
  phone_number: string,
  date_time_start: string,
}

@Component({
  selector: 'view-tc-dashboard',
  templateUrl: './view-tc-dashboard.component.html',
  styleUrls: ['./view-tc-dashboard.component.scss']
})
export class ViewTcDashboardComponent implements OnInit, OnDestroy {

  // services
  constructor(
    private loginGuard: LoginGuardService, // 
    private router:Router,
    private route:ActivatedRoute,
    private breadcrumbsService:BreadcrumbsService,
    private scrollService:ScrollService,
    private lang:LangService,
    private timezone:TimezoneService,
    private auth:AuthService,
    private routes:RoutesService,
    private myCtrlOrg:MyCtrlOrgService,
    private whitelabel:WhitelabelService,
  ) { }

  // rendered vars
  public isDisableHidden:boolean = true;
  public breadcrumb:IBreadcrumbRoute[];
  public isInited:boolean;
  public isLoaded:boolean;
  public isLoadFailed:boolean;
  public testWindowSummaries:ITestWindow[];
  public testWindowOldSessions = {};
  public accountSummaries:IAccountsSummary;
  public showLast30days = new FormControl(false);
  public showTestSessionInProgressData = new FormControl(false);

  // logical vars
  private routeSub:Subscription;
  private myCtrlOrgSub:Subscription;
  
  // init / destroy
  ngOnInit() {
    this.scrollService.scrollToTop();
    this.whitelabel.setMPTctrlID()
    this.loginGuard.activate([AccountType.TEST_CTRL]);
    this.routeSub = this.route.params.subscribe(routeParams => {
      // this.setupId = routeParams['setupId'];
      console.log(MPT_TEST_CTRL_GROUP_ID)
      this.breadcrumb = [
        this.breadcrumbsService.TESTCTRL_DASHBOARD(),
        // this.breadcrumbsService._CURRENT(this.lang.tra(currentPageNameTrans), this.router.url),
      ]
      this.initRouteView();    
    });
  }

  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }

    if (this.myCtrlOrgSub) {
      this.myCtrlOrgSub.unsubscribe();
    }

  }

  initRouteView(){
    this.myCtrlOrgSub = this.myCtrlOrg.sub().subscribe(orgInfo => {
      if (!this.isInited && orgInfo){
        this.isInited = true;
        this.loadSummaryData();
      }
    })
  }


  // API utility
  loadSummaryData(){
    return this.auth
      .apiGet( this.routes.TEST_CTRL_SUMMARY, 0, {} )
      .then( (res:ITCDashboardSummaryRes) => {
        this.isLoaded = true;
        this.accountSummaries = res.accounts;
        this.testWindowSummaries = res.test_windows.map(this.parseTestWindowSummary);

        const testWindowIds = res.test_windows.map(window => window.test_window_id);
        this.loadSUnclosedSessions(testWindowIds);
      })
      .catch(e => {
        this.isLoadFailed = true;
      })
  }

  loadSUnclosedSessions(testWindowIds) {
    console.log('loadSUnclosedSessions')
    this.auth.apiFind('public/test-ctrl/sessions-date', {
      query: {
        test_window_ids: testWindowIds
      }
    }).then(res => {
      res.data.forEach(sess => {
        const windowId = sess.id;
        const isClosed = sess.is_closed;
        const startDate = sess.date_time_start;

        const start = moment(startDate);
        const now = moment().subtract(6, 'hour');

        if (start.isBefore(now)) {

          if (windowId in this.testWindowOldSessions) {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] += 1;
            }
          } else {
            if (!isClosed) {
              this.testWindowOldSessions[windowId] = 1;
            }
          }

        }
      });

    });
  }

  createTestWindow(){
    if (confirm('Proceed in creating a new test window?')){
      return this.auth
        .apiCreate( this.routes.TEST_CTRL_TEST_WINDOW_SUMMARY, {test_ctrl_group_id: MPT_TEST_CTRL_GROUP_ID})
        .then( (res:{id:number} ) => {
          this.router.navigate([ this.getTestWindowRoute(res.id) ])
        })
        .catch(e => {
          alert('Cannot create new test window.')
        })
    }
    }

  isLangReqLoaded:boolean;
  isLangReqLoading:boolean;
  isLangReqLoadFailed:boolean;
  langReqRows:ILangReqRow[] = [];
  loadLangReqData(){
    this.langReqRows = []
    this.isLangReqLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_LANG_REQ, {} )
      .then( (res:any) => { // :Paginated
        this.isLangReqLoaded = true;
        this.langReqRows = res.data.map(this.parseLangReqRecord);
        this.isLangReqLoading = false;
        // console.log('loadLangReqData', this.langReqRows)
      })
      .catch(e => {
        this.isLangReqLoadFailed = true;
      })
  }


  isInstSummaryLoaded:boolean;
  isInstSummaryLoading:boolean;
  isInstSummaryLoadFailed:boolean;
  instSummaryList:any[] = [];
  loadInstSummary(){
    this.instSummaryList = []
    this.isInstSummaryLoading = true;
    return this.auth
      .apiFind( this.routes.TEST_CTRL_ACCOUNTS_INSTITUTIONS, {} )
      .then( (res:any) => { // :Paginated
        this.isInstSummaryLoaded = true;
        this.instSummaryList = res.data.sort((a,b)=>{
          if (a.name > b. name){ return 1; }
          if (a.name < b. name){ return -1; }
          return 0;
        });
        this.isInstSummaryLoading = false;
      })
      .catch(e => {
        this.isInstSummaryLoadFailed = true;
      })
  }

  parseLangReqRecord = (langReqRecord:ILangReqRecord) : ILangReqRow => {
    const DATE_FMT = 'MMM D, YYYY'
    const TIME_FMT = this.lang.tra('datefmt_time')
    const m = moment(langReqRecord.date_time_start);
    return { 
      testSessionId: langReqRecord.id,
      date: m.format(DATE_FMT),
      time: m.format(TIME_FMT),
      location:langReqRecord.city,
      locationInst: langReqRecord.institution_name,
      locationDetails: [
        `${langReqRecord.room}, ${langReqRecord.campus_building}`,
        `${langReqRecord.address}`,
        `${langReqRecord.city} ${langReqRecord.province} ${langReqRecord.postal_code}`,
        `${langReqRecord.phone_number}`,
      ].join('\n'),
      lang: this.processLangReqCode(langReqRecord.lang_req),
      tally: langReqRecord.tally,
      isOrganized: false,
      notes: '',
      __notesInput: new FormControl(),
    }
  }

  private processLangReqCode(langCode:string){
    if (langCode === 'fr'){
      return 'French';
    }
    return 'Other';
  }

  parseTestWindowSummary = (tws:ITestWindowSummary) : ITestWindow => {
    const dateFmt = this.lang.tra('datefmt_dow_time_day_month_year');
    const dateStart = this.timezone.moment(tws.test_window_date_start).format(dateFmt);
    const dateEnd = this.timezone.moment(tws.test_window_date_end).format(dateFmt);
    return {
      id: tws.test_window_id,
      captionStr: tws.test_window_name ? tws.test_window_name[this.lang.c()] : '',
      dateStart,
      dateEnd,
      numSessionsCreated: tws.num_sessions_created,
      numSessionsAdministered: tws.num_sessions_administered,
      numResultsPendingRelease: tws.num_results_pending_release,
      test_ctrl_group_id: tws.test_ctrl_group_id,
      is_allow_new_bookings: tws.is_allow_new_bookings,
    }
  }

  getSessionListDlLink(){
    return this.auth.dataFilePath('public/test-ctrl/proc-cert/current-sessions', {})
  }

  // DOM utility
  disabled(){
    if (!this.isDisableHidden){
      this.loginGuard.confirmationReqActivate({
        caption: this.lang.tra('txt_feature_disabled'), 
        confirm: ()=>{}
      })
    }
  }

  getTestWindowRoute(id:string | number){
    console.log("id: ", id);
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/test-window/window/${id}` ;
  }

  refreshData(){

  }

  getAccountsRoute(){
    return `/${this.lang.c()}/${AccountType.TEST_CTRL}/accounts/${AccountType.TEST_ADMIN}`;
  }

  getAppealsRoute() {
    return;
    // return `/${this.lang.c()}/${AccountType.TEST_CTRL}/pending-appeals/${this.instInfo.id}`
  }

  isTestCtrlReportingExplorerEnabled(){
    return this.whitelabel.getSiteFlag('TEST_CTRL_REPORT_EXPLR');
  }

  // testWindowSummaries
  
  renderTwTitle(tw:ITestWindow){
    return tw.captionStr;
  }
  renderTwDateStart(tw:ITestWindow){
    return tw.dateStart;
  }
  renderTwDateEnd(tw:ITestWindow){
    return tw.dateEnd;
  }
  renderTwNumSessionsCr(tw:ITestWindow){
    return tw.numSessionsCreated;
  }
  renderOldUnclosed(tw:ITestWindow) {
    return this.testWindowOldSessions[tw.id] || 0;
  }
  renderTwNumSessionsAdm(tw:ITestWindow){
    return tw.numSessionsAdministered;
  }
  renderTwNumResPend(tw:ITestWindow){
    return tw.numResultsPendingRelease;
  }
  // end of: testWindowSummaries
  // Account Summaries
  renderAccountTally(set:string, subset: 'r' | 'l30' | 'p'){
    let val;
    if (this.accountSummaries){
      const tallySummary = this.accountSummaries[set];
      if (tallySummary){
        val = tallySummary[subset];
      }
    }
    return val || 0;
  }


  // end of: Account Summaries
  renderDateFromStr(dateString:string){
    return this.timezone.moment(dateString).format();
  }
  isOnFirstPage(){
    return true;
  }
  isOnLastPage(){
    return true;
  }
  getCurrentPageNum(){
    return 1
  }
  getMaxPages(){
    return 1
  }

  showAppealButton(){
    if(this.whitelabel.isTestCenter()){
      return false;
    }
    return true;
  }


}
