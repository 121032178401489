import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LangService } from '../../core/lang.service';

@Component({
  selector: 'tra-md',
  templateUrl: './tra-md.component.html',
  styleUrls: ['./tra-md.component.scss']
})
export class TraMdComponent implements OnInit, OnChanges {

  @Input() id: number;
  @Input() slug: string;
  @Input() isCondensed: boolean;
  @Input() isFlex: boolean;
  @Input() isInline: boolean;
  @Input() forcedLang: string;
  @Input() whitelabel: string; // whitelabel text which needs to be routed to determine what it should be
  @Input() props: {[key:string]: string | number} = {};

  val: string;

  constructor(
    private langService:LangService
  ) { }

  refreshVal() {
    this.val = this.langService.traWithWhitelabel(this.whitelabel, this.slug || this.id, undefined, this.props);
  }
  ngOnInit() {
    this.render();
  }
 ngOnChanges(changes:SimpleChanges){
    if (changes.slug){
      this.render();
    }

    if (changes.props) {
      this.render();
    }
  }

  private render(){
    let str = this.langService.traWithWhitelabel(this.whitelabel, this.slug || this.id, this.forcedLang, this.props);
    str = str.split('« ').join('«&nbsp;')
    str = str.split(' »').join('&nbsp;»')
    this.val = str;
  }
 

}
