import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { indexOf } from '../services/util';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { TextToSpeechService } from '../text-to-speech.service';
import { AuthScopeSetting } from '../../ui-item-maker/auth-scope-settings.service';
import { getRandomInt } from '../../marking/data/data';
import { IMultiSelect } from '../../ui-schooladmin/data/types';
import { EMcqSelectionStyle, StyleprofileService } from '../../core/styleprofile.service';
import { LangService } from '../../core/lang.service';
import { McqRenderer } from './element-render-mcq-functions';
import { LoginGuardService } from '../../api/login-guard.service';
import { QuestionPubSub } from '../question-runner/pubsub/question-pubsub';
import { DrawingLogService } from '../drawing-log.service';
import { IContentElementText } from '../element-render-text/model';
import { ScoringTypes, QuestionState, ElementType, getElementWeight } from '../models';
import { IContentElementMcq, IContentElementMcqOption, IContentElementMcqOptionInTable, IEntryStateMcq, McqDisplay } from './model';
import { QuestionRunnerLayoutService } from '../question-runner-layout.service';
import { EditSelectionService } from '../edit-selection.service';
import { HighlighterService } from '../highlighter.service';
import { Subscription } from 'rxjs';
import { WhitelabelService } from 'src/app/domain/whitelabel.service';

const SCORING_TYPE = ScoringTypes.AUTO;

@Component({
  selector: 'element-render-mcq',
  templateUrl: './element-render-mcq.component.html',
  styleUrls: ['./element-render-mcq.component.scss']
})
export class ElementRenderMcqComponent implements OnInit, OnChanges, OnDestroy {

  @Input() element:IContentElementMcq;
  @Input() isLocked:boolean;
  @Input() isShowSolution:boolean;
  @Input() questionState:QuestionState;
  @Input() questionPubSub?: QuestionPubSub;
  @Input() frameWorkTags:{slug:string}[];

  subscription = new Subscription();

  mcqRenderer: McqRenderer;
  lastTrackedQuestionState;
  alreadyScrambled:boolean;
  buttonLabels = 'ABCDEFGHIJKLMNOP'.split('');
  dropdownSelector = new FormControl();
  isCustomDropdownOn = false;
  frameWorkTagsRef = new Map();

  // Special properties for highlighting dropdown
  hasAuthHighlightedOption:boolean = false;
  hasAuthHighlightedOptionResolved:boolean = false;
  
  // minHeight = 3;
  constructor(
    private textToSpeech:TextToSpeechService,
    private profile: StyleprofileService,
    private lang: LangService,
    private loginGuard: LoginGuardService,
    private bufferedLog: DrawingLogService,
    private questionRunnerLayout: QuestionRunnerLayoutService,
    private whitelabel: WhitelabelService,
    public editSelection: EditSelectionService,
    public highlighter: HighlighterService
  ) {
    
  }

  ngOnInit() {
    this.ensureMcqRenderer();
    this.mcqRenderer.ensureState();
    this.mcqRenderer.initElementSub(this.questionPubSub)
    this.initScrambledOrder()
    this.mcqRenderer.initDropDownSelector(); 
    document.addEventListener('click', this.offClickHandler.bind(this));
    this.initTags()
    this.initAuthHighlightSub();
  }

  /**
   * Subscribe to chosen highlights, in dropdown mode handle highlighting directly here
   * If it's targeting any of the dropdown options, set properties to outline the dropdown box in the highlight colour
   */
  initAuthHighlightSub(){
    this.subscription.add(this.highlighter.applyHighlight.subscribe(newHighlight => {
      if (!this.isDisplayStyleDropdown()) return;
      this.hasAuthHighlightedOption = this.hasAuthHighlightedOptionResolved = false;
      if (!newHighlight) return;
      newHighlight.targets?.forEach(target => {
        if (target.prop == 'content' && this.element.options?.some(option => option.entryId == target.entryId)) {
          if (newHighlight.isResolved) this.hasAuthHighlightedOptionResolved = true
          else this.hasAuthHighlightedOption = true;
        }
      })
    }))
  }

  initScrambledOrder(){
    const entryState = this.mcqRenderer.ensureState();
    this.alreadyScrambled = entryState.alreadyScrambled;
    // if(this.questionState && !entryState.alreadyScrambled && this.element.isScrambled) {
      // this.mcqRenderer.scrambleOrder();
    // }
  }

  initTags(){
    // console.log('frameWorkTags', this.frameWorkTags);
    if (this.frameWorkTags){
      this.frameWorkTags.forEach(tag => {
        const key = (''+tag.slug).trim();
        this.frameWorkTagsRef.set(key, true);
      })
    }
  }

  ensureMcqRenderer(){
    if (!this.mcqRenderer){
      this.mcqRenderer = new McqRenderer(
        this.element,
        this.questionState,
        this.getMcqContent,
        this.isShowSolution,
        this.isLocked,
        this.textToSpeech,
        this.profile,
        this.loginGuard,
        this.lang,
        this.whitelabel,
        this.questionPubSub,
        this.questionRunnerLayout,
        this.dropdownSelector,
      );
    }
  }

  ngOnDestroy(): void {
    // this was creating an issue for dropdowns, so I suggest just saving the state immediately after creating the scramble rather than trying to catch on navigation away
    // if (!this.isDisplayStyleDropdown()){
    //   this.mcqRenderer.updateState(); //Make sure we're saving the alreadyScrambled value on changing of questions
    // } 
    if (this.closer) {
      clearTimeout(this.closer)
    }
    this.subscription.unsubscribe();
  }
  
  ngOnChanges(changes:SimpleChanges){
    this.ensureMcqRenderer();
    this.mcqRenderer.checkForChanges(changes)
    this.mcqRenderer.updateDropdownEnabled();
  }
  

  getVoiceURL(optionElement) {
    if (optionElement?.voiceover?.url) {
      return optionElement.voiceover.url
    }
  }

  
  renderOptionTableCols(option:IContentElementMcqOption){
    const rowOption = <IContentElementMcqOptionInTable> option;
    return rowOption.cols
  }

  getOptionsContainerStyle(){
    let style:any = {};
    if (this.isDisplayStyleGrid()){
      if (this.element.gridNumColumns){
        style['width.em'] = (this.element.gridCellWidth+1+(this.element.marginRight || 0)) * this.element.gridNumColumns;
        style['justify-content'] = 'center';
      }
    }
    if (this.isDisplayStyleFreeform()){
      style['width.em'] = this.element.width;
      style['height.em'] = this.element.height;
    }
    return style;
  }

  isDisplayStyleLikert(){         return this.element.displayStyle === McqDisplay.LIKERT; }
  isDisplayStyleVertical(){       return this.element.displayStyle === McqDisplay.VERTICAL; }
  isDisplayStyleHorizontal(){     return this.element.displayStyle === McqDisplay.HORIZONTAL }
  isDisplayStyleWrapAround(){     return this.element.displayStyle === McqDisplay.WRAP }
  isDisplayStyleGrid(){           return this.element.displayStyle === McqDisplay.GRID; }
  isDisplayStyleDropdown(){       return this.element.displayStyle === McqDisplay.DROPDOWN; }
  isDisplayStyleCustomDropdown(){ return this.element.displayStyle === McqDisplay.CUSTOM_DROPDOWN; }
  isDisplayStyleBubble(){         return this.element.displayStyle === McqDisplay.BUBBLE; }
  isDisplayStyleFreeform(){       return this.element.displayStyle === McqDisplay.FREEFORM; }
  isDisplayStyleTable(){          return this.element.displayStyle === McqDisplay.TABLE; }
  isColoredLikert(){   return this.element.isColoredLikert; }

  getMcqContent = (selection) =>{
    let content;
    if (selection.elementType === 'image'){
      content = selection.url;
    }
    else{
      content = selection.content;
    }
    return content
  }

  closer
  closeTimer() {
    this.closer = setTimeout(()=>{
      this.closeCDD()
    },100)
  }

  closeCDD() {
    this.isCustomDropdownOn = false;
    this.questionRunnerLayout.clear(this.element.entryId);
  }

  offClickHandler(event) {

    const dropdown_list = document.getElementsByClassName("dropdown-option-container");
    const dropdown_button = document.getElementsByClassName("custom-dropdown-button");
    const dropdown_arrow_up = document.getElementsByName("dropdown-icon-up");
    const dropdown_arrow_down = document.getElementsByName("dropdown-icon-down");

    const nodeList = []
      .concat(Array.from(dropdown_list))
      .concat(Array.from(dropdown_button))
      .concat(Array.from(dropdown_arrow_up))
      .concat(Array.from(dropdown_arrow_down));

    let clicked_outside = true;

    nodeList.forEach(dropdownElement => {
      if (event.target.className && dropdownElement.className)
        event.target.className == dropdownElement.className? clicked_outside = false : '';
      dropdownElement.contains(event.target)? clicked_outside = false : '';
    })

    clicked_outside? this.closeCDD() : '';
  }

  openCDD() {
    this.isCustomDropdownOn = true;
    this.popOut()
  }

  popOut() {
    this.mcqRenderer.popOut()
  }

  getCurrentDropDownAnswers() {
    const qsState = this.questionState[this.element.entryId]
    const content = []
    if (!qsState?.selections) return []
    qsState.selections.forEach((selection)=>{
      content.push(this.element.options[selection['i']])
    })
    return content;
  }

  getMCQTableBGStyle(optionElement) {
    let style:any = {};

    const selected = this.mcqRenderer.isSelected(optionElement)
    if(this.element.isOptionsBgColor && 
      !(this.element.isBgClear && (!selected || (this.element.isBgClearAlways && selected)) )) { 
        style['background-color'] = this.element.optionsBgColor 
    }

    return style
  }

  getButtonContainerStyle(optionElement){
    let style:any = {};
    if (this.isDisplayStyleFreeform()){
      style['left.em'] = optionElement.x;
      style['top.em'] = optionElement.y;
    }
    return style;
  }
  getButtonOptionStyle(optionElement){
    let style:any = {}
    if (this.element.isHotspot){
      // style['margin-right.em'] = 
    }
    else {
      style['margin-right.em'] = this.element.marginRight || 0;
      style['margin-bottom.em'] = this.element.marginBottom || 0;
      if (this.isDisplayStyleGrid()){
        style['width.em'] = this.element.gridCellWidth || 10;
      }
      if (this.element.optionHeightSet){
        if (this.element.optionHeight){
          style['min-height.em'] = this.element.optionHeight
          style['height.em'] = this.element.optionHeight
        } 
      }
      if (this.isDisplayStyleFreeform()){
        style['width.em'] = optionElement.width || this.element.gridCellWidth;
        style['height.em'] = optionElement.height || this.element.optionHeight;
      }
      const basePadding = 0.5;
      if (this.element.isPolaroidStyle){
        style['padding.em'] = 0;
      }
      else if (this.element.isRadioDisabled && this.element.isOptionLabelsDisabled) {
        style['padding-left.em'] = basePadding;
        style['padding-right.em'] = basePadding;
        // style['padding-right.em'] = basePadding - 3.5;
      } 
      else {
        const paddingProp = this.element.isOnRightSideOptionIndicator ? 'padding-right.em' : 'padding-left.em' 
        const paddingProp2 = this.element.isOnRightSideOptionIndicator ? 'padding-left.em' : 'padding-right.em'
        const bgClear = this.element.isBgClear && this.element.isRadioBackgroundHidden
        const padding = bgClear ? 2 : 2.75 
        style[paddingProp] = padding + basePadding;
        if (this.element.isCanLink == undefined || this.element.isCanLink == false) style[paddingProp2] = basePadding;
        //   style['padding-left.em'] = 2.75+basePadding;
        //   if (this.element.isCanLink == undefined || this.element.isCanLink == false) style['padding-right.em'] = basePadding;
      }
  
      const selected = this.mcqRenderer.isSelected(optionElement)
      if(this.element.isOptionsBgColor && 
        !(this.element.isBgClear && (!selected || (this.element.isBgClearAlways && selected)) )) { 
          style['background-color'] = this.element.optionsBgColor 
      }
      if(this.element.isOptionsFontColor) { style['color'] = this.element.optionsFontColor }
      if(this.element.isOptionsBoxShadow) { 
        const { optionDropshadowX:x, optionDropshadowY : y, optionDropshadowBlur: blur, optionDropshadowColor:color} = this.element
        style['box-shadow'] =  x + 'px ' + y + 'px '+ blur + 'px ' + color  
      }
      if(this.element.isSelectionScale && this.mcqRenderer.isSelected(optionElement)){
        style['transform'] = `scale(${this.element.selectionScale})` 
      }
      if(this.mcqRenderer.isSelected(optionElement)){
        const defaultBorder = '#209cee' // as per the $selectedBorder in scss
        const highContrastBorder = this.element.isHCBorderColor ? this.element.HCSelectionBorderColor : defaultBorder
        const normalBorder = this.element.isSelectionBorder ? this.element.selectionBorder : defaultBorder
        style['border-color'] = this.textToSpeech.isHiContrast ? highContrastBorder : normalBorder
      }
    }
    return style;
  }

  isAdvTextEl(el:IContentElementText){
    if (el.paragraphStyle){
      return true;
    }
    return false;
  }

  updateDropdownEnabled(){
    if (this.mcqRenderer.isInputDisabled()){
      this.dropdownSelector.disable()
    }
    else{
      this.dropdownSelector.enable()
    }
  }
  
  updateDropdownState(){
    if (!this.isDisplayStyleDropdown()){
      return;
    }
    let isCorrect = false;
    let isFilled = false;
    let i = this.dropdownSelector.value;
    let option;
    if (i>=0){
      // if (this.hasDefaultDropDownText()) option = this.element.options[i-2];
      // else option = this.element.options[i]
      option = this.element.options[i]
      if (option){
        isFilled = true
        isCorrect = option.isCorrect;
      }
    }

    const weight = getElementWeight(this.element);
    const entryState:IEntryStateMcq = this.mcqRenderer.ensureState();
    entryState.isCorrect = isCorrect;
    entryState.isFilled = isFilled;
    entryState.isStarted = true;
    if (isFilled){
      entryState.selections = [{
        i: +i, 
        id: option.optionId, 
        elementType: ElementType.TEXT, 
        content: option.content,
      }]
    }
    else{
      entryState.selections = [];
    }
    entryState.score =  isCorrect ? weight : 0 ;
    //this.triggerDropdownSelect(option)
  }

  triggerDropdownSelect(option=undefined) {
    if (!option) {
      option = this.element.options[this.dropdownSelector.value]
    }
    if (option) {
      this.mcqRenderer.getClickTrigger(option).next(true);
      this.highlighter.checkInitWholeHighlight(option.entryId, 'content', option.content)
    }
  }

  getDropdownOptionStyle() {
    const style:any = {};
    style['font-weight'] = this.isBold(this.element.defaultDropdownText) ? 'bold' : 'normal'
    style['font-size'] = this.isItalic(this.element.defaultDropdownText) ? 'italic' : 'normal'
    return style;
  }

  showSelectAllMessage() {
    //return this.element.isMultiSelect && this.profile.getStyleProfile()[this.lang.c()].renderStyling.mcq.showSelectAll;
  }

  isCheckbox() {
    if (this.element.isMultiSelect){
      if (this.profile.getStyleProfile() && this.profile.getStyleProfile()[this.lang.c()].renderStyling?.mcq?.multiSelectCheckbox){
        return true;
      }
    }
    return false;
  }


  isBold(option:string) {
    if (!option) return false;
    if ((option.startsWith('**') && option.endsWith('**'))) {
      return true;
    }
    return false;
  }


  hasDefaultDropDownText() {
    const def = this.element.defaultDropdownText;
    if (!def || def==='' || def.trim() === '') return false;
    return true;
  }

  isItalic(option:string) {
    if (!option) return false;
    if ((option.startsWith('*') && option.endsWith('*') && !this.isBold(option)) || (option.startsWith("***") && option.endsWith("***"))) {
      return true;
    }
    return false;
  }

  isAllTableColsBlank(){
    let isAnyLabelFilled = false;
    this.element.tableCols.forEach(col => {
      if ( (col.label || '').trim() ){
        isAnyLabelFilled = true
      }
    })
    return !isAnyLabelFilled;
  }

  getDefaultTextDelimiter() {
    let len = 0;
    if (this.element.defaultDropdownText) {
      len = this.element.defaultDropdownText.length;
    }
    this.element.options.forEach((option)=>{
      if (option.content && option.content.length>len) len = option.content.length;
    })
    len *= 0.64;
    if (len == 0) len = 1;
    let str = "";
    for (let i = 0;i<len;i++) str+='─';
    return str;
  }

  stopEventProp($event) {
    $event.stopPropagation();
  }

  optionIndicatorStyle() {
    let style = {}
    if (this.element.isVerticallyCenterOptionIndicator) {
      style["display"]="flex"
      style["align-items"]="start"
      style["align-items"]="center"
    }
    const position = this.element.isOnRightSideOptionIndicator ? 'right' : 'left'
    style[position] = "0"
    return style;
  }

  getDropdownOptionFontSize(){
    const fontSize = this.element.fontSizeDropdownOptions
    return fontSize ? fontSize : 1
  }

  getDropdownWidth(){
    const width = this.element.dropdownWidth
    return width ? width : null
  }

  isDropdownNarrowWidth(){
    const width = this.element.dropdownWidth
    return width ? width < 4 : false
  }

  isInverted() {
    if (this.textToSpeech.isHiContrast) {
      return true;
    }
    return false;
  }

  getBorderColor() {
    if (!this.textToSpeech.isHiContrast) {
      if (this.element.isBorderColor && this.element.selectionBorderColor) {
        return this.element.selectionBorderColor
      }
    } else {
      if (this.element.isHCBorderColor && this.element.HCSelectionBorderColor) {
        return this.element.HCSelectionBorderColor
      }
    }
    return undefined
  } 
  
  getHideOptionBackground(optionElement) {
    return this.element.isRadioBackgroundHidden && (this.element.isRadioBackgroundHiddenAlways || !this.mcqRenderer.isSelected(optionElement))
  }

  isContentsJustifiedCenter() {
    if(this.element.displayStyle === McqDisplay.VERTICAL) {
      return this.element.isContentsCenteredVertically; //When vertical flex-direction is column
    }

    return this.element.isContentsCentered;
  }

  isContentsAlignedCenter() {
    if(this.element.displayStyle === McqDisplay.VERTICAL) {
      return this.element.isContentsCentered; //When vertical we are using column flex display
    }
    return this.element.isContentsCenteredVertically;
  }

  useSimpleSelectionStyle() {
    if(this.textToSpeech.isHiContrast) {
      return true;
    }
    if(this.profile.getStyleProfile()){
      return this.profile.getStyleProfile()[this.lang.c()].renderStyling?.mcq?.selectionStyle === EMcqSelectionStyle.SIMPLE
    }
    return false;
  }

  isCaretHidden() {
    return this.element.hideCaretOnSelection && this.dropdownSelector.value !== undefined
  }
}
