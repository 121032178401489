import { Injectable } from '@angular/core';
import { AuthService, IUserInfo } from '../api/auth.service';
import { BehaviorSubject } from 'rxjs';
import { RoutesService } from '../api/routes.service';
import { UserRoles } from '../api/models/roles';

type RolesList = string[];

export interface ITestWindow {
  id: number,
  test_ctrl_group_id: number,
  created_on?: string,
  dateEnd?:any; // from dashboard
  date_end?:  string,
  dateStart?:any; // from dashboard
  date_start?:  string,
  is_active?: number,
  is_qa?: number,
  is_bg?: number,
  title?: string,
  type_slug?: string,
  numSessionsCreated?:any; // from dashboard
  numSessionsAdministered?:any; // from dashboard
  numResultsPendingRelease?:any; // from dashboard
  captionStr?:any; // from dashboard
  is_allow_new_bookings?:boolean;
}

export const TestControllerGroupId = 5403;

@Injectable({
  providedIn: 'root'
})
export class MyCtrlOrgService {

  // services + init
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
  ) {
    this.auth.user().subscribe(v => {
      this._userInfo = v;
      this.loadMyRoles();
    });
  }

  // vars (these should never be public)
  private _userInfo:IUserInfo;
  private _roles:RolesList;
  private info: BehaviorSubject<RolesList> = new BehaviorSubject(null);
  public selectedWindow: ITestWindow;
  private testWindowSummary: Partial<ITestWindow> = {};

  public setTestWindowSummary(summary: Partial<ITestWindow>)
  {
    this.testWindowSummary = summary;
  }

  public getTestWindowSummary(): Partial<ITestWindow>
  {
    return this.testWindowSummary;
  }


  // public methods
  public sub() {
    return this.info;
  }

  getMyUid(){
    if (this._userInfo){
      return this._userInfo.uid
    }
  }

  hasRole(role:UserRoles){
    if (this._roles && this._roles.indexOf(role) !== -1){
      return true;
    }
    return false;
  }

  private loadMyRoles(){
    return this.auth
      .apiFind(this.routes.TEST_CTRL_ROLES)
      .then(roles => {
        this._roles = roles;
        this.info.next(this._roles);
      })
  }

  constructPermissionsParams(query?:any, window?:ITestWindow){
    const windowParams:any = {};
    window = window || this.selectedWindow
    if (window){
      windowParams.tc_group_id = window.test_ctrl_group_id;
      windowParams.test_window_id = window.id;
      windowParams.type_slug = window.type_slug;
    }
    return {
      query:{
        ... query,
        ... windowParams,
      }
    }
  }

  loadWindows(){
    return this.auth.apiFind(
      '/public/test-ctrl/test-window/administration-windows',
    )
  }

  loadSchoolAdministratorsBulkLoadRecords(){
    return this.auth.apiFind(
      this.routes.TEST_CTRL_ACCOUNTS_SCHOOL_ADMINS, { query: { group_id: TestControllerGroupId }}
    )
  }
  getCodebookFiles(slug:string){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/data-dl/codebook-tcv',
      this.constructPermissionsParams({slug})
    )
  }

  getCodebookChangeLogFile(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/data-dl/codebook-change-log-tcv',
      this.constructPermissionsParams()
    )
  }

  getTwFiles(){
    return this.auth.apiFind(
      '/public/test-ctrl/tw-data-file',
      this.constructPermissionsParams()
    )
  }

  getTwTdAr(testWindowId?){
    // note: does not injest test window id as it is based on the selection stored in the service
    if(testWindowId){
      return this.auth.apiFind(
        '/public/test-ctrl/test-designs',
        this.constructPermissionsParams({test_window_id: testWindowId})
      )
    }else{
      return this.auth.apiFind(
        '/public/test-ctrl/test-designs',
        this.constructPermissionsParams()
      )
    }
  }
  createTwTdAr(insertData:any){
    return this.auth.apiCreate(
      '/public/test-ctrl/test-designs',
      insertData,
      this.constructPermissionsParams()
    )
  }
  removeTwTdAr(twtarId:number){
    return this.auth.apiRemove(
      '/public/test-ctrl/test-designs',
      twtarId,
      this.constructPermissionsParams()
    )
  }
  patchTwTdAr(twtarId:number, patchData:any){
    return this.auth.apiPatch(
      '/public/test-ctrl/test-designs',
      twtarId,
      patchData,
      this.constructPermissionsParams()
    )
  }

  getTwTdArTestDesigns(source_item_set_id?:number){
    // note: does not injest test window id as it is based on the selection stored in the service
    return this.auth.apiGet(
      '/public/test-ctrl/test-designs',
      source_item_set_id || -1, // -1 is interpreted as wanting to take from all available published test designs
      this.constructPermissionsParams()
    )
  }

  assignTwTdAr(twTdArId:number, test_design_id:number){
    return this.auth.apiPatch(
      '/public/test-ctrl/test-designs',
      twTdArId,
      {test_design_id},
      this.constructPermissionsParams()
    )
  }

  getSummaries(col:string, window?:ITestWindow){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/summary',
      0,
      this.constructPermissionsParams({ 
        col
      }, window)
    )
  }
  
  getBoards(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/boards',
      this.constructPermissionsParams({})
    )
  }
  getSchools(){
    return this.auth.apiFind(
      '/public/test-ctrl/schools/schools',
      this.constructPermissionsParams({})
    )
  }
  getScheduledSessionForms(){
    return this.auth.apiFind(
      '/public/test-ctrl/test-window/scheduled-sessions',
      this.constructPermissionsParams({})
    )
  }
  getParticipatingSchools(){
    return this.auth.apiFind(
      '/public/test-ctrl/test-window/schools-allowed',
      this.constructPermissionsParams({})
    )
  }
  addParticipatingSchool(s_id:number){
    return this.auth.apiCreate(
      '/public/test-ctrl/test-window/schools-allowed',
      {s_id},
      this.constructPermissionsParams({})
    )
  }
  removeParticipatingSchool(tas_id:number){
    return this.auth.apiRemove(
      '/public/test-ctrl/test-window/schools-allowed',
      tas_id,
      this.constructPermissionsParams({})
    )
  }
  getSchoolDateExceptions(){
    return this.auth.apiFind(
      '/public/test-ctrl/test-window/school-date-exceptions',
      this.constructPermissionsParams({})
    )
  }
  addSchoolDateExceptions(school_id:number, type_slug:string, date_start:string){
    return this.auth.apiCreate(
      '/public/test-ctrl/test-window/school-date-exceptions',
      {school_id, type_slug, date_start},
      this.constructPermissionsParams({})
    )
  }
  removeSchoolDateExceptions(tas_id:number){
    return this.auth.apiRemove(
      '/public/test-ctrl/test-window/school-date-exceptions',
      tas_id,
      this.constructPermissionsParams({})
    )
  }
  getStudents(id){
    return this.auth.apiGet(
      '/public/test-ctrl/schools/students-teachers-classes',
      id,
      this.constructPermissionsParams({})
    )
  }

  


  // private utilities
}
